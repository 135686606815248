import React, { useMemo } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { DateInput, Input, Label, LoadingSpinner, Select, TextArea } from "../../ui";
import useGetStates from "../../../hooks/settings/useStates";

const Step2Releases = () => {
  const { values, setFieldValue } = useFormikContext();
  const { data, isLoading } = useGetStates();

  const statesData = useMemo(() => {
    if (!data || !data.data || !data.data.states) {
      return [];
    }
    return data.data.states;
  }, [data]);


  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="grid gap-3">
      <h2 className="text-h6 font-bold">Releases</h2>
      <div className="grid md:grid-cols-2 gap-8">
        <div className="space-y-6">
          <div className="space-y-2">
            <Label htmlFor="firstReleaseDate" className="block text-sm font-medium">
              First Release Date
            </Label>
            <Field name="firstReleaseDate">
              {({ field }) => (
                <DateInput
                  id="firstReleaseDate"
                  {...field}
                  onChange={(event) => setFieldValue("firstReleaseDate", event.target.value)}
                  className="w-full"
                />
              )}
            </Field>
            <ErrorMessage name="firstReleaseDate" component="div" className="text-sm text-red" />
          </div>

          <div className="space-y-4">
            <Label className="block text-sm font-medium">Delivery Method</Label>
            <div className="space-y-4">
              <div className="flex items-start space-x-2">
                <Field name="deliveryMethod">
                  {({ field }) => (
                    <input
                      type="radio"
                      id="shipping"
                      {...field}
                      value="shipping"
                      className="mt-1 no-shadow h-4 w-4"
                      checked={values.deliveryMethod === "shipping"}
                    />
                  )}
                </Field>
                <label htmlFor="shipping" className="flex flex-col cursor-pointer">
                  <span className="font-medium text-base">Customer Requests Shipping</span>
                  <span className="text-sm text-gray-500">Ship To Customers Address.</span>
                </label>
              </div>
              <div className="flex items-start space-x-2 no-shadow">
                <Field name="deliveryMethod">
                  {({ field }) => (
                    <input
                      type="radio"
                      id="pickup"
                      {...field}
                      value="pickup"
                      className="mt-1 no-shadow h-4 w-4"
                      checked={values.deliveryMethod === "pickup"}
                    />
                  )}
                </Field>
                <label htmlFor="pickup" className="flex flex-col cursor-pointer">
                  <span className="font-medium text-base">Customer Requests Pickup</span>
                  <span className="text-sm text-gray-500">Customer Will Pick Up The Release Himself.</span>
                </label>
              </div>
            </div>
          </div>

          {values.deliveryMethod === "shipping" && (
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="shippingAddress" className="block text-sm font-medium">
                  Shipping Address
                </Label>
                <Field name="shippingAddress">
                  {({ field }) => (
                    <Input
                      type="text"
                      id="shippingAddress"
                      {...field}
                      className="w-full px-3 py-2 border rounded-md"
                    />
                  )}
                </Field>
                <ErrorMessage name="shippingAddress" component="div" className="text-sm text-red" />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="city" className="block text-sm font-medium">
                    City
                  </Label>
                  <Field name="city">
                    {({ field }) => (
                      <Input
                        type="text"
                        id="city"
                        {...field}
                        className="w-full px-3 py-2 border rounded-md"
                        placeholder="City name"
                      />
                    )}
                  </Field>
                  <ErrorMessage name="city" component="div" className="text-sm text-red" />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="state" className="block text-sm font-medium">
                    State
                  </Label>
                  <Field name="state">
                    {({ field }) => (
                      <Select
                        id="state"
                        {...field}
                        className="w-full px-3 py-2 border rounded-md"
                      >
                        <option value=" " className="hidden">Select state</option>
                        {statesData.map((state) => (
                          <option key={state.id} value={state.id}>
                            {state.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  </Field>
                  <ErrorMessage name="state" component="div" className="text-sm text-red" />
                </div>
              </div>

              <div className="space-y-2">
                <Label htmlFor="zipCode" className="block text-sm font-medium">
                  Zip Code
                </Label>
                <Field name="zipCode">
                  {({ field }) => (
                    <Input
                      type="text"
                      id="zipCode"
                      {...field}
                      className="w-full px-3 py-2 border rounded-md"
                    />
                  )}
                </Field>
                <ErrorMessage name="zipCode" component="div" className="text-sm text-red" />
              </div>
            </div>
          )}
        </div>

        <div className="space-y-2 h-full border-l pl-8">
          <Label htmlFor="note" className="block text-sm font-medium">
            Add A Note
          </Label>
          <Field name="note">
            {({ field }) => (
              <TextArea
                id="note"
                {...field}
                className="w-full h-[240px] px-3 py-2 border rounded-md resize-none"
              />
            )}
          </Field>
          <ErrorMessage name="note" component="div" className="text-sm text-red" />
        </div>
      </div>
    </div>
  );
};

export const step2Validation = Yup.object().shape({
  firstReleaseDate: Yup.date().required("First release date is required"),
  deliveryMethod: Yup.string().required("Delivery method is required"),
  shippingAddress: Yup.string().when("deliveryMethod", {
    is: "shipping",
    then: () => Yup.string().required("Shipping address is required"),
  }),
  city: Yup.string().when("deliveryMethod", {
    is: "shipping",
    then: () => Yup.string().required("City is required"),
  }),
  state: Yup.string().when("deliveryMethod", {
    is: "shipping",
    then: () => Yup.string().required("State is required"),
  }),
  zipCode: Yup.string().when("deliveryMethod", {
    is: "shipping",
    then: () => Yup.string().required("Zip code is required"),
  }),
  addNote: Yup.string(),
});

export default Step2Releases;

