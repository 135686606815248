const TrashIcon = (props) => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g mask="url(#mask0_1341_32220)">
      <path
        d="M5.25 16.25C4.8375 16.25 4.48438 16.1031 4.19062 15.8094C3.89687 15.5156 3.75 15.1625 3.75 14.75V5H3V3.5H6.75V2.75H11.25V3.5H15V5H14.25V14.75C14.25 15.1625 14.1031 15.5156 13.8094 15.8094C13.5156 16.1031 13.1625 16.25 12.75 16.25H5.25ZM6.75 13.25H8.25V6.5H6.75V13.25ZM9.75 13.25H11.25V6.5H9.75V13.25Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default TrashIcon;
