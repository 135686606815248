import React from 'react';

const TableHeader = ({ columns ,hasActions }) => {
  return (
    <thead>
      <tr >
        {columns.map((column, index) => (
          <th
            key={index}
            scope='col'
            className="py-6 px-2 text-left text-base2 font-normal text-sm text-[#636363]  tracking-wide"
            >
            {column}
          </th>
        ))}
        {
        hasActions && (
            <th className="py-6 invisible  text-left text-base2 font-normal text-sm text-[#636363] uppercase tracking-wide">
                Actions
             </th>
            )
        }
      </tr>
    </thead>
  );
};

export default TableHeader;
