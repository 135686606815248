// Container.js
import React from "react";
import cn from "../../utils/cn";

const ContainerStyles = {
  default: "mx-auto w-full gap-2",
  display: {
    flex: "flex flex-col",
    grid: "grid",
  },
  sizes: {
    sm: "max-w-screen-sm",
    md: "max-w-screen-md",
    lg: "max-w-screen-lg",
    xl: "max-w-screen-xl",
    xxl: "max-w-screen-xxl",
    full: "max-w-full",
    fit: "max-w-fit",
  },
};

const Container = ({
  children,
  type = "flex",
  size = "xxl",
  className = "",
}) => {
  return (
    <div
      className={cn(
        className,
        ContainerStyles.default,
        ContainerStyles.display[type],
        ContainerStyles.sizes[size],
      )}
    >
      {children}
    </div>
  );
};

export default Container;
