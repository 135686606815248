import React from "react";

const ArrowIcon2 = (props) => (
  <svg
    className="absolute top-[15px] right-[15px] w-[20px] cursor-pointer"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5 4H21.5C21.5 3.73478 21.3947 3.48043 21.2071 3.29289C21.0196 3.10536 20.7652 3 20.5 3V4ZM19.5 16C19.5 16.2652 19.6054 16.5196 19.7929 16.7071C19.9804 16.8946 20.2348 17 20.5 17C20.7652 17 21.0196 16.8946 21.2071 16.7071C21.3947 16.5196 21.5 16.2652 21.5 16H19.5ZM8.50002 3C8.2348 3 7.98045 3.10536 7.79291 3.29289C7.60538 3.48043 7.50002 3.73478 7.50002 4C7.50002 4.26522 7.60538 4.51957 7.79291 4.70711C7.98045 4.89464 8.2348 5 8.50002 5V3ZM3.79302 19.293C3.69751 19.3852 3.62133 19.4956 3.56892 19.6176C3.51651 19.7396 3.48892 19.8708 3.48777 20.0036C3.48662 20.1364 3.51192 20.2681 3.5622 20.391C3.61248 20.5138 3.68673 20.6255 3.78063 20.7194C3.87452 20.8133 3.98617 20.8875 4.10907 20.9378C4.23196 20.9881 4.36364 21.0134 4.49642 21.0123C4.6292 21.0111 4.76042 20.9835 4.88242 20.9311C5.00443 20.8787 5.11477 20.8025 5.20702 20.707L3.79302 19.293ZM19.5 4V16H21.5V4H19.5ZM20.5 3H8.50002V5H20.5V3ZM19.793 3.293L3.79302 19.293L5.20702 20.707L21.207 4.707L19.793 3.293V3.293Z"
      fill="#D1D5DB"
    />
  </svg>
);

export default ArrowIcon2;
