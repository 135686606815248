import React from "react";

const EyeIcon2 = (props) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0"
      style={{maskType: "alpha"}}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="19"
    >
      <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M14.85 17.4496L11.7 14.3371C11.2625 14.4746 10.8219 14.5777 10.3781 14.6465C9.93438 14.7152 9.475 14.7496 9 14.7496C7.1125 14.7496 5.43125 14.2277 3.95625 13.184C2.48125 12.1402 1.4125 10.7871 0.75 9.12461C1.0125 8.46211 1.34375 7.84649 1.74375 7.27773C2.14375 6.70898 2.6 6.19961 3.1125 5.74961L1.05 3.64961L2.1 2.59961L15.9 16.3996L14.85 17.4496ZM9 12.4996C9.1375 12.4996 9.26562 12.4934 9.38437 12.4809C9.50313 12.4684 9.63125 12.4434 9.76875 12.4059L5.71875 8.35586C5.68125 8.49336 5.65625 8.62149 5.64375 8.74024C5.63125 8.85898 5.625 8.98711 5.625 9.12461C5.625 10.0621 5.95312 10.859 6.60938 11.5152C7.26562 12.1715 8.0625 12.4996 9 12.4996ZM14.475 12.8371L12.0938 10.4746C12.1812 10.2621 12.25 10.0465 12.3 9.82773C12.35 9.60898 12.375 9.37461 12.375 9.12461C12.375 8.18711 12.0469 7.39023 11.3906 6.73398C10.7344 6.07773 9.9375 5.74961 9 5.74961C8.75 5.74961 8.51562 5.77461 8.29688 5.82461C8.07812 5.87461 7.8625 5.94961 7.65 6.04961L5.7375 4.13711C6.25 3.92461 6.775 3.76523 7.3125 3.65898C7.85 3.55273 8.4125 3.49961 9 3.49961C10.8875 3.49961 12.5688 4.02148 14.0438 5.06523C15.5188 6.10898 16.5875 7.46211 17.25 9.12461C16.9625 9.86211 16.5844 10.5465 16.1156 11.1777C15.6469 11.809 15.1 12.3621 14.475 12.8371ZM11.0063 9.38711L8.75625 7.13711C9.10625 7.07461 9.42813 7.10273 9.72188 7.22148C10.0156 7.34023 10.2688 7.51211 10.4813 7.73711C10.6938 7.96211 10.8469 8.22149 10.9406 8.51523C11.0344 8.80898 11.0563 9.09961 11.0063 9.38711Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default EyeIcon2;
