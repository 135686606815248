import {toast, Slide, Bounce} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {MdClose} from "react-icons/md"; // Using React Icons for the X icon

const useNotification = () => {
  const STATUSES = {
    SUCCESS: "success",
    ERROR: "error",
    LOADING: "loading",
    ACTION: "action",
  };
  const toastOptions = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const customStyle = {
    success: {
      backgroundColor: "#008000",
      color: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins, sans-serif",
      padding: "16px",
      borderRadius: "8px",
      fontWeight: "400",
    },
    error: {
      backgroundColor: "#71101A",
      color: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins, sans-serif",
      padding: "16px",
      borderRadius: "8px",
      fontWeight: "400",
    },
    default: {
      backgroundColor: "#333",
      color: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins, sans-serif",
      padding: "16px",
      borderRadius: "8px",
      fontWeight: "400",
    },
  };

  const showToast = (message, type, title = "Successful") => {
    switch (type) {
      case STATUSES.ACTION:
        return toast.success(<CustomToast title={title} message={message} />, {
          ...toastOptions,
          transition: Slide,
          icon: false,
          closeButton: CustomCloseButton,
        });
      case STATUSES.ERROR:
        return toast.error(message, {
          theme: "colored",
          ...toastOptions,
          style: customStyle.error,
          transition: Bounce,
          closeButton: CustomCloseButton,
        });
      case STATUSES.SUCCESS:
        return toast.success(message, {
          theme: "colored",
          transition: Slide,
          style: customStyle.success,
          ...toastOptions,
        });
      default:
        return toast.info(message, {
          ...toastOptions,
          style: customStyle.default,
        });
    }
  };

  return {showToast, STATUSES};
};

export default useNotification;

const CustomCloseButton = ({closeToast}) => (
  <button
    onClick={closeToast}
    style={{
      border: "none",
      background: "transparent",
      cursor: "pointer",
      fontSize: "24px",
      paddingLeft: "8px",
      float: "right",
      position:"absolute",
      top: "50%",
      right: "5%",
      transform: "translateY(-50%)",
    }}
  >
    {/* width  height 15px */}
    <MdClose
      style={{
        width: "24px",
        height: "24px",
      }}
    />
  </button>
);

const CustomToast = ({title, message}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      borderRadius: "8px",
      background: "#ffffff",
      fontFamily: "Poppins, sans-serif",
      width: "100%",
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: "#0044cc",
      }}
    >
      <div
        style={{
          height: "48px",
          width: "4px",
          backgroundColor: "#0044cc",
          marginRight: "8px",
        }}
      ></div>
      <div className="flex flex-col ">
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          {title}
        </span>
        <div
          style={{
            marginTop: "4px",
            fontSize: "14px",
            color: "#666",
          }}
        >
          {message}
        </div>
      </div>
    </div>
  </div>
);
