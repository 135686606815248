import React from "react";
import { Field, ErrorMessage, } from "formik";
import { Input, Label, Select } from "../../ui";
import * as Yup from "yup";
import { BsCreditCard2Front, BsShieldLock } from 'react-icons/bs';

const billingOptions = [
  "Bill This Customer Later (During Shipments)",
  "USA",
 "Germany",
];

const handleExpirationDateInput = (e) => {
  const { value } = e.target;
  e.target.value = value
    .replace(/^([1-9]\/|[2-9])$/g, '0$1/') // 3 > 03/
    .replace(/^(0[1-9]|1[0-2])$/g, '$1/') // 11 > 11/
    .replace(/^([0-1])([3-9])$/g, '0$1/$2') // 13 > 01/3
    .replace(/^(\d{2})(\d{2})$/g, '$1/$2') // 1122 > 11/22
    .replace(/\/\//g, '/'); // Prevent multiple slashes
};

const Step3MemberBilling = () => {

  return (
    <div className="flex flex-col justify-start gap-6 w-full">
      <h2 className="text-h6 font-bold text-dark">Billing</h2>

      <div className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="billingOptionValue">Billing Options</Label>
          <Field name="billingOptionValue">
            {({ field }) => (
              <Select
                id="billingOptionValue"
                {...field}
                className="w-full px-3 py-2 border rounded-md"
              >
                <option value="" className="hidden">Select billing option</option>
                {billingOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            )}
          </Field>
          <ErrorMessage
            name="billingOptionValue"
            component="div"
            className="text-sm text-red"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="cardHolderName">Card Holder's Name</Label>
          <Field name="cardHolderName">
            {({ field }) => (
              <Input
                type="text"
                id="cardHolderName"
                placeholder="Enter Card Holder's Name"
                {...field}
                className="w-full"
              />
            )}
          </Field>
          <ErrorMessage
            name="cardHolderName"
            component="div"
            className="text-sm text-red"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="space-y-2 md:col-span-1">
            <Label htmlFor="cardNumber">Card Number</Label>
            <div className="relative">
              <Field name="cardNumber">
                {({ field }) => (
                  <Input
                    type="text"
                    id="cardNumber"
                    placeholder="Card Number"
                    {...field}
                    className="w-full pl-10"
                    maxLength="16"
                  />
                )}
              </Field>
              <BsCreditCard2Front className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
            <ErrorMessage
              name="cardNumber"
              component="div"
              className="text-sm text-red"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="expirationDate">Expiration Date</Label>
            <Field name="expirationDate">
              {({ field }) => (
                <Input
                  type="text"
                  id="expirationDate"
                  placeholder="MM / YY"
                  {...field}
                  className="w-full"
                  maxLength="5"
                  onInput={handleExpirationDateInput}
                />
              )}
            </Field>
            <ErrorMessage
              name="expirationDate"
              component="div"
              className="text-sm text-red"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="cvv">CVV</Label>
            <div className="relative">
              <Field name="cvv">
                {({ field }) => (
                  <Input
                    type="text"
                    id="cvv"
                    placeholder="Enter CVV"
                    {...field}
                    className="w-full pl-10"
                    maxLength="4"
                  />
                )}
              </Field>
              <BsShieldLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
            <ErrorMessage
              name="cvv"
              component="div"
              className="text-sm text-red"
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="billingAddress">Billing Address</Label>
          <Field name="billingAddress">
            {({ field }) => (
              <Input
                type="text"
                id="billingAddress"
                placeholder="Enter Billing Address"
                {...field}
                className="w-full"
              />
            )}
          </Field>
          <ErrorMessage
            name="billingAddress"
            component="div"
            className="text-sm text-red"
          />
        </div>

       
      </div>
    </div>
  );
};

export const step3Validation = Yup.object().shape({
  billingOptionValue: Yup.string().required("Billing option is required"),
  cardHolderName: Yup.string().required("Card holder's name is required"),
  cardNumber: Yup.string()
    .required("Card number is required")
    .matches(/^[0-9]{16}$/, "Card number must be 16 digits"),
  expirationDate: Yup.string()
    .required("Expiration date is required")
    .matches(/^(0[1-9]|1[0-2])\/([0-9]{2})$/, "Must be in MM/YY format"),
  cvv: Yup.string()
    .required("CVV is required")
    .matches(/^[0-9]{3,4}$/, "CVV must be 3 or 4 digits"),
  billingAddress: Yup.string().required("Billing address is required"),
});

export default Step3MemberBilling;

