import React from "react";

function ProgressTracker({ currentStep , steps }) {


  return (
    <div className="flex items-start gap-2">
      {steps.map((label, index) => (
        <Step 
          key={index} 
          label={label} 
          index={index} 
          isCurrent={index === currentStep} 
          isCompleted={index < currentStep} 
          isLast={index === steps.length - 1} 
        />
      ))}
    </div>
  );
}

function Step({ label, index, isCurrent, isCompleted, isLast }) {
  return (
    <div className="flex w-full flex-1 items-start flex-col gap-2">
      <div className="inline-flex items-center w-full justify-start gap-2">
        <StepCircle index={index} isCurrent={isCurrent} isCompleted={isCompleted} />
        {!isLast && <ProgressLine isCompleted={isCompleted} />}
      </div>
      <StepLabel label={label} isCurrent={isCurrent} isCompleted={isCompleted} />
    </div>
  );
}

function StepCircle({ index, isCurrent, isCompleted }) {
  const circleClass = isCurrent || isCompleted
    ? "bg-blue text-white"
    : "text-primary bg-[#F5F5FA]";

  return (
    <span
      className={`w-[28px] h-[28px] rounded-full flex items-center justify-center font-bold text-small ${circleClass}`}
    >
      {index + 1}
    </span>
  );
}

function ProgressLine({ isCompleted }) {
  return (
    <div className={`flex-1 custom-dotted-line ${isCompleted ? 'completed' : ''}`} />
  );
}

function StepLabel({ label, isCurrent, isCompleted }) {
  const labelClass = isCurrent
    ? "text-dark"
    : isCompleted
    ? "text-primary"
    : "text-gray-400";

  return (
    <span className={`text-small font-semibold ${labelClass}  w-full md:w-[50%] max-w-[150px]`}>
      {label}
    </span>
  );
}

export default ProgressTracker;
