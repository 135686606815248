import React from "react";
import {Button, Container} from "../../../components/ui";
import EnhancedSetupEmailForm from "./SetupEmailForm";
import SetupNotificationsForm from "./SetupNotificationsForm";
import {Link} from "react-router-dom";
import {ArrowBackIcon} from "../../../assets/icons";
import dummyUsersAccounts from "../../../utils/data/users";

const SetupEmailsPage = () => {
  // const [emailSetup, setEmailSetup] = useState(null);

  const handleSubmit = (values) => {
    console.log("values", values);
  };

  const filteredSentMailTrueUSers = dummyUsersAccounts.filter(
    (user) => user.sentEmail === true,
  );

  return (
    <Container type="flex" className="px-4 md:px-8 pt-1 gap-1" size="xxl">
      <Link to="/email-history" className="w-fit">
        <Button aschild variant="ghost" size="sm">
          <ArrowBackIcon className="text-black hover:text-primary" />
        </Button>
      </Link>
      <h5 className="text-h5 font-bold flex-1 mb-1">Welcome Emails</h5>

      <div className="flex flex-col flex-wrap justify-between md:flex-row w-full gap-4">
        <div className="w-full md:w-[65%] ">
          <EnhancedSetupEmailForm onSubmit={handleSubmit} />
        </div>

        {/* Divider */}
        <div className="hidden md:block w-px bg-gray-200 self-stretch mx-2"></div>

        <div className="w-full md:w-[30%] min-w-[310px]">
          <SetupNotificationsForm
            onSubmit={handleSubmit}
            initialData={filteredSentMailTrueUSers}
          />
        </div>
      </div>
    </Container>
  );
};

export default SetupEmailsPage;
