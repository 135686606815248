import React, { useRef, useState } from "react";
import { TiPlus } from "../../assets/icons";
import brandPic from "../../assets/images/brandPic.png";
import { SearchIcon, NotificationIcon } from "../../assets/icons";
import Button from "../ui/Button";
import useClickOutside from "../../hooks/useOnClickOutside";
import { Input } from "../ui";
import { useLogoutMutation } from "../../hooks/useLoginMutation";
import useAuthStore from "../../store/authStore";
import { Link } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { FiUser } from 'react-icons/fi';
import { HiMenuAlt2 } from "react-icons/hi";
import useModals from "../../hooks/useModals";


function Navbar() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const [openUserDropdown, setOpenUserDropdown] = useState(false);
  const modalRef = useClickOutside(() => setOpenUserDropdown(false));

  const searchInputRef = useRef(null);

  const { user } = useAuthStore();

  const {openModal } =useModals();


  const { mutate, isPending } = useLogoutMutation();

  const getUserFirst2Letters = (user) => {
    const name = user?.name || ["A", "B"];
    const firstLetter = name[0].toUpperCase();
    const secondLetter = name[1].toUpperCase();
    return `${firstLetter}${secondLetter}`;
  };

 


  return (
    <header className='bg-white shadow flex flex-col gap-2 items-center justify-between px-4 pt-4 pb-2  border-b-[1px]'>
      
   
     <div className="flex items-center justify-between w-full">
     <h1 className='text-xl font-semibold text-gray-800'>
        <img src={brandPic} alt='' />
      </h1>

      <div className='inline-flex items-center gap-3 '>
        

        {/* Search Icon */}
        <Button
          variant='ghost'
          size='sm'
          className='text-gray-600 hover:text-gray-800'
          onClick={() => setIsSearchOpen(!isSearchOpen)}
        >
          <SearchIcon className='h-6 w-6 text-gray-400' />
        </Button>
        <button className='flex items-center text-gray-400 hover:text-gray-800'>
        <NotificationIcon className='h-6 w-6' />
      </button>

      <div
          className='relative h-[46px] w-[46px] rounded-full cursor-pointer'
          ref={modalRef}
        >
          <div
            onClick={() => setOpenUserDropdown(!openUserDropdown)}
            className='absolute select-none flex items-center justify-center top-0 right-0  bg-new-secondary-gray h-full w-full rounded-full'
          >
            <span className='text-white text-h6 spa font-semibold'>
              {getUserFirst2Letters(user)}
            </span>
          </div>

          {openUserDropdown && (
            <div className='absolute z-10  right-4 top-[130%]  w-48 bg-white border border-gray-200 rounded shadow-lg'>
              <Link to={"/user-profile"}>
                <Button
                variant="ghost"
                  className='flex justify-start px-4 py-2 text-gray-800 hover:bg-gray-100 w-full'
                  onClick={() => {
                    setOpenUserDropdown(false);
                  }}
                >
                 <span className="w-full flex items-center justify-start">
                  <FiUser className='mr-2 ' />
                  User Profile
                 </span>
                </Button>
              </Link>
              <Button
                variant="ghost"
                disabled={isPending}
                className='flex justify-start px-4 py-2 text-gray-800 hover:bg-gray-100 w-full'
                onClick={() => {
                  mutate();
                }}
              >
              <span className="w-full flex items-center justify-start">
              <FiLogOut className='mr-2' />
              Logout
              </span>

                
              </Button>
            </div>
          )}
        </div>
      </div>

      
     </div>

     <div className="flex items-center justify-between gap-5 w-full">
     <Button
          variant='ghost'
          size='sm'
          className='text-gray-600 hover:text-gray-800'
          onClick={() =>  openModal("menu")}
        >        <HiMenuAlt2 
        className='text-dark hover:text-gray-800 h-8 w-8 cursor-pointer'
        />

        </Button>

     <div className='flex items-center w-full justify-end gap-5  '>
        {/* Add new member button */}
        <Link to={"/memberships/add-member"}>
        <Button aschild variant='default'>
          <TiPlus />
          <span className="">          Add New Member
          </span>
        </Button>

        </Link>

       
        {/*a little circle for the persons account image */}
       
      </div>
     </div>


     
      <div
            className={` transition-all duration-300  w-full max-w-[345px]
            ${isSearchOpen ? " h-12 max-h-20" : " h-0 max-h-0 overflow-hidden"} "}
                `}
          >
            <Input
              type='text'
              ref={searchInputRef}
              className='border border-gray-300 rounded focus:outline-none  w-full overflow-hidden'
              placeholder='Search...'
              size='sm'
            />
          </div>
    </header>
  );
}

export default Navbar;
