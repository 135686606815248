import React from "react";
import { Modal } from "../../components/ui";

const ProductActionModal = ({
  isOpen,
  onClose,
  title,
  actionLabel,
  secondaryActionLabel,
  secondaryAction,
  selectedProduct,
  onSubmit,
  actionType ,
  isPending,

}) => {


  const getModalContent = () => {
    switch (actionType) {
      case 'delete':
        return "Are you sure you want to delete this product? This action cannot be undone.";
      case 'archive':
        return "Are you sure you want to archive this product? To unarchive, go to the archived products tab.";
      case 'unarchive':
        return "Are you sure you want to unarchive this product? ";
      default:
        return "";
    }
  };

  const getPendindActionLabel = () => {
    switch (actionType) {
      case 'delete':
        return 'Deleting...';
      case 'archive':
        return 'Archiving...';
      case 'unarchive':
        return 'Unarchiving...';
      default:
        return "";
    }
  }

  const body = (
    <div className="text-secondary-gray text-base2 flex flex-col gap-2">
        <p>
            {getModalContent()}
        </p>
        <p>Product : <span className="font-semibold text-base2">
                {selectedProduct?.name}
            </span>
            
        </p>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      body={body}
      actionLabel={isPending ? `${getPendindActionLabel()}` : actionLabel}
      secondaryAction={secondaryAction}
      secondaryActionLabel={secondaryActionLabel}
      onSubmit={onSubmit}
      disableSubmit={!selectedProduct || isPending}
      isPending={isPending}
    />
  );
};

export default ProductActionModal;
