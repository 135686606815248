import React from "react";
import { Container } from "../../components/ui";
import { ArrowIcon2 } from "../../assets/icons";
import Header from "../../components/ui/Header";
import { pageTitles } from "../../utils/data/dummyData";

const ReportsPage = () => {
  return (
    <Container type='flex' size='xxl' className='items-center py-4 p-5'>
      <div className='w-full max-w-[1500px] flex items-center justify-between'>
        <Header>{pageTitles.reportsPage}</Header>
      </div>

      <div className=' w-full max-w-[1500px]   grid grid-cols-12    items-center justify-center border-t  border-l rounded-md  '>
        <div className=' col-span-12 lg:col-span-6 border-r border-b '>
          <div className=' p-4 rounded-tl-[8px] relative  '>
            <h3 className='  text-lg text-dark font-semibold select-none   '>
              Sales Reports
            </h3>
            <h2 className=' f-f-r text-tiny text-secondary-grey my-2 '>
              Doloribus dolores nostrum quia qui natus officia quod et dolorem.
              Sit repellendus qui ut at blanditiis et quo et molestiae.
            </h2>
            <ArrowIcon2 />
          </div>
        </div>
        <div className=' col-span-12 lg:col-span-6 border-r border-b'>
          <div className=' p-4 rounded-tl-[8px] relative  '>
            <h3 className='  text-lg text-dark font-semibold select-none  '>
              Sales Reports
            </h3>
            <h2 className=' f-f-r text-tiny text-secondary-grey my-2 '>
              Doloribus dolores nostrum quia qui natus officia quod et dolorem.
              Sit repellendus qui ut at blanditiis et quo et molestiae.
            </h2>
            <ArrowIcon2 />
          </div>
        </div>
        <div className=' col-span-12 lg:col-span-6 border-r border-b'>
          <div className=' p-4 rounded-tl-[8px] relative  '>
            <h3 className='  text-lg text-dark font-semibold select-none  '>
              Sales Reports
            </h3>
            <h2 className=' f-f-r text-tiny text-secondary-grey my-2 '>
              Doloribus dolores nostrum quia qui natus officia quod et dolorem.
              Sit repellendus qui ut at blanditiis et quo et molestiae.
            </h2>
            <ArrowIcon2 />
          </div>
        </div>
        <div className=' col-span-12 lg:col-span-6 border-r border-b'>
          <div className=' p-4 rounded-tl-[8px] relative  '>
            <h3 className='  text-lg text-dark font-semibold select-none  '>
              Sales Reports
            </h3>
            <h2 className=' f-f-r text-tiny text-secondary-grey my-2 '>
              Doloribus dolores nostrum quia qui natus officia quod et dolorem.
              Sit repellendus qui ut at blanditiis et quo et molestiae.
            </h2>
            <ArrowIcon2 />
          </div>
        </div>
        <div className=' col-span-12 lg:col-span-6 border-r border-b'>
          <div className=' p-4 rounded-tl-[8px] relative  '>
            <h3 className='  text-lg text-dark font-semibold select-none  '>
              Sales Reports
            </h3>
            <h2 className=' f-f-r text-tiny text-secondary-grey my-2 '>
              Doloribus dolores nostrum quia qui natus officia quod et dolorem.
              Sit repellendus qui ut at blanditiis et quo et molestiae.
            </h2>
            <ArrowIcon2 />
          </div>
        </div>
        <div className=' col-span-12 lg:col-span-6 border-r border-b'>
          <div className=' p-4 rounded-tl-[8px] relative  '>
            <h3 className='  text-lg text-dark font-semibold select-none  '>
              Sales Reports
            </h3>
            <h2 className=' f-f-r text-tiny text-secondary-grey my-2 '>
              Doloribus dolores nostrum quia qui natus officia quod et dolorem.
              Sit repellendus qui ut at blanditiis et quo et molestiae.
            </h2>
            <ArrowIcon2 />
          </div>
        </div>
        <div className=' col-span-12 lg:col-span-6 border-r border-b'>
          <div className=' p-4 rounded-tl-[8px] relative  '>
            <h3 className='  text-lg text-dark font-semibold select-none  '>
              Sales Reports
            </h3>
            <h2 className=' f-f-r text-tiny text-secondary-grey my-2 '>
              Doloribus dolores nostrum quia qui natus officia quod et dolorem.
              Sit repellendus qui ut at blanditiis et quo et molestiae.
            </h2>
            <ArrowIcon2 />
          </div>
        </div>
        <div className=' col-span-12 lg:col-span-6 border-r border-b'>
          <div className=' p-4 rounded-tl-[8px] relative  '>
            <h3 className='  text-lg text-dark font-semibold select-none  '>
              Sales Reports
            </h3>
            <h2 className=' f-f-r text-tiny text-secondary-grey my-2 '>
              Doloribus dolores nostrum quia qui natus officia quod et dolorem.
              Sit repellendus qui ut at blanditiis et quo et molestiae.
            </h2>
            <ArrowIcon2 />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReportsPage;
