import React from 'react'
import ProductCard from '../../components/cards/ProductCard'
import { Button, Container } from '../../components/ui'
import add_ad from '../../assets/images/add_ad.png'
import { Link } from 'react-router-dom'
import { TiPlus } from 'react-icons/ti'
import Pagination from '../../components/pagination/Pagination'
import Image from '../../components/ui/Image'

const ProductListContainer = ({ 
  currentItems,
  currentPage,
  totalPages,
  totalItems,
  perPage,
  handlePageChange,
  handleItemsPerPageChange,
  emptyMessage, 
  getStartedMessage, 
  onDelete, 
  onArchive, 
  onUnarchive 
}) => {

  if (!currentItems || currentItems.length === 0) {
    return (
      <Container className="mt-6 text-center h-[60vh] justify-center items-center" size='xl'>
        <div className='flex flex-col items-center  max-w-[901px] w-full justify-center space-y-4 p-10 border rounded-lg'> 
          <div>
          <Image src={add_ad} alt='add_ad' className='w-[48px] h-[48px]' />
            </div> 
          <h1 className='text-h1 font-semibold text-dark text-lg'>{emptyMessage}</h1>
          <p className='text-base2 font-normal text-secondary-grey'>{getStartedMessage}</p>
          <Link to="/products/add-product">
            <Button 
              size='sm'
              className={`w-full px-5`}
              aschild 
              variant="default">
              <TiPlus /> Add Product
            </Button>
          </Link>
        </div>
      </Container>
    )
  }

  return (
    <div className='w-full  min-h-[75vh] flex flex-col items-center justify-between'>
      <div className="
        grid 
        grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 
        gap-1 md:gap-2 lg:gap-6 mt-4 
        w-full
        justify-items-center 
        place-items-start self-start max-w-[1500px] px-2 md:px-5"
      >
        {currentItems.map((product) => (
          <ProductCard 
            key={product.id} 
            product={product} 
            onDelete={onDelete}
            onArchive={onArchive}
            onUnarchive={onUnarchive}
          />
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        totalItems={totalItems}
        itemsPerPage={perPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </div>
  )
}

export default ProductListContainer

