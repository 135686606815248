import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../api/queryKeys";
import { createMember, getAllMembers, getMember, updateMember } from "../api/members/membersQueries";
import { useNavigate } from "react-router-dom";
import useNotification from "./useNotification";


export const useGetMembers = () => {


  const { data , isLoading , isError } = useQuery({
    queryKey: queryKeys.MEMBERS,
    queryFn:getAllMembers,
    refetchOnWindowFocus: false,
    cacheTime: 600000, // Keep in cache for 5 minutes
    staleTime: 60000, // Keep in cache for 1 minute

  });

  return { data , isLoading , isError };
}


export const useGetMember = (memberId) => {
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: [queryKeys.MEMBER, memberId],
    queryFn: () => getMember(memberId),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    cacheTime: 60000, 
  });

  return { data, isLoading, isError, isSuccess };
}


export const useUpdateMember = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {STATUSES, showToast} = useNotification();

  return useMutation({
    mutationFn: async ({id, memberData}) => {
      await updateMember(id, memberData);
    },
    onSuccess: (response, {id}) => {
      // Optionally, update the members list query if needed
      queryClient.removeQueries({
        queryKey: queryKeys.MEMBERS
      });
      queryClient.removeQueries({
        queryKey: [queryKeys.MEMBER, `${id}`]
      });

      showToast("Member updated successfully", STATUSES.ACTION, "Success");
      navigate("/memberships");
    },
    onError: (error) => {
      showToast(
        error.message || "Failed to update member, Please try again ",
        STATUSES.ERROR,
      );
    },
  });
}

export const useCreateMember = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {STATUSES, showToast} = useNotification();

  const createMemberMutation = useMutation({
    mutationFn: createMember,
    onSuccess: (newMember) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.MEMBERS,
      });

      queryClient.removeQueries(queryKeys.MEMBERS);

      showToast("Member created successfully",STATUSES.ACTION , "Success");
      navigate("/memberships");
    },
    onError: (error) => {
      showToast(
        error.message || "Failed to create member, Please try again ",
        STATUSES.ERROR,
      );
    },
  });

  return  createMemberMutation 
}

