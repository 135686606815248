import BollingerImg from "../../assets/images/wines/bollinger.png";
import CabernetSauvignonImg from "../../assets/images/wines/cabernet.png";

export const productsData = [
  {
    id: 1,
    name: "Bollinger",
    description: "Product 1 description",
    price: 100,
    image: `${BollingerImg}`,
    status: "Active",
    quantityAvailable: 0,

    size: "75ml",
    category: "Red Wine",
  },
  {
    id: 2,
    name: "Cabernet Sauvignon",
    description: "Product 2 description",
    price: 200,
    image: `${CabernetSauvignonImg}`,
    status: "Active",
    quantityAvailable: 0,
    size: "75ml",
    category: "Champagne",
  },
  {
    id: 3,
    name: "Bollinger",
    description: "Product 3 description",
    price: 300,
    image: `${BollingerImg}`,
    status: "Active",
    quantityAvailable: 43,
    size: "100ml",
    category: "White Wine",
  },
  {
    id: 4,
    name: "Cabernet Franc",
    description: "Product 4 description",
    price: 400,
    image: `${CabernetSauvignonImg}`,
    status: "Active",
    quantityAvailable: 5,
    size: "75ml",
    category: "Red Wine",
  },
  {
    id: 5,
    name: "Cabernet Sauvignon",
    description: "Product 5 description",
    price: 500,
    image: `${CabernetSauvignonImg}`,
    status: "Active",
    quantityAvailable: 20,
    size: "100ml",
    category: "Champagne",
  },
  {
    id: 6,
    name: "Cabernet Sauvignon",
    description: "Product 6 description",
    price: 500,
    image: `${CabernetSauvignonImg}`,
    status: "Active",
    quantityAvailable: 20,
    size: "100ml",
    category: "Champagne",
  },
];
