/**
 * Converts an ISO date string to a readable date format.
 * @param {string} isoDate - The ISO date string to convert.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (isoDate ,timeIncluded= true) => {
  const date = new Date(isoDate);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: timeIncluded ? "numeric" : undefined,
    minute: timeIncluded ? "numeric" : undefined,
  };
  return date.toLocaleDateString(undefined, options);
};

/**
 * Formats a number with commas as thousands separators.
 * @param {number} number - The number to format.
 * @returns {string} - The formatted number string.
 */
export const formatNumber = (number) => {
  return number.toLocaleString();
};