import { createBrowserRouter, Outlet } from "react-router-dom";
import NotFoundPage from "../pages/404_page";
import AuthLayout from "../layouts/AuthLayout";
import LoginPage from "../pages/auth/login";
import ForgotPasswordPage from "../pages/auth/forgot-password";
import ResetPasswordPage from "../pages/auth/reset-password";
import ProtectedRoute from "../components/ProtectedRoute";
import AppLayout from "../layouts/AppLayout";
import DashboardPage from "../pages/dashboard";
import WineryPage from "../pages/club-management";
import AddClubPage from "../pages/club-management/add-club";
import EditClubPage from "../pages/club-management/edit-club";
import ProductsLayout from "../layouts/ProductsLayout";
import ProductsPage from "../pages/products";
import AddProductPage from "../pages/products/add";
import EditProductPage from "../pages/products/edit";
import MembershipsPage from "../pages/memberships";
import AddMemberPage from "../pages/memberships/add";
import IntergrationsPage from "../pages/integrations";
import ReleasesPage from "../pages/releases";
import EmailCampaignsPage from "../pages/emails/email_campaigns";
import ReportsPage from "../pages/reports";
import UserProfilePage from "../pages/user-profile";
import EmailHistoryPage from "../pages/emails/email_history";
import SetupEmailsPage from "../pages/emails/setup_emails";
import AdminRoute from "../components/AdminRoute";
import TenantsPage from "../pages/admin/tenants";
import ClubPage from "../pages/club-management/clubs";
import EditMemberPage from "../pages/memberships/edit";
import TenantSettingsPage from "../pages/account";
import AddRolePage from "../pages/account/roles_and_premissions/add_role";
import EditRolePage from "../pages/account/roles_and_premissions/edit";
import SettingsPage from "../pages/settings";

const RouterComponent = ({user, isAuthenticated}) => {

  const router = createBrowserRouter([
    {
      path: "*",
      element: <NotFoundPage />,
    },
    {
      element: <AuthLayout />,
      children: [
        { path: "login", element: <LoginPage /> },
        { path: "forgot-password", element: <ForgotPasswordPage /> },
        { path: "reset-password", element: <ResetPasswordPage /> },
      ],
    },
    {
      element: (
        <ProtectedRoute isAuthenticated={isAuthenticated}>
          <AppLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: "/", element: <DashboardPage user={user} /> },
        {
          path: "club-management",
          element: <WineryPage />,
          children: [
            { path: "clubs", element: <ClubPage /> },
            { path: "clubs/add-club", element: <AddClubPage /> },
            { path: "clubs/:id/edit", element: <EditClubPage /> },
          ],
        },
        {
          path: "products",
          element: <ProductsLayout />,
          children: [
            { index: true, element: <ProductsPage /> },
            { path: "add-product", element: <AddProductPage /> },
            { path: ":id/edit", element: <EditProductPage /> },
          ],
        },
        {
          path: "memberships",
          element: <Outlet />,
          children: [
            { index: true, element: <MembershipsPage /> },
            { path: "add-member", element: <AddMemberPage /> },
            { path: ":id/edit", element: <EditMemberPage /> },
          ],
        },
        { path: "integrations", element: <IntergrationsPage /> },
        { path: "releases", element: <ReleasesPage /> },
        { path: "send-email", element: <EmailCampaignsPage /> },
        { path: "reports", element: <ReportsPage /> },
        { path: "user-profile", element: <UserProfilePage /> },
        { path: "settings", element: <SettingsPage /> },

        {
          path: "email-history",
          children: [
            { index: true, element: <EmailHistoryPage /> },
            { path: "edit", element: <SetupEmailsPage /> },
          ],
        },
        {
          path: "account",
          element: <Outlet />,
          children: [
            { index: true, element: <TenantSettingsPage /> },
            { path: "add-role", element: <AddRolePage /> },
            {path: ":id/roles" , element: <EditRolePage />}
          ],
        },
        {
          path: "admin",
          element: (
            <AdminRoute isAdmin={user?.isAdmin || user?.id === 1}>
              <Outlet />
            </AdminRoute>
          ),
          children: [
            { path: "tenants", element: <TenantsPage /> },
          ],
        },
      ],
    },
  ]);

  return router;
};

export default RouterComponent;