import React from "react";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { LoadingSpinner } from "./components/ui";
import AuthProvider from "./components/AuthProvider";

// Hooks
import { useAuth } from "./hooks/useAuth";
import useIsMobile from "./hooks/useIsMobile";
import RouterComponent from "./router";

const App = () => {
  useIsMobile(); // Initializes and listens for screen size changes
  const { user, isAuthenticated, isLoading } = useAuth();

  const router = RouterComponent({ user, isAuthenticated });

  return (
    <AuthProvider>
      <div className="main__container">
        {isLoading && <LoadingSpinner />}
        <ToastContainer position="top-right" />
        <RouterProvider router={router} />
      </div>
    </AuthProvider>
  );
};

export default App;

