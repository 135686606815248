import React from "react";
import cn from "../../utils/cn";


const headerStyles = {
  base: "text-h6 sm:text-h5  font-semibold sm:font-bold",
 
};

const Header = (
  {
    className,
    variant = "default",
    aschild = false,
    ...props
  }
) => {
  const Component = aschild ? "h2" : "div"; 
  return (
    <Component
      className={cn(
        headerStyles.base,
        className
      )}
      {...props}
    />
  );
}

Header.displayName = "Header";

export default Header;