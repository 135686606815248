import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {queryKeys} from "../../api/queryKeys";
import {
  activateUser,
  createUser,
  deleteUser,
  disableUser,
  getAllUsersData,
  updateProfile,
} from "../../api/user/userQueries";
import useNotification from "../useNotification";
import {useNavigate} from "react-router-dom";
import useAuthStore from "../../store/authStore";

export const useUsers = () => {
  const {data, isLoading, isError, status} = useQuery({
    queryKey: queryKeys.USERS,
    queryFn: getAllUsersData,
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 10 minutes
    refetchOnWindowFocus: false, // Disable refetching when tab gains focus
    refetchOnMount: false, // Do not refetch on component mount
  });

  return {data, isLoading, isError, status};
};

export const useCreateUser = () => {
  const {STATUSES, showToast} = useNotification();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const createUserMutation = useMutation({
    mutationFn: createUser,
    onSuccess: (newUser) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.USERS,
        stale: true,
        exact: true,
        type: "all",
      });

      const user = newUser.data.user;
      user.status = "active";
      queryClient.setQueryData(queryKeys.USERS, (oldData) => {
        return {
          ...oldData,
          data : {
            users : [...oldData.data.users, user]
          }
          
        };
      });

      showToast("User created successfully", STATUSES.SUCCESS);
      navigate("/account?tab=users");
    },
    onError: (error) => {
      showToast(
        error.message || "Failed to create user, Please try again ",
        STATUSES.ERROR,
      );
    },
    // onSettled: () => {
    //   queryClient.invalidateQueries({ queryKey: queryKeys.USERS });
    // }
  });

  return createUserMutation;
};

export const useUpdateProfile = () => {
  const {STATUSES, showToast} = useNotification();
  const queryClient = useQueryClient();
  const {updateUser} = useAuthStore();

  return useMutation({
    mutationFn: async (newProfile) => {
      return await updateProfile(newProfile);
    },
    onMutate: async (newProfile) => {
      // Cancel any outgoing refetches to avoid overwriting our optimistic update
      await queryClient.cancelQueries({queryKey: queryKeys.USER});
    },
    onSuccess: (response, newProfile) => {
      showToast("Profile updated successfully", STATUSES.ACTION);

      queryClient.refetchQueries({queryKey: queryKeys.USERS});

      //update user profile wthout saving password and passoword_confirmation fields and not passing this in updateProfile API
      const {password, password_confirmation, ...updatedProfile} = newProfile;
      updateUser(updatedProfile);
    },
    onError: (error, newProfile, context) => {
      // Rollback to the previous values if mutation fails
      showToast(
        error.message || "Failed to update profile. Please try again",
        STATUSES.ERROR,
      );
    },
    onSettled: () => {
      // Refetch after error or success to ensure cache consistency
      queryClient.invalidateQueries({queryKey: queryKeys.USER});
    },
  });
};

export const useUserAction = () => {
  const {STATUSES, showToast} = useNotification();
  const queryClient = useQueryClient();

  const actionMap = {
    delete: deleteUser,
    disable: disableUser,
    activate: activateUser,
  };

  const successMessages = {
    delete: "User Deleted Successfully",
    disable: "User Disabled Successfully",
    activate: "User Activated Successfully",
  };

  const errorMessages = {
    delete: "Failed to Delete User",
    disable: "Failed to Disable User",
    activate: "Failed to Enable User",
  };

  return useMutation({
    mutationFn: async ({action, userId}) => {
      return await actionMap[action](userId);
    },
    onMutate: async ({action, userId}) => {
      await queryClient.cancelQueries(queryKeys.USERS);
      const previousUsersData = queryClient.getQueryData(queryKeys.USERS);
      return {previousUsersData};
    },
    onSuccess: (response, {action, userId}) => {
      queryClient.setQueryData(queryKeys.USERS, (old) => {
        if (!old?.data?.users) return old;

        const updatedData = action === "delete"
          ? old.data.users.filter((user) => user.id !== userId)
          : old.data.users.map((user) => {
              if (user.id === userId) {
                return {
                  ...user,
                  status: action === "disable" ? "disabled" : "active",
                };
              }
              return user;
            });

        return {
          ...old,
          data: {
            users: updatedData,
          },
        };
      });

      showToast(response.message || successMessages[action], STATUSES.ACTION);
    },
    onError: (err, {action}) => {
      showToast(err.message || errorMessages[action], STATUSES.ERROR);
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: queryKeys.USERS});
    },
  });
};
