import React, { useState } from "react";
import UpcomingBirthdays from "../../components/BirthdayCalendar";
import { metricsData, revenueData } from "../../utils/data/dummyData";
import MetricCard from "../../components/cards/MetricCard";
import { activeMemberData, averageSpendData } from "../../utils/data/dummyData";
import { LineChart, RevenueChart } from "../../components/charts";
import { Select } from "../../components/ui";

const DashboardPage = ({ user }) => {
  const [timePeriod, setTimePeriod] = useState("");

  const handleSelectChange = (event) => {
    setTimePeriod(event.target.value);
  };

  return (
    <div className='px-4 py-6 flex flex-col gap-y-[24px] w-lg'>
      <div className="w-full max-w-[165px]">
        <Select
          name='timePeriod'
          id="timePeriod"
          placeholder='Time Period'
          onChange={handleSelectChange}
          value={timePeriod}
          className=''
          size='sm'
        >
          <option value='1-year'>Last 12 months</option>
          <option value='three-months'>Last 3 months</option>
          <option value='six-months'>Last 6 Months</option>
          <option value='twelve-months'>Last 12 months</option>
        </Select>
      </div>
      
      <div className='flex flex-col lg:flex-row gap-[16px] mb-[24px]'>
        <div className='w-full lg:w-1/3 grid grid-cols-2 gap-[16px]'>
          {metricsData.map((metric, index) => (
            <MetricCard key={index} {...metric} />
          ))}
        </div>
        <div className='w-full lg:w-2/3'>
          <RevenueChart data={revenueData} />
        </div>
      </div>

      <div className='flex flex-col lg:flex-row gap-[24px]'>
        <div className='w-full lg:w-2/3 flex flex-col gap-6'>
          <h6 className=' lg:text-h6 font-bold'>Active Member</h6>
          <LineChart data={activeMemberData} variant='default' />
          <LineChart data={averageSpendData} variant='primary' />
        </div>
        <div className='w-full lg:w-1/3'>
          <UpcomingBirthdays />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;

