import React, { useState, useCallback } from 'react';

const Image = React.memo(({ src, alt, className = '', ...props }) => {
  const [status, setStatus] = useState('loading');

  const handleLoad = useCallback(() => {
    setStatus('loaded');
  }, []);

  const handleError = useCallback(() => {
    setStatus('error');
  }, []);

  return (
    <div className={`image-container w-full ${className}`}>
      {status === 'loading' && (
        <div className="skeleton-loader" aria-hidden="true" />
      )}
      {status === 'error' && (
        <div className="error-placeholder" aria-label="Image failed to load">
          ❌
        </div>
      )}
      <img
        src={src || "/placeholder.svg"}
        alt={alt}
        className={`image ${status}`}
        onLoad={handleLoad}
        onError={handleError}
        loading="lazy"
        {...props}
      />
    </div>
  );
});

Image.displayName = 'Image';

export default Image;

