import React from 'react'
import { Container } from '../../../components/ui'
import AddMemberForm from '../../../components/forms/addMemberForm'

const AddMemberPage = () => {
  return (
    <Container  size='xxl' className='p=4' >
      <AddMemberForm />
    </Container>
  )
}

export default AddMemberPage
