import React from "react";
import {Navigate} from "react-router-dom";
import { LoadingSpinner } from "./ui";
import useAuthStore from "../store/authStore";

function ProtectedRoute({children}) {
  const { isAuthenticated  ,user } = useAuthStore();

  if (user === undefined) {
    return <LoadingSpinner />;
  }



  return isAuthenticated ? children : <Navigate to="/login" />;
}

export default ProtectedRoute;
