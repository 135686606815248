import { EditIcon, EyeIcon, ReleasesIcon } from "../../assets/icons";
import Pagination from "../../components/pagination/Pagination";
import { usePagination } from "../../components/pagination/usePagination";
import {  Button, Container, Tag } from "../../components/ui";
import Table from "../../components/ui/table/Table";
import TableBody from "../../components/ui/table/TableBody";
import TableCell from "../../components/ui/table/TableCell";
import TableHeader from "../../components/ui/table/TableHeader";
import TableRow from "../../components/ui/table/TableRow";
import { DummyReleases } from "../../utils/data/releases";

const ReleasesTable = () => {
  const columns = ['Release Date', 'Member',  'Status',];

  // Use the pagination hook
  const {
    currentItems,
    currentPage,
    totalPages,
    totalItems,
    perPage,
    handlePageChange,
    handleItemsPerPageChange
  } = usePagination(DummyReleases);

const actions = [
  {
    icon:  <EditIcon className="h-5 w-5 mr-3" />,
    onClick: (row) => console.log('View details', row),
    tooltip: 'Edit',
    name: 'Edit Release',
    variant: 'outline',
  },
  {
    icon:  <EyeIcon className="h-5 w-5 mr-3 text-[#A0A5AB]" />,
    onClick: (row) => console.log('View details', row),
    tooltip: 'View',
    name: 'View Release',
    variant: 'outline',
  },
  {
    icon: <ReleasesIcon className="h-5 w-5 mr-3" />,
    onClick: (row) => console.log('View details', row),
    tooltip: 'Complete',
    name: 'Process Release',
    variant: 'default',

  },
];

  return (
    <Container>
      <Table>
        <TableHeader columns={columns} hasActions={true}/>
        <TableBody>
          {currentItems.map((row, index) => (
            <TableRow key={row.id}>
              {/* Member */}
              <TableCell>
                <div className="flex ">
                  <span className="text-base2 font-medium">{row.date}</span>
                </div>
              </TableCell>
              <TableCell>{row.members}</TableCell>

              <TableCell>
                <Tag
                  variant={{
                    Completed: 'default',
                    Upcoming: 'blue',
                    'On Hold': 'secondary',
                  }[row.status] || 'warning'}
                  sizes="md"
                  text={row.status}
                />
              </TableCell>
      
              {/* Actions */}
              <TableCell className=" lg:w-[40%]">
                   <div className="flex justify-end items-center gap-4  w-full">
                   {actions.map((action, actionIndex) => (
                     <Button
                       key={actionIndex}
                       className=" text-center flex justify-center"
                       onClick={() => action.onClick(row)}
                       size="action"
                       variant={action.variant}
                       title={action.tooltip}
                     >
                       {action.icon} {action.name}
                     </Button>
                   ))}
                 </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        totalItems={totalItems}
        itemsPerPage={perPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </Container>
  )

};

export default ReleasesTable;
