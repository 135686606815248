import React from "react";
import {  useFormikContext } from "formik";
import * as Yup from "yup";
import { Button, Label } from "../../ui";

const Step3RefineAudience = () => {
  const { values, errors, setFieldValue } = useFormikContext();

  const audiencesData = [
    { label: "Member on hold", value: "Member on hold" },
    { label: "Expired Membered", value: "Expired Membered" },
    { label: "Cancelled Member", value: "Cancelled Member" },
    { label: "Member not shipping", value: "Member not shipping" },
    { label: "Member with failed credit card", value: "Member with failed credit card" },
  ];

  const handleAudienceChange = (audienceValue) => {
    const newAudience = values.audienceTodiscord.includes(audienceValue)
      ? values.audienceTodiscord.filter((item) => item !== audienceValue)
      : [...values.audienceTodiscord, audienceValue];
    setFieldValue("audienceTodiscord", newAudience);
  };


  return (
    <div className="grid gap-[12px]  max-w-[556px] w-full mx-auto select-none">
      {/* Select Audience */}
      <Label>Select  Audience to discard</Label>

      <div className="flex gap-2 w-full flex-wrap">
        {audiencesData.map((audience, index) => (
          <div
            key={index}
            className={`border min-h-[69px] text-base2 font-normal rounded w-[180px] transition-all duration-300 ${
              values.audienceTodiscord.includes(audience.value) ? "bg-[#3D55D726]" : "bg-white"
            }`}
          >
            <Button
              type="button"
              variant="ghost"
              className={`${
                values.audienceTodiscord.includes(audience.value) ? "text-[#3D55D7]" : "text-secondary-grey"
              } w-full h-full font-normal`}
              onClick={() => handleAudienceChange(audience.value)}
            >
              {audience.label}
            </Button>
          </div>
        ))}
      </div>
      {/* Error Message */}
      {errors.audienceTodiscord && (
        <div className="error-message text-red max-sm:text-small">{errors.audienceTodiscord}</div>
      )}
    </div>
  );
};

// Validation schema for Step 3
export const Step3RefineAudienceValidation = Yup.object().shape({
  // Required array with at least one item
  audienceTodiscord: Yup.array().min(1, "Select at least one audience"),
});

export default Step3RefineAudience;
