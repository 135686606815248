import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Formik, Form } from "formik";
import Step1PersonalInfo, { step1Validation } from "./Step1PersonalInfo";
import Step2Releases, { step2Validation } from "./Step2Releases";
import Step3Billing, { step3Validation } from "./Step3Billing";
import { Button } from "../../ui";
import { ArrowBackIcon } from "../../../assets/icons";
import { Link, useNavigate } from "react-router-dom";
import ProgressTracker from "../ProgressTracker";
import MemberSteps from "../../../utils/steps/memberSteps";
import { useCreateMember } from "../../../hooks/useMembers";
import { ScrollToError } from "../../ScrollToError";

const steps = [
  { component: Step1PersonalInfo, validationSchema: step1Validation },
  { component: Step2Releases, validationSchema: step2Validation },
  { component: Step3Billing, validationSchema: step3Validation },
];

const initialValues = {
  firstName: "",
  lastName: "",
  birthday: "",
  unknownBirthday: false,
  email: "",
  phone: "",
  secondaryPhone: "",
  clubMembership: "",
  nrOfReleases: "",
  membershipStartDate: "",
  memberNumber: "",
  signedUpBy: "",
  firstReleaseDate: "",
  deliveryMethod: 'shipping',
  shippingAddress: "",
  city: "",
  state: "",
  zipCode: "",
  addNote: "",
  billingOptionValue: "",
  cardHolderName: "",
  cardNumber: "",
  expirationDate: "",
  cvv: "",
  billingAddress: "",
};

function AddMemberForm() {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const formikRef = useRef(null);
  const mutateAddUser = useCreateMember();

  const CurrentStepComponent = useMemo(() => steps[step].component, [step]);

  const formatValues = useCallback((values) => ({
    name: values.firstName,
    last_name: values.lastName,
    email: values.email,
    phone_number: values.phone,
    phone_number_2: values.secondaryPhone,
    is_drinking_age_confirmed: values.unknownBirthday,
    birthday: values.birthday,
    signed_up_by: values.signedUpBy,
    membership_start_date: values.membershipStartDate,
    membership_expiration_date: values.membershipStartDate,
    membership_number: values.memberNumber,
    notes: values.addNote,
    address_line_1: values.shippingAddress,
    state_id: parseInt(values.state) || "",
    city: values.city,
    zip: values.zipCode,
    country: "USA",
    club_id: parseInt(values.clubMembership),

  }), []);

  const handleNext = useCallback(async (values, actions) => {
    const nextStep = step + 1;
    const isLastStep = nextStep === steps.length;

    const operations = {
      submit: async () => {
        try {
          await mutateAddUser.mutateAsync(formatValues(values));
       
        } catch (error) {
          console.error("Error adding member:", error);
        }
      },
      advance: () => setStep(nextStep),
    };

    await operations[isLastStep ? 'submit' : 'advance']();
    actions.setSubmitting(false);
  }, [step, mutateAddUser, formatValues]);

  const handleBack = useCallback(() => {
    setStep((prevStep) => Math.max(0, prevStep - 1));
  }, []);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.validateForm();
      formikRef.current.setTouched({});
    }
    console.log("Step Data:", formikRef.current?.values);
  }, [step]);

  return (
    <div className='max-w-[1024px] w-full mx-auto flex flex-col justify-center gap-6 p-3'>
      <div>
        <Link to='/memberships'>
          <Button aschild variant='ghost' size='sm'>
            <ArrowBackIcon className='text-black hover:text-primary' />
          </Button>
        </Link>
        <h1 className='font-bold text-xl pt-2'>Add New Member</h1>
      </div>

      <ProgressTracker currentStep={step} steps={MemberSteps} />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={steps[step].validationSchema}
        onSubmit={handleNext}
        validateOnMount={false}
        validateOnBlur={false}
      >
        {({ isValid, values, isSubmitting }) => (
          <Form className='flex flex-col gap-8'>
            <ScrollToError />
            <CurrentStepComponent values={values} />
            <div className='flex justify-end gap-3 text-base2 font-medium'>
              {step > 0 ? (
                <Button
                  variant='outline'
                  type='button'
                  onClick={handleBack}
                  className='outline-1'
                >
                  Back
                </Button>
              ) : (
                <Button
                  variant='outline'
                  type='button'
                  onClick={() => navigate("/memberships")}
                  className='btn'
                >
                  Cancel
                </Button>
              )}
              <Button
                variant='default'
                type='submit'
                disabled={ isSubmitting || mutateAddUser.isPending }
                className='btn btn-primary text-base2 font-medium'
              >
                {step === steps.length - 1 ? 
                  (mutateAddUser.isPending ? "Adding..." : "Submit") 
                : "Next"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddMemberForm;

