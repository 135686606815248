import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { Input, Label, Select, TextArea } from "../../ui";
import { Iterator } from "../../ui/Iterator";

const Step1ClubInfo = () => {
  const { values, setFieldValue } = useFormikContext();

  // Increment and decrement functionality for "Max No. of Members"
  const handleIncrement = () =>
    setFieldValue("maxMembers", values.maxMembers + 1);
  const handleDecrement = () => {
    if (values.maxMembers > 1)
      setFieldValue("maxMembers", values.maxMembers - 1);
  };

  return (
    <div className='grid gap-[16px]'>
      <h2 className='text-xl font-bold mb-4'>Club Information</h2>

      <div className='flex flex-col [@media(min-width:500px)]:flex-row justify-between items-start gap-4 flex-wrap '>
        <div className='flex-1 flex flex-col items-start justify-between  h-full gap-2 w-full'>
          <Label htmlFor='clubName'>Club Name *</Label>
          <Field name='clubName' as={Input} placeholder='Enter Club Name' className="h-full px-2" />
          <ErrorMessage
            name='clubName'
            component='div'
            className='error-message text-red max-sm:text-small'
          />
        </div>
        <div>
          <div className='flex flex-col items-center justify-between  gap-2 '>
            <div>
            <Label htmlFor='maxMembers'>
              <div className="flex items-center gap-2">
              Limit No. of members

              <span>
                <Input
                  type='checkbox'
                  name='limitMembers'
                  size='checkbox'
                  checked={values.limitMembers}
                  onChange={ (e) =>  {
                  setFieldValue("limitMembers", e.target.checked);
                  if (!e.target.checked) {
                    setFieldValue("maxMembers", 1);
                  }
                  } } 
                />
              </span>

              </div>
            </Label>

            </div>
            <Iterator
              handleDecrement={handleDecrement}
              handleIncrement={handleIncrement}
              name='maxMembers'
              id='maxMembers'
              value={values.maxMembers}
              disabled={!values.limitMembers}
              className='max-h-[56px] h-full'
              onChange={(e) => setFieldValue("maxMembers", Number(e.target.value))}

            />
           
          </div>
          
        </div>
      </div>

      <div className="flex flex-col items-start gap-2">
        <Label htmlFor='description'>Club Description *</Label>
        <Field
          name='description'
          as={TextArea}
          placeholder='Enter Club Description'
          
        />
        <ErrorMessage
          name='description'
          component='div'
          className='error-message text-red max-sm:text-small'

        />

       
      </div>

      <div className="">
        <Label htmlFor='clubType'>What type of wine club is this? *</Label>
        <Field name='clubType' as={Select} placeholder='Select Club Type'>
          <option value='' >Select Club Type</option>
          <option value='1'>Member picks products</option>
          <option value='2'>Admin picks products</option>
          <option value='3'>Member picks products from packages</option>
        </Field>
        <ErrorMessage
          name='clubType'
          component='div'
          className='error-message text-red max-sm:text-small'
        />
      </div>
    </div>
  );
};

// Validation schema for Step 1
export const step1Validation = Yup.object().shape({
  clubName: Yup.string().required("Club name is required"),
  description: Yup.string().required("Description is required"),
  maxMembers: Yup.number()
    .required("Max number of members is required")
    .min(1, "Must be at least 1 member"),
  clubType: Yup.string().required("Please select a wine club type"),
});

export default Step1ClubInfo;
