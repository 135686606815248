import * as React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import useNotification from "../../hooks/useNotification";
import { Button, Input, Label, LoadingSpinner, Select, TextArea, ToggleSwitch } from '../ui';
import addImage from '../../assets/images/add_ad.png';
import { TiDelete } from 'react-icons/ti';
import { useCreateProduct, useGetProductTypes, useUpdateProduct } from '../../hooks/useProducts';
import { useMemo } from 'react';
import { ScrollToError } from '../ScrollToError';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
 
  productType: Yup.string().required('Product Type is required'),
  size: Yup.string().required('Size is required'),
  vintage: Yup.string().required('Vintage is required'),
  status: Yup.string().required('Status is required'),
  pricePerBottle: Yup.number().required('Price Per Bottle is required').min(0.1, 'Price Per Bottle must be greater than 0'),
  bottlesInStock: Yup.number().required('Bottles In Stock is required').min(0.1, 'Bottles In Stock must be greater than 0'),
  taxExempt: Yup.boolean().required('Tax Exempt is required'),
  description: Yup.string().required('Description is required'),
  productImage: Yup.mixed().required('Product Image is required')
});

const emptyStateData = {
  name: '',
  brand : '',
  appellation: '',
  sku : '',
  productType: '',
  size: '',
  vintage: '',
  status: '',
  pricePerBottle: '',
  bottlesInStock: '',
  taxExempt: false,
  description: '',
  productImage: null,
};

const ProductForm = ({initialProductData ,isEditForm = false}) => {
  const [selectedImage, setSelectedImage] = useState(initialProductData?.productUrl ||  null );
  const { STATUSES, showToast } = useNotification();
 const {data ,isError ,isLoading} =  useGetProductTypes();

 const initialValues = useMemo(() => {
    if (initialProductData) {
      return initialProductData;
    }
    return emptyStateData;
  }, [initialProductData]);


 const addProductMutation = useCreateProduct();
 const editProductMutation = useUpdateProduct();
  // Helper function to convert file to base64


 


  const handleSubmit = async (values, { setSubmitting ,resetForm }) => {

    const formData = new FormData();

    formData.append('product_type_id', values.productType);
    formData.append('name', values.name);
    formData.append('brand', values.brand);
    formData.append('appellation', values.appellation);
    formData.append('sku', values.sku);
    formData.append('description', values.description);
    formData.append('price', values.pricePerBottle);
    formData.append('apply_taxes', values.taxExempt ? 1 : 0);
    formData.append('stock', values.bottlesInStock);
    formData.append('status', values.status === 'available' ? 1 : 0);

    let valueSize = parseInt(values.size);
    let size_unit = 'ml';
    if (valueSize > 999) {
      valueSize = valueSize / 1000;
      size_unit = 'L';
    }
    formData.append('size_unit', size_unit);
    formData.append('bottle_size', valueSize);
    formData.append('year', values.vintage);

    // if product image is string url dont append
    if (typeof values.productImage !== 'string') { 
      formData.append('logo', values.productImage);
    }

    if (isEditForm && initialProductData) {
      await editProductMutation.mutateAsync({ id: values.id, productData: formData });
    }
    else {
      await addProductMutation.mutateAsync(formData);
    }

    setSubmitting(false);

    if (addProductMutation.isSuccess) {
      resetForm();
      setSubmitting(false);
    }

  
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    const maxSize = 10 * 1024 * 1024;
    if (file.size > maxSize) {
      showToast('Check image format or size', STATUSES.ERROR);
      return;
    }
    const newImage = {
      file,
      preview: URL.createObjectURL(file)
    };
    setSelectedImage(newImage);
    setFieldValue('productImage', event.target.files[0]);
    event.target.value = null;
  };

  const removeImage = (setFieldValue) => {
    setSelectedImage(null);
    setFieldValue('productImage', null);
  };

  if (isLoading) {
    return (
      <LoadingSpinner />
    )
  }

  if (isError) {
    return (
      <div>
        <p>There was an error fetching product types</p>
      </div>
    )
  }


  const productTypes = data.data?.productTypes.map((productType) => (
    <option key={productType.id} value={productType.id}>
      {productType.name}
    </option>
  ));



  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize={false}
      
    >
      {({ errors, touched, isSubmitting, isValid, values, setFieldValue }) => (
        <Form className="space-y-8">
            <ScrollToError />
          
          <div className='grid gap-[16px]'>
            <div className='flex max-sm:flex-col justify-between items-start gap-5 max-sm:gap-0'>
              <div className="flex-1 max-sm:w-full">
                <Label htmlFor="name">
                  Product Name <span className="text-red">*</span>
                </Label>
                <Field
                  as={Input}
                  type="text"
                  id="name"
                  name="name"
                  className={errors.name && touched.name ? 'outline-red' : ''}
                />
                {errors.name && touched.name && (
                  <p className="text-sm text-red mt-1">{errors.name}</p>
                )}
              </div>
              <div className="flex-1 max-sm:w-full ">
                <Label htmlFor="sku">
                  SKU 
                </Label>
                <Field
                  as={Input}
                  type="text"
                  id="sku"
                  name="sku"
                  className={errors.sku && touched.sku ? 'outline-red' : ''}
                />
                {errors.sku && touched.sku && (
                  <p className="text-sm text-red mt-1">{errors.sku}</p>
                )}
              </div>
            </div>
            <div className='flex justify-between items-start gap-5 max-sm:flex-col max-sm:gap-0'>
              <div className="flex-1 max-sm:w-full">
                <Label htmlFor="brand">
                  Brand 
                </Label>
                <Field
                  as={Input}
                  type="text"
                  id="brand"
                  name="brand"
                  className={errors.brand && touched.brand ? 'outline-red' : ''}
                />
                {errors.brand && touched.brand && (
                  <p className="text-sm text-red mt-1">{errors.brand}</p>
                )}
              </div>
              <div className="flex-1 max-sm:w-full">
                <Label htmlFor="appellation">
                  Appellation 
                </Label>
                <Field
                  as={Input}
                  type="text"
                  id="appellation"
                  name="appellation"
                  className={errors.appellation && touched.appellation ? 'outline-red' : ''}
                />
                {errors.appellation && touched.appellation && (
                  <p className="text-sm text-red mt-1">{errors.appellation}</p>
                )}
              </div>
            </div>
            <div className='flex flex-col md:flex-row justify-between items-start gap-5'>
              <div className="flex-1 flex flex-col items-start justify-between gap-2 w-full">
                <Label htmlFor='productType'>Type of Product *</Label>
                <Field name="productType" as={Select} placeholder="Choose product type">
                  <option value="" className='hidden'>Select Product</option>
                  {productTypes}
                </Field>
                <ErrorMessage
                  name="productType"
                  component="div"
                  className="error-message text-red max-sm:text-small"
                />
              </div>
              <div className="flex-1 flex flex-col items-start justify-between gap-2 w-full">
                <Label htmlFor='size'>Size *</Label>
                <Field name="size" as={Select} placeholder="Select Size">
                  <option value="" >Select Size</option>
                  <option value="250">250ml</option>
                  <option value="350">350ml</option>
                  <option value="375">375ml</option>
                  <option value="500">500ml</option>
                  <option value="750">750ml</option>
                  <option value="1000">1L</option>
                  <option value="1500">1.5L</option>
                </Field>
                <ErrorMessage
                  name="size"
                  component="div"
                  className="error-message text-red max-sm:text-small"
                />
              </div>
            </div>

            <div className='flex flex-col md:flex-row justify-between items-start gap-5'>
              <div className="flex-1 flex flex-col items-start justify-between gap-2 w-full">
                <Label htmlFor='vintage'>Vintage *</Label>
                <Field name="vintage" as={Select} placeholder="Select Vintage">
                  <option value="" className='hidden'>Select Vintage</option>
                  {/* map array from 1990 */}
                  <option value="2025">2025</option>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  <option value="2018">2018</option>
                  <option value="2017">2017</option>
                  <option value="2016">2016</option>
                  <option value="2015">2015</option>
                </Field>
                <ErrorMessage
                  name="vintage"
                  component="div"
                  className="error-message text-red max-sm:text-small"
                />
              </div>
              <div className="flex-1 flex flex-col items-start justify-between gap-2 w-full">
                <Label htmlFor='status'>Status *</Label>
                <Field name="status" as={Select} placeholder="Select Status">
                  <option value="" className='hidden'>Select Status</option>
                  <option value="available">Available</option>
                  <option value="archived">Archived</option>
                </Field>
                <ErrorMessage
                  name="status"
                  component="div"
                  className="error-message text-red max-sm:text-small"
                />
              </div>
            </div>

            <div className='flex flex-col md:flex-row justify-between items-start gap-5'>
              <div className="flex-1 flex flex-col items-start justify-between gap-2 w-full">
                <Label htmlFor='pricePerBottle'>Price Per Bottle *</Label>
                <Field name="pricePerBottle">
                  {({ field }) => (
                    <Input
                      type="number"
                      id="pricePerBottle"
                      placeholder="Enter Price Per Bottle"
                      min={0}
                      {...field}
                      className="w-full"
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="pricePerBottle"
                  component="div"
                  className="error-message text-red max-sm:text-small"
                />
              </div>
              <div className="flex-1 flex flex-col items-start justify-between gap-2 w-full">
                <Label htmlFor='bottlesInStock'>Bottles in Stock</Label>
                <Field name="bottlesInStock">
                  {({ field }) => (
                    <Input
                      type="number"
                      id="bottlesInStock"
                      placeholder="Enter Bottles in Stock"
                      min={0}
                      {...field}
                      className="w-full"
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="bottlesInStock"
                  component="div"
                  className="error-message text-red max-sm:text-small"
                />
              </div>
            </div>

            <div className="flex justify-between items-center mt-2">
              <Label htmlFor="taxExempt">
                <span className="flex flex-col gap-1">
                  Tax Exempt product
                  <span className="text-small font-normal text-secondary-grey">
                    Do not apply tax to this product.
                  </span>
                </span>
              </Label>
              <ToggleSwitch
                name="taxExempt"
                checked={values.taxExempt}
                onChange={(checked) => setFieldValue("taxExempt", checked)}
              />
            </div>

            <div>
              <Label htmlFor="description">
                Product Description
              </Label>
              <Field
                as={TextArea}
                id="description"
                name="description"
                placeholder="Enter Product Description"
              />
            </div>

            <div className="mb-6">
              <Label htmlFor="productImage">Product Image </Label>
              <div className="mt-1 border border-dashed border-gray-300 p-4 rounded-md text-center">
                {selectedImage ? (
                  <div className="relative">
                    <img src={selectedImage.preview || selectedImage} alt="Selected" className="w-368 h-24 outline outline-2 outline-secondary-grey object-cover rounded-sm" />
                    <Button
                      type="button"
                      variant="icon"
                      size='sm'
                      onClick={() => removeImage(setFieldValue)}
                      className="absolute top-0 right-1 rounded-full"
                    >
                      <TiDelete stroke='red' fill='#71101A' size={30}  />
                    </Button>
                  </div>
                ) : (
                  <Label
                    htmlFor="productImage"
                    className="inline-flex justify-center w-full p-2 font-normal text-base2 rounded-md cursor-pointer gap-3"
                  >
                    <div className="flex flex-col items-center gap-4">
                      <img src={addImage} alt='add_ad' className='w-[24px] h-[24px] object-scale-down' />
                      <span className='text-base2 font-normal'>
                        <span className='text-blue font-medium'>Upload a file</span> or drag and drop
                      </span>
                      <span className='text-secondary-grey font-normal text-small'>
                        PNG, JPG, GIF up to 10MB
                      </span>
                    </div>
                  </Label>
                )}
                <Input
                  type="file"
                  id="productImage"
                  name="productImage"
                  accept="image/png, image/jpeg, image/gif"
                  className="hidden"
                  onChange={(event) => handleFileChange(event, setFieldValue)}
                />
              </div>
              <ErrorMessage name="productImage" component="div" className="error-message text-red max-sm:text-small" />
            </div>
          </div>

          <div className="flex justify-end space-x-4 py-5">
            <Link to="/products">
              <Button type="button" variant="outline" aschild>
                Cancel
              </Button>
            </Link>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? `${initialProductData 
                ? 'Updating...' 
                : 'Adding...'}` : `${initialProductData
                ? 'Update'
                : 'Add Product'}`}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProductForm;