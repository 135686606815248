import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../axiosInstance";
import { queryKeys } from "../queryKeys";

export const getAllRoles = async () => {
  try {
    const response = await axiosInstance.get("/roles");
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getAllPermissions = async () => {
  try {
    const response = await axiosInstance.get("/permissions");
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getRoleById = async (roleId) => {
  try {
    const response = await axiosInstance.get(`/roles/${roleId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}


export const updateRole = async (roleId, data) => {
  try {
    const response = await axiosInstance.put(`/roles/${roleId}`, data);

    if (!response.data) {
      throw new Error("Something Went Wrong.");
    }

    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error(`Error: ${error.response.status} - ${error.response.data?.message || error.message}`);
      throw new Error(error.response.data?.message || `Something Went Wrong.`);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Something Went Wrong.");
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      console.error(`Error: ${error.message}`); 
      throw new Error("Something Went Wrong..");
    }
  }
}


export const useGetAllRoles = () => {
  return useQuery({
    queryKey: queryKeys.ROLES,
    queryFn: getAllRoles,
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 10 minutes
    refetchOnWindowFocus: false, // Disable refetching when tab gains focus
    refetchOnMount: false, // Do not refetch on component mount
  });
};

export const useGetAllPermissions = () => {
  return useQuery({
    queryKey: queryKeys.PERMISSIONS,
    queryFn: getAllPermissions,
    cacheTime: 1000 * 60 * 60, // 1 hour
    refetchOnWindowFocus: false, // Disable refetching when tab gains focus
    refetchOnMount: false, // Do not refetch on component mount
  });
};

