import { useEffect } from "react";
import useAppStore from "../store/appStore";

const useIsMobile = () => {
  const setMobile = useAppStore((state) => state.setMobile);

  useEffect(() => {
    const checkIsMobile = () => {
      // Adjust the breakpoint as needed (e.g., 768px)
      const isMobile = window.innerWidth <= 976;
      setMobile(isMobile);
    };

    // Check on initial load
    checkIsMobile();

    // Add a resize listener
    window.addEventListener("resize", checkIsMobile);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, [setMobile]);
};
export default useIsMobile;
