import React from 'react';
import { Input, Label, Modal, Select } from '../../../components/ui';
import { withFormik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const ShippingModal = ({
  isOpen,
  onClose,
  title,
  footer,
  disabled,
  actionLabel,
  secondaryAction,
  secondaryActionLabel,
  handleSubmit,
  isSubmitting,
  initialValues,
  isValid,
}) => {
  const bodyContent = (
    <Form className="flex flex-col gap-4">
      <div className="flex flex-col gap-7 border-b pb-8">
        <div className="w-full flex flex-col gap-2">
          <Label htmlFor="shippingCarrier">Shipping Carrier</Label>
          <Field name="shippingCarrier" as={Select} placeholder="Select Shipping Carrier">
            <option value="" style={{ display: 'none' }}>Choose one</option>
            <option value="332">Fedex</option>
            <option value="323">UPS</option>
          </Field>
          <ErrorMessage
            name="shippingCarrier"
            component="div"
            className="error-message text-red max-sm:text-small"
          />
        </div>

        <div className="flex flex-col gap-2">
          <Label htmlFor="licenceKey">Licence Key*</Label>
          <Field
            name="licenceKey"
            as={Input}
            placeholder="Enter Licence Key"
            className="h-full px-2"
          />
          <ErrorMessage
            name="licenceKey"
            component="div"
            className="error-message text-red max-sm:text-small"
          />
        </div>

        <div className="flex flex-col gap-2">
          <Label htmlFor="accountNumber">Account Number</Label>
          <Field
            name="accountNumber"
            as={Input}
            placeholder="Enter Account Number"
            className="h-full px-2"
          />
          <ErrorMessage
            name="accountNumber"
            component="div"
            className="error-message text-red max-sm:text-small"
          />
        </div>
      </div>

      <div className="flex gap-7">
        <div className="flex flex-col gap-2 w-1/2">
          <Label htmlFor="password">Password</Label>
          <Field
            name="password"
            as={Input}
            placeholder="Enter Password"
            className="h-full px-2"
          />
          <ErrorMessage
            name="password"
            component="div"
            className="error-message text-red max-sm:text-small"
          />
        </div>

        <div className="flex flex-col gap-2 w-1/2">
          <Label htmlFor="username">Username</Label>
          <Field
            name="username"
            as={Input}
            placeholder="Enter Username"
            className="h-full px-2"
          />
          <ErrorMessage
            name="username"
            component="div"
            className="error-message text-red max-sm:text-small"
          />
        </div>
      </div>
    </Form>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      body={bodyContent}
      footer={footer}
      disabled={disabled || isSubmitting}
      actionLabel={actionLabel}
      secondaryAction={secondaryAction}
      secondaryActionLabel={secondaryActionLabel}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      disableSubmit={!isValid || isSubmitting}
    />
  );
};

export default withFormik({
  validateOnMount: true,
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    shippingCarrier: props.initialValues?.shippingCarrier || '',
    licenceKey: props.initialValues?.licenceKey || '',
    accountNumber: props.initialValues?.accountNumber || '',
    password: props.initialValues?.password || '',
    username: props.initialValues?.username || '',
  }),
  validationSchema: Yup.object().shape({
    shippingCarrier: Yup.string().required('Shipping Carrier is required'),
    licenceKey: Yup.string().required('Licence Key is required'),
    accountNumber: Yup.string().nullable(),
    password: Yup.string().nullable(),
    username: Yup.string().nullable(),
  }),
  handleSubmit: (values, { props, setSubmitting ,resetForm }) => {
    console.log('Submitted values:', values);
    props.onClose(); // Close the modal after submission
    setSubmitting(false); // Reset submission state
    resetForm()
  },
})(ShippingModal);
