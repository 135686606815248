import React from "react";
import { Modal } from "../../../components/ui";

const ClubActionModal = ({
  isOpen,
  onClose,
  title,
  actionLabel,
  secondaryActionLabel,
  secondaryAction,
  selectedClub,
  onSubmit,
  actionType ,
  isPending,

}) => {


  const getModalContent = () => {
    switch (actionType) {
      case 'delete':
        return "Are you sure you want to delete this club This action cannot be undone.";
      case 'archive':
        return "Are you sure you want to archive this club to unarchive, go to the archived clubs tab.";
      case 'unarchive':
        return "Are you sure you want to unarchive this club ";
      default:
        return "";
    }
  };

  const getPendindActionLabel = () => {
    switch (actionType) {
      case 'delete':
        return 'Deleting...';
      case 'archive':
        return 'Archiving...';
      case 'unarchive':
        return 'Unarchiving...';
      default:
        return "";
    }
  }



  const body = (
    <div className="text-secondary-gray text-base2 flex flex-col gap-2">
        <p>
            {getModalContent()}
        </p>
        <p>Club : <span className="font-semibold text-base2">
                {selectedClub?.name}
            </span>
            
        </p>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      body={body}
      actionLabel={isPending ? `${getPendindActionLabel()}` : actionLabel}
      secondaryAction={secondaryAction}
      secondaryActionLabel={secondaryActionLabel}
      onSubmit={onSubmit}
      disableSubmit={!selectedClub || isPending}
      isPending={isPending}
    />
  );
};

export default ClubActionModal;
