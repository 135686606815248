import Pagination from "../../../components/pagination/Pagination";
import { usePagination } from "../../../components/pagination/usePagination";
import {  Container, Tag } from "../../../components/ui";
import Table from "../../../components/ui/table/Table";
import TableBody from "../../../components/ui/table/TableBody";
import TableCell from "../../../components/ui/table/TableCell";
import TableHeader from "../../../components/ui/table/TableHeader";
import TableRow from "../../../components/ui/table/TableRow";
import { DummyEmailHistoryData } from "../../../utils/data/emailsHistory";

const EmailsTable = () => {
  const columns = ['Subject', 'Email From',  'Email To', 'Date', 'Status'];

  // Use the pagination hook
  const {
    currentItems,
    currentPage,
    totalPages,
    totalItems,
    perPage,
    handlePageChange,
    handleItemsPerPageChange
  } = usePagination(DummyEmailHistoryData);



  return (
    <Container>
      <Table>
        <TableHeader columns={columns} />
        <TableBody>
          {currentItems.map((row, index) => (
            <TableRow key={row.id}>
              {/* Member */}
              <TableCell>
                <div className="flex ">
                  <span className="text-base2 font-medium">{row.subject}</span>
                </div>
              </TableCell>
              <TableCell><span className="text-secondary-grey font-normal">{row.sender}</span></TableCell>
              <TableCell><span className="text-secondary-grey font-normal">{row.reciver} </span></TableCell>
                <TableCell><span className="text-secondary-grey font-normal">{row.date} </span></TableCell>

              <TableCell>
                <Tag
                  variant={{
                    Delivered: 'default',
                    Bounced: 'primary',
                  }[row.status] || 'warning'}
                  sizes="md"
                  text={row.status}
                />
              </TableCell>
      
         
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        totalItems={totalItems}
        itemsPerPage={perPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </Container>
  )

};

export default EmailsTable;
