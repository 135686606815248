import React, { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Button, Container, LoadingSpinner } from '../../../components/ui';
import { ArrowBackIcon } from '../../../assets/icons';
import ProductForm from '../../../components/forms/ProductForm';
import { useGetProduct } from '../../../hooks/useProducts';

const EditProductPage = () => {

  // TODO: Implement EditProductPage

  const params = useParams();

 const {data,isError,isLoading } = useGetProduct(params.id);


 const productData = useMemo(() => {

  if (!data || !data?.data?.product) return null;

  console.log(data?.data?.product);

  const productData = data?.data?.product;

  const productRefactoredData = {
    name  : productData.name,
    description : productData.description,
    pricePerBottle : productData.price,
    bottlesInStock : productData.stock,
    brand : productData.brand,
    sku : productData.sku,
    productType : productData.product_type_id,
    appellation : productData.appellation,
    id : productData.id,
    vintage : productData.year,
    size: productData.size_unit !== "L" ? parseInt(productData.bottle_size).toString() : parseFloat(productData.bottle_size) * 1000,
    taxExempt : productData.apply_taxes === 0 ? false : true,
    productImage : productData.logo,
    productUrl : productData.logo,
    status : productData.status === 0 ? 'archived' : 'available',


  }

  return productRefactoredData;
  }, [data]);
 

 if (isLoading) {
  return <div>
        <Container className="max-sm:px-1 mt-4 " size="lg">
        <LoadingSpinner />
        </Container>
  </div>
 }

  if (isError || !productData) {
    return <div>
      Could not find product
    </div>
  }



  
  return (
    <Container className="max-sm:px-2 px-4 mt-4 " size="lg">
      <div>
        <Link to="/products">
          <Button aschild variant="ghost" size="sm">
            <ArrowBackIcon className="text-black hover:text-primary" />
          </Button>
        </Link>
        <h1 className="font-bold text-xl ">Edit Product</h1>
      </div>
      {/* AddProductForm */}
      <ProductForm initialProductData={productData} isEditForm />
    </Container>
  )
}

export default EditProductPage
