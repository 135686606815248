import React, { useMemo } from 'react'
import {  useParams } from 'react-router-dom';
import { Container, LoadingSpinner } from '../../../components/ui';
import EditClubForm from './EditClub';
import { useGetClub } from '../../../hooks/useClubs';

const EditClubPage = () => {
  const { id } = useParams();


  const {data ,isError,isLoading} = useGetClub(id);

  const editClubData = useMemo(() => {
    if (data && data.data && data.data.club) {

      const club = data.data.club;
      if (!club || !club.id) {
        return null;
      }
      const formattedData = {
        id: club.id,
        clubName: club.name,
        description: club.description,
        maxMembers: club.membership_limit,
        limitMembers: club.membership_limit > 1 ? true : false,
        clubType: club.club_type,
        billingPreference: club.billing_preference || "",
        totalBottles: club.quantity,
        pricePerShipment: club.shipment_price || "",
        shipmentOption: club.shipment_option || "",
        taxRate: club.tax_rate || "",
        tastingRoomDiscount: club.tasting_room_discount || 0,
        selectedShippingOptions: club.shipping_options || [],
        membershipPerks: club.membership_perks || "",
        boxWeight: club.weight || "",
        boxHeight: club.height || "",
        boxLength: club.length || "",
        boxWidth: club.width || "",
        clubPicture: club.logo || "",
        clubFeeSettings: {
          feeTitle: club.fee_title || "",
          feeDescription: club.fee_description || "",
          clubFee: club.fee || 0,
          applyTax: club.apply_tax || false,
        },
        promotionClubSettings: {
          promotionTitle: club.promotion_title || "",
          promotionPrice: club.promotion_price || 0.00,
        },
        welcomePack: {
          maxWines: club.max_wines || 5,
          selectedWines: club.selected_wines || [],
        },
        allowPricingVisibility: club.prices_visibility===1 ? true : false,
        billTotalAmount  : club.bill_checkout_total_amount===1 ? true : false,
        allowMoreBottles : club.allow_extra_products===1 ? true : false,
        notifyAdmin : club.notify_admin_on_selection===1 ? true : false,
        clubVisible: club.club_visibility===1 ? true : false,
        markAsPrivate: club.is_private===1 ? true : false,
        shippingType: club.shipping_type || "",
        isShippable: club.is_shippable || "",
        taxApplication: club.tax_application || "",
        
      }

      return formattedData;
      


 
    }
    return null;
  }
  ,[data])

  if (isLoading) {
    return <Container>
      <LoadingSpinner />
    </Container>
  }

  if (isError) {
    return <Container>
      <div>Something Went Wrong</div>
    </Container>
  }


  return (
    <Container size="md">

     <EditClubForm id={id} initialClubData={editClubData}  />
    </Container>
  )
}

export default EditClubPage
