import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import {
  HomeIcon,
  MembershipIcon,
  ClubManagementIcon,
  InventoryIcon,
  ReleasesIcon,
  EmailCampaignsIcon,
  EmailHistoryIcon,
  ReportsIcon,
  IntegrationsIcon,
  SettingsIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  AccountCircleIcon,
} from "../../assets/icons";
import logoMaster from "../../assets/images/wineclub_logo.png";
import useAuthStore from "../../store/authStore";
import useAppStore from "../../store/appStore";
import useModals from "../../hooks/useModals";

function Sidebar() {
  const { user } = useAuthStore();
  const location = useLocation();
  const { closeModal, isModalOpen } = useModals();
  const isMobile = useAppStore((state) => state.isMobile);
  const [isManagementExpanded, setIsManagementExpanded] = useState(false);

  useEffect(() => {
    let timeout;
  
    if (isMobile) {
      timeout = setTimeout(() => closeModal("menu"), 200); // Adjust timeout as needed
    }
  
    return () => clearTimeout(timeout); // Cleanup timeout on component unmount
  }, [location.pathname, isMobile, closeModal]);

  useEffect(() => {
    if (isMobile && isModalOpen('menu')) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isMobile, isModalOpen]);


  const toggleManagement = () => {
    setIsManagementExpanded(!isManagementExpanded);
  };

  const handleSidebarClose = () => {
    if (isMobile) {
      closeModal('menu');
    }
  };

  const sidebarContent = (
    <>
      <div className="flex justify-between items-center mb-4">
        <div className="text-2xl font-bold text-secondary-grey">
          <img src={logoMaster} alt="Wine Club Logo" className="w-[100px] h-[100px] object-contain" />
        </div>
        {isMobile && (
          <button onClick={handleSidebarClose} className="text-dark hover:text-primary">
            <IoMdClose size={27} />
          </button>
        )}
      </div>

      <nav className="flex flex-col space-y-2">
        <NavLink
          to="/"
          end
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey lg:hover:bg-primary lg:hover:text-white"
            }`
          }
        >
          <HomeIcon className="h-5 w-5 mr-3" />
          Home
        </NavLink>

        <NavLink
          to="/club-management/clubs"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey lg:hover:bg-primary lg:hover:text-white"
            }`
          }
        >
          <ClubManagementIcon className="h-5 w-5 mr-3" />
          Club Management
        </NavLink>

        <NavLink
          to="/memberships"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey lg:hover:bg-primary lg:hover:text-white"
            }`
          }
        >
          <MembershipIcon className="h-5 w-5 mr-3" />
          Membership
        </NavLink>

        <NavLink
          to="/products"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey lg:hover:bg-primary lg:hover:text-white"
            }`
          }
        >
          <InventoryIcon className="h-5 w-5 mr-3" />
          Products
        </NavLink>

        <NavLink
          to="/releases"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey lg:hover:bg-primary lg:hover:text-white"
            }`
          }
        >
          <ReleasesIcon className="h-5 w-5 mr-3" />
          Releases
        </NavLink>

        <NavLink
          to="/send-email"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey lg:hover:bg-primary lg:hover:text-white"
            }`
          }
        >
          <EmailCampaignsIcon className="h-5 w-5 mr-3" />
          Email Campaigns
        </NavLink>

        <NavLink
          to="/email-history"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey lg:hover:bg-primary lg:hover:text-white"
            }`
          }
        >
          <EmailHistoryIcon className="h-5 w-5 mr-3" />
          Email History
        </NavLink>

        <NavLink
          to="/reports"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey lg:hover:bg-primary lg:hover:text-white"
            }`
          }
        >
          <ReportsIcon className="h-5 w-5 mr-3" />
          Reports
        </NavLink>

        <NavLink
          to="/integrations"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey lg:hover:bg-primary lg:hover:text-white"
            }`
          }
        >
          <IntegrationsIcon className="h-5 w-5 mr-3" />
          Integrations
        </NavLink>

        <NavLink
          to="/account"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
              ? "bg-primary text-white"
              : "text-secondary-grey lg:hover:bg-primary lg:hover:text-white"
            }`
          }
        >
          <AccountCircleIcon className=" mr-3" />
          Account
        </NavLink>
        

        <NavLink
          to="/settings"
          className={({ isActive }) =>
            `p-3 flex items-center rounded-lg ${
              isActive
                ? "bg-primary text-white"
                : "text-secondary-grey lg:hover:bg-primary lg:hover:text-white"
            }`
          }
        >
          <SettingsIcon className="h-5 w-5 mr-3" />
          Settings
        </NavLink>

        {(user?.isAdmin || user?.id === 1) && (
          <div>
            <button
              onClick={toggleManagement}
              className={`p-3 flex items-center rounded-lg w-full ${
                location.pathname.includes('/admin')
                  ? "bg-primary text-white"
                  : "text-secondary-grey lg:hover:bg-primary lg:hover:text-white"
              }`}
            >
              Admin Management
              {isManagementExpanded ? (
                <ChevronUpIcon className="h-4 w-4 ml-auto" />
              ) : (
                <ChevronDownIcon className="h-4 w-4 ml-auto" />
              )}
            </button>
            {isManagementExpanded && (
              <div className="ml-4 mt-2 space-y-2">
                <NavLink
                  to="/admin/tenants"
                  className={({ isActive }) =>
                    `p-2 flex items-center rounded-lg ${
                      isActive
                        ? "bg-primary text-white"
                        : "text-secondary-grey lg:hover:bg-primary lg:hover:text-white"
                    }`
                  }
                >
                  Tenants
                </NavLink>
              </div>
            )}
          </div>
        )}
      </nav>
    </>
  );

 if (isMobile) {
    return (
      <>
        {isModalOpen('menu') && (
          <div
            className="fixed inset-0  overflow-hidden bg-dark/60 z-40 backdrop-blur-[2px]" 
            onClick={handleSidebarClose}
          ></div>
        )}
        <div
          className={`fixed w-full   max-w-[280px] inset-y-0 left-0 z-50 bg-blue-bg transform ${
            isModalOpen('menu') ? 'translate-x-0' : '-translate-x-full'
          } transition-transform duration-300 ease-in-out`}
        >
          <div className="h-full overflow-y-auto p-4 hide-scrollbar">
            {sidebarContent}
          </div>
        </div>
      </>
    );
  }

  return (
    <aside className="w-64 h-full bg-blue-bg flex flex-col p-4">
      {sidebarContent}
    </aside>
  );
}

export default Sidebar;

