import React from "react";
import { Button, Container, } from "../../components/ui";
import { Link } from "react-router-dom";
import { TiPlus } from "react-icons/ti";

import Header from "../../components/ui/Header";
import { pageTitles } from "../../utils/data/dummyData";
import ProductsPageContainer from "./ProductsPageContainer";

const ProductsPage = () => {
 

  return (
    <Container  size="full">
      <div className="flex px-5 justify-between flex-wrap gap-3 items-center">
             <Header>{pageTitles.productsPage}</Header>
             <Link to="/products/add-product">
               <Button aschild variant="default">
                 <TiPlus /> Add Product
               </Button>
             </Link>
           </div>
           <ProductsPageContainer />
    </Container>
  );
};

export default ProductsPage;

