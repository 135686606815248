import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Input } from "../../../components/ui";
import { useLoginMutation } from "../../../hooks/useLoginMutation";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for password visibility


  const { mutate, isPending, error    } = useLoginMutation();


  const handleSubmit = (e) => {
    e.preventDefault();
    
    mutate(
      { email, password },
    );
  };



  return (
    <div className='p-0 lg:p-[40px] w-full items-center flex justify-center'>
      <form onSubmit={handleSubmit} className=' p-[20px] lg:p-[40px] w-[100%]'>
        <div>
          <h2 className='text-[35px] lg:text-[45px] font-bold'>Let's Sign In!</h2>
          <p className='text-small mb-6 text-new-secondary-gray'>
            Welcome back, please fill your credentials
          </p>
        </div>

        <div className='mb-4'>
          <label className='block text-base2 font-medium text-new-secondary-gray'>
            Email
          </label>
          <Input
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='Enter your email'
            required
            autoComplete='email'
            id='email'
            name='email'

          />
        </div>

        <div className='mb-4'>
          <label className='block text-base2 font-medium text-new-secondary-gray'>
            Password
          </label>
          <div className='relative'>
            <Input
              type={showPassword ? "text" : "password"} // Toggle password visibility
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder='Enter Password'
              autoComplete='current-password'
              className='w-full p-2 border rounded mt-1'
              id='password'
              name='password'
            />
            <button
              type='button'
              onClick={() => setShowPassword(!showPassword)} // Toggle visibility
              className='absolute inset-y-0 right-3 flex items-center text-gray-500'
            >
              {showPassword ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='currentColor'
                  className='w-5 h-5 text-new-secondary-gray'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3.98 8.218c1.708-2.516 4.445-4.218 8.02-4.218 3.574 0 6.312 1.703 8.02 4.218a11.994 11.994 0 010 7.564c-1.708 2.516-4.445 4.218-8.02 4.218-3.574 0-6.312-1.703-8.02-4.218a11.994 11.994 0 010-7.564z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                  />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='currentColor'
                  className='w-5 h-5 text-new-secondary-gray'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M3 3l18 18M10.477 10.477a2.5 2.5 0 003.046 3.046M9.879 9.879A3 3 0 0112 6c3.222 0 6 1.5 8 4a11.994 11.994 0 01-1.871 1.865M16.121 16.121A3 3 0 0112 18c-3.222 0-6-1.5-8-4a11.994 11.994 0 01.748-.953'
                  />
                </svg>
              )}
            </button>
          </div>
          <div className='flex justify-end'>
            <Link
              to='/forgot-password'
              className='text-base2 font-medium text-dark hover:underline mt-2'
            >
              Forgot Password?
            </Link>
          </div>
        </div>

        {error && (
          <p className='text-red text-sm mb-4'>
            {error.message || "Login failed. Please try again."}
          </p>
        )}

        <Button
          type='submit'
          disabled={isPending}
          className={`w-full p-2 rounded ${
            isPending ? "bg-new-secondary-gray" : "bg-blue text-white"
          }`}
        >
          {isPending ? "Logging in..." : "Login"}
        </Button>
      </form>
    </div>
  );
};

export default LoginPage;
