import React from "react";
import { withFormik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Checkbox, Input, Label, Modal, TextArea } from "../../../ui";

const validationSchema = Yup.object().shape({
  feeTitle: Yup.string().required('Fee Title is required'),
  feeDescription: Yup.string().required('Fee Description is required'),
  clubFee: Yup.number().required('Club Fee is required').min(0, 'Club Fee must be greater than 0'),
  applyTax: Yup.boolean(),
});

const FeeModal = ({
  isOpen,
  onClose,
  title,
  footer,
  disabled,
  actionLabel,
  secondaryAction,
  secondaryActionLabel,
  values,
  initialValues,
  handleSubmit,
  isSubmitting,
  isValid,
  isPending,
}) => {

  const body = (
    <Form className='flex flex-col gap-2'>
      <div className='flex justify-between flex-col w-full items-start gap-4'>
        <div className='w-full flex flex-col items-start gap-2'>
          <Label htmlFor='feeTitle'>Fee Title *</Label>
          <Field
            name='feeTitle'
            as={Input}
            type='text'
            placeholder='Enter Fee Title'
          />
          <ErrorMessage
            name='feeTitle'
            component='div'
            className='error-message text-red max-sm:text-small'
          />
        </div>
        <div className='w-full flex flex-col items-start gap-2'>
          <Label htmlFor='feeDescription'>Fee Description *</Label>
          <Field
            name='feeDescription'
            as={TextArea}
            type='text'
            placeholder='Write description here'
          />
          <ErrorMessage
            name='feeDescription'
            component='div'
            className='error-message text-red max-sm:text-small'
          />
        </div>
        <div className='w-full flex flex-col items-start gap-2'>
          <Label htmlFor='clubFee'>Club Fee</Label>
          <div className="relative w-full">
          <span className="absolute
          top-1/2
          transform -translate-y-1/2
          left-2
          text-base2

          ">$</span>
          <Field
            name='clubFee'
            as={Input}
            type='number'
            placeholder=' Enter Club Fee'
          />
          </div>
         
          <ErrorMessage
            name='clubFee'
            component='div'
            className='error-message text-red max-sm:text-small'
          />
        </div>
        <div className='w-full flex  items-center gap-2'>
          <Field
            name='applyTax'
            as={Checkbox}
            type='checkbox'
          />
          <Label htmlFor='applyTax'>Apply Tax</Label>

        
        </div>
        
      </div>
    </Form>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      body={body}
      disabled={isSubmitting}
      actionLabel={isPending ? 'Saving...' : actionLabel}
      secondaryAction={secondaryAction}
      secondaryActionLabel={secondaryActionLabel}
      onSubmit={handleSubmit}
      disableSubmit={!isValid || isSubmitting}
    />
  );
};

const SaveFeeModal = withFormik({
  validateOnMount: true,
  enableReinitialize: true,
  validateOnChange: true, // Validate on change
  validateOnBlur: true, // Validate on blur
  mapPropsToValues: (props) => ({
    feeTitle: props.initialValues?.feeTitle || "",
    feeDescription: props.initialValues?.feeDescription || "",
    clubFee: props.initialValues?.clubFee || "",
    applyTax: props.initialValues?.applyTax || false,
  }),
  validationSchema,
  handleSubmit:  (values, { props, setSubmitting, resetForm }) => {
      props.onSave(values);
      props.onClose();
      resetForm();
      setSubmitting(false);

 
  },
})(FeeModal);

const SaveFeeModalWrapper = (props) => {


  return <SaveFeeModal {...props} />;
};

export default SaveFeeModalWrapper;

