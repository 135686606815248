import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../api/queryKeys';
import { productsData } from '../utils/data/products';
import { archiveProduct, createProduct, deleteProduct, getAllProducts, getProduct, getProductTypes, restoreProduct, updateProduct } from '../api/product/productQueries';
import { useNavigate } from 'react-router-dom';
import useNotification from './useNotification';

export const useGetProductsDummy = () => {


  const { data , isLoading , isError } = useQuery({
    queryKey: ["productsDummy"],
    refetchOnWindowFocus: false,
    //cache 10 minutes
    staleTime:  600000, // Cache for 10 minutes
    cacheTime: 600000, // Keep in cache for 5 minutes
    initialData: productsData,
  });

  return { data , isLoading , isError };
}


export const useGetProducts = () => {


  const { data , isLoading , isError } = useQuery({
    queryKey: queryKeys.PRODUCTS,
    queryFn:getAllProducts,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: 0, // Always fetch fresh data
    cacheTime: 0, // Do not cache

  });

  return { data , isLoading , isError };
}




export const useGetProductTypes = () => {

  const { data , isLoading , isError } = useQuery({
    queryKey: queryKeys.PRODUCT_TYPES,
    queryFn: getProductTypes,
    refetchOnWindowFocus: false,
    //cache 10 minutes
    staleTime:  600000, // Cache for 10 minutes
    cacheTime: 600000, // Keep in cache for 5 minutes
  });

  return { data , isLoading , isError };
}



export const useGetProduct = (productId) => {
  
  const { data , isLoading , isError, isSuccess } = useQuery({
    queryKey: [queryKeys.PRODUCT, productId],
    queryFn: () => getProduct(productId),
    refetchOnWindowFocus: false,
    staleTime: 600000, // Cache for 10 minutes
    cacheTime: 600000, // Keep in cache for 10 minutes

  });

  return { data , isLoading , isError ,isSuccess };
}


export const useCreateProduct = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {STATUSES, showToast} = useNotification();

  const createProductMutation = useMutation({
    mutationFn: createProduct,
    onSuccess: (newProduct) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.PRODUCTS,
      });

      queryClient.removeQueries(queryKeys.PRODUCTS);

   

      showToast("Product created successfully",STATUSES.ACTION , "Success");
      navigate("/products");
    },
    onError: (error) => {
      showToast(
        error.message || "Failed to create product, Please try again ",
        STATUSES.ERROR,
      );
    },
  });

  return  createProductMutation 
}

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {STATUSES, showToast} = useNotification();

  return useMutation({
    mutationFn: async ({id,productData}) => {
    
      await updateProduct(id, productData);
    },
    onMutate: async (productData) => {
      await queryClient.cancelQueries(queryKeys.PRODUCTS);

      const previousProducts = queryClient.getQueryData(queryKeys.PRODUCTS);

      return { previousProducts };
    },
    onSuccess: (response,{id}) => {

      queryClient.invalidateQueries(
        {
          queryKey: queryKeys.PRODUCTS
        }
      );
      queryClient.removeQueries({
        queryKey: queryKeys.PRODUCTS,
      });

      queryClient.removeQueries({
        queryKey: [queryKeys.PRODUCT, `${id}`],
      });


      showToast("Product updated successfully", STATUSES.ACTION, "Success");
      navigate("/products");
    },
    onError: (error) => {
      showToast(
        error.message || "Failed to update product, Please try again ",
        STATUSES.ERROR,
      );
    },
  });
}



export const useActionProduct = () => {
  const { STATUSES, showToast } = useNotification();
  const queryClient = useQueryClient();

  const actionMap = {
    delete: deleteProduct,
    archive: archiveProduct,
    unarchive: restoreProduct,
  };

  return useMutation({
    mutationFn: async ({ productId, actionType }) => {
      const action = actionMap[actionType];
      if (!action) {
        throw new Error(`Invalid action type: ${actionType}`);
      }
      await action(productId);
    },
    onMutate: async ({ productId, actionType }) => {
      await queryClient.cancelQueries(queryKeys.PRODUCTS);
      const previousProducts = queryClient.getQueryData(queryKeys.PRODUCTS);
      return { previousProducts };
    },
    onSuccess: (response, { productId, actionType }) => {
      queryClient.invalidateQueries(queryKeys.PRODUCTS);
      const successMessages = {
        delete: "Product deleted successfully",
        archive: "Product archived successfully",
        unarchive: "Product restored successfully",
      };
      showToast(successMessages[actionType], STATUSES.ACTION, "Success");
    },
    onError: (error, variables, context) => {
      showToast(
        error.message || "Failed to perform action, Please try again",
        STATUSES.ERROR
      );
    },
  });
};



