import React from 'react';

const Tabs = ({ tabs, activeTab, onTabChange }) => {

  const handleTabChange = (value) => {
    const url = new URL(window.location);
    url.searchParams.set('tab', value);
    window.history.pushState({}, '', url);
    onTabChange(value);
  };

  return (
    <div className="relative flex border-b justify-start select-none overflow-x-auto max-w-[90vw] lg:max-w-full ">
      {tabs.map((tab) => (
        <span
          key={tab.label}
          onClick={() => handleTabChange(tab.value)}
          className={`relative text-small lg:text-base2 font-medium  md:cursor-pointer p-4 text-center w-fit min-w-[180px] ${
            activeTab === tab.value ? 'text-primary' : 'text-[#666666]'
          } `} // Add responsive class for smaller text on mobile
        >
          {tab.label}
          {activeTab === tab.value && (
            <div className="absolute bottom-[0px] left-0 right-0 h-[2px] bg-primary"></div>
          )}
        </span>
      ))}
    </div>
  );
};

export default Tabs;