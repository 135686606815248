
export const DummyEmailHistoryData = [
  {
    id: 1,
    date: "Fri, 27 June 2024",
    members: 16,
    status: "Delivered",
    subject:"New Offers",
    sender: "manager@wineclubsite.com",
    reciver:"jane.cooper@example.com"
  },
  {
    id: 2,
    date: "Fri, 27 June 2024",
    members: 4,
    status: "Delivered",
    subject:"New Offers",
    sender: "manager@wineclubsite.com",
    reciver:"jane.cooper@example.com"
  },
  {
    id: 3,
    date: "Fri, 27 June 2024",
    members: 4,
    status: "Bounced",
    subject:"New Offers",
    sender: "manager@wineclubsite.com",
    reciver:"jane.cooper@example.com"
  },
  {
    id: 4,
    date: "Fri, 27 June 2024",
    members: 10,
    status: "Bounced",
    subject:"New Offers",
    sender: "manager@wineclubsite.com",
    reciver:"jane.cooper@example.com"
    
  },
  {
    id: 5,
    date: "Fri, 27 June 2024",
    members: 14,
    status: "Delivered",
    subject:"New Offers",
    sender: "manager@wineclubsite.com",
    reciver:"jane.cooper@example.com"
  },
  {
    id: 6,
    date: "Fri, 27 June 2024",
    members: 4,
    status: "Delivered",
    subject:"New Offers",
    sender: "manager@wineclubsite.com",
    reciver:"jane.cooper@example.com"
  },
  {
    id: 7,
    date: "Fri, 27 June 2024",
    members: 4,
    status: "Bounced",
    subject:"New Offers",
    sender: "manager@wineclubsite.com",
    reciver:"jane.cooper@example.com"
  },
  {
    id: 8,
    date: "Fri, 27 June 2024",
    members: 40,
    status: "Draft",
    subject:"New Offers",
    sender: "manager@wineclubsite.com",
    reciver:"jane.cooper@example.com"
  }
]