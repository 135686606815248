import React from "react";
import { withFormik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Checkbox, Label } from "../../../components/ui";
import dummyUsersAccounts from "../../../utils/data/users";

const NotificationsForm = ({
  values,
  errors,
  touched,
  isSubmitting,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  return (
    <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
        
      <h6 className="text-lg font-normal">New Members Notification List</h6>
      <p className="text-base text-secondary-grey">Choose which users get a notification email when a new member joins a club:</p>

      <div className="flex flex-col gap-2">
        <Label htmlFor="usersSelected" className="text-base font-medium">Users</Label>
        <div className="space-y-2 max-h-64 overflow-y-auto border border-gray-200 rounded-md p-2">
          {dummyUsersAccounts.map((user) => (
            <div key={user.id} className="flex items-center">
              <Field
                as={Checkbox}
                id={`user-${user.id}`}
                name="usersSelected"
                value={user.id.toString()}
                checked={values.usersSelected.includes(user.id.toString()) }
                className="mr-2"
              />
              <Label htmlFor={`user-${user.id}`} className="text-sm cursor-pointer">
                {user.name} ({user.email})
              </Label>
            </div>
          ))}
        </div>
        <ErrorMessage name="usersSelected" component="div" className="text-red text-sm" />
      </div>

      <div className="w-full max-w-52 self-end">
        <Button type="submit" disabled={isSubmitting} className="mt-2 w-full">
          {isSubmitting ? "Saving..." : "Save List"}
        </Button>
      </div>
    </Form>
  );
};

const SetupNotificationsForm = withFormik({
    enableReinitialize:true,
  mapPropsToValues: ({ initialData }) => ({
    usersSelected: initialData.map((user) => user.id.toString()),
  }),
  validationSchema: Yup.object().shape({
    usersSelected: Yup.array().min(1, "Select at least one user"),
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    console.log("Submitted values:", values);
    // Simulate API call
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 1000);
  },
})(NotificationsForm);

export default SetupNotificationsForm;

