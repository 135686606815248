import React from "react";
import {Container} from "../../../../components/ui";
import RoleForm from "../RoleForm";
import Header from "../../../../components/ui/Header";

const AddRolePage = () => {
  return (
    <Container type="flex" className="px-2 py-4 " size="lg">
      <Header>Add Role</Header>
      <RoleForm />
    </Container>
  );
};

export default AddRolePage;
