import React from 'react';
import { Outlet } from 'react-router-dom';
import { queryKeys } from '../api/queryKeys';
import { getAllProducts } from '../api/product/productQueries';
import { useQueryClient } from '@tanstack/react-query';

const ProductsLayout = () => {

  const queryClient = useQueryClient();

   queryClient.prefetchQuery({
        queryKey: queryKeys.PRODUCTS,
        queryFn: getAllProducts,
      }); 

  return  (
    <Outlet  />
  )
  
};

export default ProductsLayout;