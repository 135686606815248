import React, { useCallback, useMemo, useRef } from "react";
import {  Link, useNavigate } from "react-router-dom";

import * as Yup from 'yup';
import { Button, Container } from "../../../components/ui";
import { ArrowBackIcon } from "../../../assets/icons";
import {Formik, Form,} from "formik";
import Step1ClubInfo from "../../../components/forms/addClubForm/Step1ClubInfo";
import Step2ClubConfiguration from "../../../components/forms/addClubForm/Step2ClubConfig";
import Step3ClubShipping from "../../../components/forms/addClubForm/Step3ClubShipping";
import WelcomePackClubWrapper from "../../../components/forms/addClubForm/modals/WelcomePackModal";
import SaveFeeModalWrapper from "../../../components/forms/addClubForm/modals/ClubFeeModal";
import useModals from "../../../hooks/useModals";
import PromotionModalClubWrapper from "../../../components/forms/addClubForm/modals/PromotionClubModal";
import { ScrollToError } from "../../../components/ScrollToError";
import { useUpdateClub } from "../../../hooks/useClubs";


const initialValuesEditClub = {
    clubName: "",
    description: "",
    maxMembers: 1,
    limitMembers:false,
    clubType: "",
    billingPreference: "",
    allowPricingVisibility: false,
    totalBottles: 1,
    pricePerShipment: "",
    shipmentOption: "",
    taxRate: "",
    tastingRoomDiscount: 0,
    selectedShippingOptions: [], // Array of objects with selected options and their prices
    membershipPerks: "",
    boxWeight: "",
    boxHeight: "",
    boxLength: "",
    boxWidth: "",
    clubPicture: "", // Image file,
    clubFeeSettings: {
      feeTitle: "",
      feeDescription: "",
      clubFee: 0,
      applyTax: false,
    },
    promotionClubSettings: {
      promotionTitle: "",
      promotionPrice: 0.00,
    },
    welcomePack: {
      maxWines: 5,
      selectedWines: [],
    },
    shippingType:  "",
    isShippable:  "",
    taxApplication: "",
  };

// Merge all validations into one schema
const clubValidationSchema = Yup.object().shape({
    clubName: Yup.string().required("Club name is required"),
    description: Yup.string().required("Description is required"),
    maxMembers: Yup.number()
        .required("Max number of members is required")
        .min(1, "Must be at least 1 member"),
    clubType: Yup.string().required("Please select a wine club type"),
    billingPreference: Yup.string().required("Please choose a billing option"),
    totalBottles: Yup.number().required("Number of bottles is required").min(1, "At least 1 bottle is required"),
    pricePerShipment: Yup.number().required("Price per shipment/month is required"),
    shipmentOption: Yup.string().required("Please select a shipment option"),
    taxRate: Yup.number().when("applyTaxAtCheckout", {
        is: true,
        then: () => Yup.number().required("Tax rate is required").min(0, "Tax rate cannot be negative"),
      }),
    taxApplication: Yup.string().when("applyTaxAtCheckout", {
        is: true,
        then: () => Yup.string().required("Please choose how tax will apply"),
      }),
    tastingRoomDiscount: Yup.number().min(0, "Discount cannot be negative").max(100, "Discount cannot exceed 100%"),
    isShippable: Yup.string().required("Please choose an option"),
    shippingType: Yup.string().required("Please select a shipping type"),
    boxWidth: Yup.number().required("Width is required"),
    boxLength: Yup.number().required("Length is required"),
    boxHeight: Yup.number().required("Height is required"),
    boxWeight: Yup.number().required("Weight is required"),
    clubPicture: Yup.mixed().required("Please upload a club image"),
});

function EditClubForm({initialClubData}) {
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const {closeModal,isModalOpen} =  useModals();
  const updateClub = useUpdateClub();

     const initialValuesData = useMemo(() => {
        return initialClubData ? initialClubData : initialValuesEditClub;
      }, [initialClubData]);


  const onSaveClubFee = useCallback((values) => {
    if (values) {
      formikRef.current.setFieldValue("clubFeeSettings", values) ;
    }
  }
  ,[formikRef])

  const onSavePromotion =useCallback((values) => {
    if (values) {
      formikRef.current.setFieldValue("promotionClubSettings", values);
    }
  }
  ,[formikRef])

  const onSaveWelcomePack = useCallback((values) => {
    if (values) {
      formikRef.current.setFieldValue("welcomePack", values);
    }
  }
  ,[formikRef])



  const handleSubmit = async (values, { setSubmitting }) => {

    try {
      await updateClub.mutateAsync({
        clubId: initialClubData.id,
        clubData: values
      });
    } catch (error) {
      console.error("Error updating member:", error);
    }
    
    setSubmitting(false)
  }

  if (!initialClubData || !initialClubData.id) {
    return (
      <Container className="flex justify-center items-center h-full">
        Something went wrong
      </Container>
    );
  }



  return (
    <div className="max-w-[784px] w-full mx-auto flex flex-col justify-center gap-6 max-sm:px-4 p-3">
      <div>
        <Link to="/club-management/clubs">
          <Button aschild variant="ghost" size="sm">
            <ArrowBackIcon className="text-black hover:text-primary" />
          </Button>
        </Link>
        <h1 className="font-semibold text-base text-new-secondary-gray pt-2">Edit Club: {initialClubData.clubName}</h1>
      </div>

      <Formik
        innerRef={formikRef}
        initialValues={initialValuesData}
        validationSchema={clubValidationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({ isValid }) => (
          <Form className="flex flex-col gap-8">
            <ScrollToError />
            {/* Club Info Fields */}
           <Step1ClubInfo />
           <Step2ClubConfiguration />
           <Step3ClubShipping  clubImage={initialValuesData.clubPicture}/>


             <div className="flex justify-end gap-3 text-base2 font-medium">
                        
                            <Button
                              variant="outline"
                              type="button"
                              onClick={() => {
                                navigate("/club-management/clubs");
                              }}
                              className="btn"
                            >
                              Cancel
                            </Button>
                          <Button
                            variant="default"
                            type="submit"
                            disabled={ updateClub.isPending }                          >
                            {updateClub.isPending ? "Updating..." : "Update Club"}
                          </Button>
                        </div>

          </Form>
        )}
      </Formik>



      <SaveFeeModalWrapper
        isOpen={isModalOpen("clubFeeModal")}
        onClose={() => closeModal("clubFeeModal")}
        title="Club Fee Settings"
        actionLabel="Save Fee"
        secondaryAction={() => closeModal("clubFeeModal")}
        secondaryActionLabel="Cancel"
        onSave={onSaveClubFee}
        initialValues={formikRef.current?.values.clubFeeSettings}
      />

      <PromotionModalClubWrapper
        isOpen={isModalOpen("promotionClubModal")}
        onClose={() => closeModal("promotionClubModal")}
        title="Promotion Settings"
        actionLabel="Save Promotion"
        secondaryAction={() => closeModal("promotionClubModal")}
        secondaryActionLabel="Cancel"
        onSave={onSavePromotion}
        initialValues={formikRef.current?.values.promotionClubSettings}
      />

      <WelcomePackClubWrapper
        isOpen={isModalOpen("welcomePackModal")}
        onClose={() => closeModal("welcomePackModal")}
        title="Welcome Pack  Options"
        actionLabel="Save Pack Options"
        secondaryAction={() => closeModal("welcomePackModal")}
        secondaryActionLabel="Cancel"
        onSave={onSaveWelcomePack}
        initialValues={formikRef.current?.values.welcomePack}
      />


    </div>
  );
}

export default EditClubForm;




