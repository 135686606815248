import axiosInstance from "../axiosInstance";

export const createRole = async (role) => {
  try {
    const response = await axiosInstance.post('/roles', role);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

// export const useCreateRole = () => {
//   return useMutation(createRole, {
//     onSuccess: () => {
//       queryClient.invalidateQueries(queryKeys.ROLES);
//     },
//   });
// }

