import React, { useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { Input, Label, Select, Checkbox, Iterator, TextArea, Button, Container } from "../../ui";
import * as Yup from "yup";
import { EditIcon, UploadIcon } from "../../../assets/icons";
import useModals from "../../../hooks/useModals";

const Step3ClubShipping = ({clubImage}) => {




  const { values, setFieldValue } = useFormikContext();
  const [selectedFile, setSelectedFile] = useState( clubImage ? clubImage : null);
  const {openModal} = useModals()


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(URL.createObjectURL(file));
      setFieldValue('clubPicture', file);
    }
    event.target.value = null;
  };


  const shippingOptions = [
    "Manual",  
    "UPS Ground",
    "UPS 3 Day Select",
    "UPS 2nd Day Air",
    "UPS Next Day Air Early AM",
    "UPS Next Day Air",
  ];

  const handleCheckboxChange = (option) => {
    const selectedIndex = values.selectedShippingOptions.findIndex(item => item.name === option);
  
    if (selectedIndex > -1) {
      // Option is already selected, remove it
      const updatedOptions = values.selectedShippingOptions.filter(item => item.name !== option);
      setFieldValue("selectedShippingOptions", updatedOptions);
    } else {
      // Option is not selected, add it with a default empty price
      const updatedOptions = [
        ...values.selectedShippingOptions,
        { name: option, price: "" }
      ];
      setFieldValue("selectedShippingOptions", updatedOptions);
    }
  };

  return (
    <div className="flex flex-col  justify-start gap-6" >
      <h2 className="text-h6 font-bold  text-dark ">Club Membership Perks</h2>

      {/* Tasting Room Discount */}
      <div className='flex flex-col items-start justify-between  gap-2 w-fit'>
        <Label htmlFor='tastingRoomDiscount'>Tasting Room Discount</Label>
        <div className="flex items-center  h-full">
        <Iterator
          name='tastingRoomDiscount'
          id='tastingRoomDiscount'
          value={`${values.tastingRoomDiscount}`}
          handleIncrement={() => setFieldValue('tastingRoomDiscount', values.tastingRoomDiscount + 1)}
          handleDecrement={() =>  values.tastingRoomDiscount>0 &&  setFieldValue('tastingRoomDiscount', values.tastingRoomDiscount - 1)}
          className=""
          onChange={(e) => setFieldValue("tastingRoomDiscount", Number(e.target.value))}
          showPercentage={true}
        />
        </div>
      
      </div>

      {/* Other Perks */}
      <div className="">
        <Label htmlFor="membershipPerks">Write Any Other Perks Included </Label>
        <Field
          name="membershipPerks"
          as={TextArea}
          placeholder="Write perks here"
          className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
        />
        <ErrorMessage name="membershipPerks" component="div" className="error-message text-red max-sm:text-small" />
      </div>

      {/* Shipping Options */}
     <Container  className="gap-6" >
      <h2 className="text-h6 font-bold text-dark mt-2 pt-6 border-t">Shipping</h2>
      <div className="flex flex-col md:flex-row   justify-start items-stretch gap-6">
        <div className="flex-1 flex flex-col justify-start items-stretch ">
          <Label htmlFor="isShippable">Is this Club Shippable or Pickup Only *</Label>
          <Field
            name="isShippable"
            as={Select}
            className="block w-full mt-2 p-2   border border-gray-300 rounded-md"
          >
            <option value="">Choose an option</option>
            <option value="shippable">Shippable</option>
            <option value="pickupOnly">Pickup Only</option>
          </Field>
          <ErrorMessage name="isShippable" component="div" className="error-message text-red max-sm:text-small" />
        </div>

        <div className="flex-1   flex flex-col justify-start items-stretch ">
          <Label className="max-sm:min-h-6" htmlFor="shippingType">Select a Shipping Type *</Label>
          <Field
            name="shippingType"
            as={Select}
            className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
          >
            <option value="">Choose a shipping type</option>
            <option value="ground">Ground</option>
            <option value="air">Air</option>
          </Field>
          <ErrorMessage name="shippingType" component="div" className="error-message text-red max-sm:text-small" />
        </div>
      </div>

      <Container className="gap-6 justify-start p-4 bg-[#FCFCFC] border border-gray-300 rounded-md " >
 
      <div className="grid grid-cols-1 gap-4  max-md:overflow-x-auto ">
      <div className="text-body font-bold gap-6 text-dark inline-flex w-full justify-start  ">
          <h2 className="flex-1 pr-4 min-w-[200px]">Shipping Options</h2> <h2 className="flex-1 px-2">Price</h2>
        </div>
        {shippingOptions.map((option) => {
          const isChecked = values.selectedShippingOptions.some(item => item.name === option);
          return (
            <div key={option} className="flex items-center gap-6 ">
              <div className="flex items-center  text-dark flex-1 gap-4 p-4 border rounded-md min-w-[200px]  h-[56px]">
              <Checkbox
                type="checkbox"
                as={Checkbox}
                name={`option_${option}`}
                checked={isChecked}
                onChange={() => handleCheckboxChange(option)}
              />
              <span  className="text-dark text-base font-medium">{option}</span>
              </div>
              <Field
                name={`price_${option}`}
                as="input"
                type="number"
                placeholder=" $     Enter Price"
                value={
                  values.selectedShippingOptions.find(item => item.name === option)?.price || ""
                }
                onChange={(e) => {
                  const updatedOptions = values.selectedShippingOptions.map(item =>
                    item.name === option ? { ...item, price: e.target.value } : item
                  );
                  setFieldValue("selectedShippingOptions", updatedOptions);
                }}
                disabled={!isChecked} // Disable input if checkbox is not checked
                className="block p-2 border border-gray-300 rounded-md flex-1 h-full placeholder:text-base2 placeholder:font-normal"
              />
              <ErrorMessage name={`price_${option}`} component="div" className="error-message text-red max-sm:text-small" />
            </div>
          );
        })}
      </div>
        </Container>
      

     </Container>
      {/* Additional Options */}
      <div className="space-y-4 ">
        <div className="flex items-center">
          <Field name="applyFreeShipping" type="checkbox" as={Checkbox} />
          <Label className="ml-2">Apply Free Shipping to 7 Day Shipments</Label>
        </div>

        <div className="flex items-center">
          <Field name="applyShippingToState" type="checkbox" as={Checkbox} />
          <Label className="ml-2">
            Apply free shipping to members in your state/province (SC)?
          </Label>
        </div>
      </div>

      {/* Shipping Box Dimensions */}
      <h2 className="text-base2 font-medium">Shipping Box Dimensions</h2>
      <div className="flex gap-6 flex-wrap max-md:justify-center justify-between max-md:w-full">
        <div className="max-md:w-full">
          <Label htmlFor="boxWidth">Width *</Label>
          <Field
            name="boxWidth"
            as={Input}
            type="number"
            placeholder="--              Inches"
            className="block w-full  md:max-w-[160px] p-2 mt-2 border border-gray-300 rounded-md"
          />
          <ErrorMessage name="boxWidth" component="div" className="error-message text-red max-sm:text-small" />
        </div>

        <div className="max-md:w-full">
          <Label htmlFor="boxLength">Length *</Label>
          <Field
            name="boxLength"
            as={Input}
            type="number"
            placeholder="--              Inches"
            className="block w-full md:max-w-[160px] p-2 mt-2 border border-gray-300 rounded-md"
          />
          <ErrorMessage name="boxLength" component="div" className="error-message text-red max-sm:text-small" />
        </div>

        <div className="max-md:w-full">
          <Label htmlFor="boxHeight">Height *</Label>
          <Field
            name="boxHeight"
            as={Input}
            type="number"
            placeholder="--              Inches"
            className="block w-full md:max-w-[160px] p-2 mt-2 border border-gray-300 rounded-md"
          />
          <ErrorMessage name="boxHeight" component="div" className="error-message text-red max-sm:text-small" />
        </div>

        <div className="max-md:w-full">
          <Label htmlFor="boxWeight">Weight *</Label>
          <Field
            name="boxWeight"
            as={Input}
            type="number"
            placeholder="--               Pounds"
            className="block w-full md:max-w-[160px] p-2 mt-2 border border-gray-300 rounded-md"
          />
          <ErrorMessage name="boxWeight" component="div" className="error-message text-red max-sm:text-small" />
        </div>
      </div>

      {/* Club Picture Upload */}
      <div className="mb-6">
      <Label htmlFor="clubPicture">Club Picture</Label>
      <div className="mt-2 border border-dashed border-gray-300 p-4 rounded-md text-center">
        {selectedFile ? (
          <div className="flex flex-col items-center">
            <img src={selectedFile} alt="Selected" className="max-w-[200px] h-auto rounded-md" />
            <Button
            variant="secondary"
              onClick={() => {
                setSelectedFile(null);
                setFieldValue('clubPicture', '');
              }}
              className="mt-2 text-red-500 hover:text-red-700"
            >
              Remove
            </Button>
          </div>
        ) : (
          <Label
            htmlFor="clubPicture"
            className="inline-flex justify-center w-full p-2 font-normal text-base2 rounded-md cursor-pointer gap-3"
          >
            <UploadIcon /> Drag & Drop or click here to upload club picture
          </Label>
        )}
        <Input
          type="file"
          id="clubPicture"
          name="clubPicture"
          accept="image/*"
          className="hidden"
          onChange={handleFileChange}
        />
      </div>
      <ErrorMessage name="clubPicture" component="div" className="error-message text-red max-sm:text-small" />
    </div>

      {/* Additional Checkboxes */}
      <div className="space-y-4 border-b pb-4 ">
        <div className="flex items-center">
          <Field name="markAsPrivate" type="checkbox" as={Checkbox} />
          <Label className="ml-2">
           Mark this club as private (It won’t be visible from the front-end clubs page)
          </Label>
        </div>

        <div className="flex items-center">
          <Field name="clubVisible" type="checkbox" as={Checkbox} />
          <Label className="ml-2">
            Club Visible (If unchecked, then no one can sign up for this club)
          </Label>
        </div>
      </div>

      <div className="space-y-4  ">
        <div className="flex items-start ">
          <Field name="activateWelcomePacks" type="checkbox" as={Checkbox} />
          <Label className="ml-2  w-full">
            <div className="flex justify-between items-center gap-2 w-full">
            <div className="flex flex-col gap-2">
              <span className="font-semibold"> Activate Welcome Packs </span>
              <span className=" text-secondary-grey font-normal">2017 Estate Chardonnay. Wine Tasting 2018 Estate Cabernet Franc </span>
              
            </div>
            <div className="min-w-[80px] min-h-[40px] "> 

             {values.activateWelcomePacks && (
              <Button
             
              onClick={()=>openModal('welcomePackModal')}
             aschild variant="ghost" className="text-base2 font-medium">
              
             <span className="text-blue hover:text-primary/70 inline-flex items-center">
             <EditIcon className="h-4 w-4 mr-2" />
             Edit
             </span>
              </Button>
             )}
             </div>
            </div>
          </Label>
        </div>

        <div className="flex items-start">
          <Field name="activatePromotion" type="checkbox" as={Checkbox} />
          <Label className="ml-2  w-full">
          <div className="flex justify-between items-center gap-2 w-full">

            <div className="flex flex-col gap-2">
              <span className="font-semibold"> Activate a Promotion for this clubs </span>
              <span className=" text-secondary-grey font-normal">Promotion Title, Free first month for new member </span>
              
            </div>
            <div className="min-w-[80px] min-h-[40px] "> 

           {values.activatePromotion && (
            <Button
             onClick={()=>openModal('promotionClubModal')}
             aschild variant="ghost" className="text-base2 font-medium">
               <span className="text-blue hover:text-primary/70 inline-flex items-center">
                 <EditIcon className="h-4 w-4 mr-2" />
                 Edit
                 </span>
             </Button>
           )}
            </div>

            </div>

          </Label>
        </div>
        <div className="flex items-start">
          <Field name="activatClubFee" type="checkbox" as={Checkbox} />
          <Label className="ml-2 w-full">
          <div className="flex justify-between items-center gap-2 w-full">
            <div className="flex flex-col gap-2 ">
              <span className="font-semibold"> Activate a Club Activation Fee/Welcome Fee  (Allows a fee to be billed at the tme of signup) </span>
              <span className=" text-secondary-grey font-normal">Club Fee Settings</span>
            </div>

            <div className="min-w-[80px] min-h-[40px] "> 

            {values.activatClubFee && (
              <Button 
            
              onClick={()=>openModal('clubFeeModal')}
              aschild variant="ghost" className="text-base2 font-medium">
                  <span className="text-blue hover:text-primary/70 inline-flex items-center">
                  <EditIcon className="h-4 w-4 mr-2" />
                  Edit
                  </span>
              </Button>
            )}
            
            </div>
            </div>

          </Label>
        </div>
      </div>
    </div>
  );
};

export const step3Validation = Yup.object().shape({
  isShippable: Yup.string().required("Please choose an option"),
  shippingType: Yup.string().required("Please select a shipping type"),
  boxWidth: Yup.number().required("Width is required"),
  boxLength: Yup.number().required("Length is required"),
  boxHeight: Yup.number().required("Height is required"),
  boxWeight: Yup.number().required("Weight is required"),
  clubPicture: Yup.mixed().required("Please upload a club picture"),
});

export default Step3ClubShipping;
