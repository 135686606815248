import React from "react";
import {Navigate} from "react-router-dom";
import useAuthStore from "../store/authStore";

export default function AdminRoute({children, isAdmin}) {
  const {isAuthenticated} = useAuthStore();



  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return isAdmin ? children : <Navigate to="/" />;
}
