import React, { useCallback, useMemo, useState } from "react";
import {  Container, LoadingSpinner, Tabs } from "../../components/ui";

import { useActionProduct, useGetProducts } from "../../hooks/useProducts";
import ProductListContainer from "./ProductListContainer";
import ProductActionModal from "./ProductActionModal";
import useModals from "../../hooks/useModals";

import { usePagination } from "../../components/pagination/usePagination";

const ProductsPageContainer = () => {
  const [showArchiveProducts, setShowArchiveProducts] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [actionType, setActionType] = useState(null);
  const { isModalOpen, closeModal, openModal } = useModals();

  const {data:productsData,isError,isLoading} = useGetProducts()
  const productActionMutation = useActionProduct();

  const tabs = useMemo(() => [
    { label: "All Products", value: false },
    { label: "Archived Products", value: true },
  ], []);

  const handleTabChange = useCallback((value) => {
    setShowArchiveProducts(value);
  }, []);

  const handleModalClose = useCallback(() => {
    closeModal("productActionModal");
    setSelectedProduct(null);
    setActionType(null);
  }, [closeModal]);

  const handleProductAction = useCallback(
    (product, action) => {
      setSelectedProduct(product);
      setActionType(action);
      openModal("productActionModal");
    },
    [openModal]
  );

  const handleModalSubmit = useCallback(async () => {
    if (actionType && selectedProduct) {
      try {
        await productActionMutation.mutateAsync({ productId: selectedProduct.id, actionType });
        handleModalClose();
      } catch (error) {
        console.error(`Error ${actionType} user:`, error);
      }
    }
  }, [actionType, selectedProduct, productActionMutation, handleModalClose]);

  const filteredProducts = useMemo(() => {
    if (!productsData || !productsData.data || !productsData.data[0] || !productsData.data[0].length) {
      return [];
    }

    const products = productsData.data[0];

    //filter and then sort by status 1 to 0
    const filteredProducts = products.filter((product) => {
      if (showArchiveProducts) {
        return product.status === 0;
      }
      return true;
    })

    if (!filteredProducts.length) {
      return [];
    }
    const sortedProducts = filteredProducts.sort((a, b) => {
      if (a.status === 1 && b.status === 0) {
        return -1;
      }
      if (a.status === 0 && b.status === 1) {
        return 1;
      }
      return 0;
    });

    return sortedProducts;



  }, [productsData, showArchiveProducts]);

  const {
    currentItems,
    currentPage,
    totalPages,
    totalItems,
    perPage,
    handlePageChange,
    handleItemsPerPageChange
  } = usePagination(filteredProducts, 8);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <div className="mt-6 text-center text-red">Something Went Wrong</div>
    );
  }

  return (
    <Container size="full">
     

      <Tabs
        tabs={tabs}
        activeTab={showArchiveProducts}
        onTabChange={handleTabChange}
      />

      <Container type="flex"  size="full">
        <ProductListContainer
          onDelete={(product) => handleProductAction(product, "delete")}
          onArchive={(product) => handleProductAction(product, "archive")}
          onUnarchive={(product) => handleProductAction(product, "unarchive")}
          currentItems={currentItems}
          currentPage={currentPage}
          totalPages={totalPages}
          totalItems={totalItems}
          perPage={perPage}
          handlePageChange={handlePageChange}
          handleItemsPerPageChange={handleItemsPerPageChange}
          emptyMessage={`No ${showArchiveProducts ? "archived" : ""} products available`}
          getStartedMessage={`${
            showArchiveProducts
              ? "Archive a product to get started or add a new product"
              : "Get started by creating a new product"
          }`}
        />

        <ProductActionModal
          isOpen={isModalOpen("productActionModal")}
          onClose={handleModalClose}
          title={actionType ? `${actionType.charAt(0).toUpperCase() + actionType.slice(1)} Product` : ""}
          actionLabel={actionType ? actionType.charAt(0).toUpperCase() + actionType.slice(1) : ""}
          selectedProduct={selectedProduct}
          secondaryAction={handleModalClose}
          secondaryActionLabel="Cancel"
          onSubmit={handleModalSubmit}
          actionType={actionType}
          isPending={productActionMutation.isPending}
        />
      </Container>
    </Container>
  );
};

export default ProductsPageContainer;

