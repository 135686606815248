import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/navbar";
import Sidebar from "../components/sidebar";
import Footer from "../components/footer";
import { usePrefetchData } from "../hooks/usePrefetchData";
import useAppStore from "../store/appStore";
import MobileNavbar from "../components/navbar/MobileNavbar";

function AppLayout() {


  usePrefetchData();  

  const isMobile = useAppStore((state) => state.isMobile);




  return (
    <div className='flex h-screen'>
      {/* Sidebar */}
      {/* <Sidebar /> */}

      <Sidebar />

      {/* Main Content Area */}
      <div className='flex-1 flex flex-col'>
        {/* Navbar */}

        {!isMobile ? <Navbar /> : <MobileNavbar />}

        {/* Outlet for nested routes */}
        <main className='px-0 pt-4 pb-0 flex-1 overflow-y-auto bg-white flex justify-between flex-col gap-5 '>
          <Outlet />
          <Footer />
        </main>

        {/* Footer */}
      </div>
      
    </div>
  );
}

export default AppLayout;
