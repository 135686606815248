import * as React from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useCreateRole, useUpdateRole } from '../../../hooks/settings/useRoles'
import { useGetAllPermissions } from '../../../api/settings/settingsQueries'
import { Button, Checkbox, Input, Label, LoadingSpinner, TextArea } from '../../../components/ui'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  permissions: Yup.array().min(1, 'At least one permission is required'),
})

const emptyStateValues = {
  name: '',
  description: '',
  permissions: []
}

const RoleForm = ({initialRoleValues}) => {

  const initialValues = useMemo(() => {

    if (initialRoleValues) {
      return {
        id: initialRoleValues.id,
        name: initialRoleValues.name,
        description: initialRoleValues.description || '',
        permissions: initialRoleValues.permissions?.map((permission) => permission.name) || []
      }
    }
    return emptyStateValues

  }, [initialRoleValues])

  const {mutate ,isPending} =useCreateRole()
  const {mutate: updateRole, isPending: isUpdatePending} = useUpdateRole()

  // Handle form submission and update
  const handleSubmit = (values, { setSubmitting }) => {

    const formatData = (data) => ({
      name: data.name,
      permissions: data.permissions,
      ...(data.id && { id: data.id })
    })

    const formattedData = formatData(values)

    switch (true) {
      case !!initialRoleValues:
        updateRole(formattedData)
        break;
      default:
        mutate(formattedData)
        
    }

    setSubmitting(false)

   
  }

  const { data, isLoading, isError } = useGetAllPermissions()

  const groupedPermissions = useMemo(() => {
    if (!data) {
      return {}
    }

    return data.data.permissions.reduce((acc, permission) => {
      if (!acc[permission.resource]) {
        acc[permission.resource] = []
      }
      acc[permission.resource].push({
        name: permission.name,
        id: permission.id
      })
      return acc
    }, {})
  }, [data])

  if (isLoading) {
    return <LoadingSpinner />
  }
  
  if (isError) {
    return <p>No permissions found, try again</p>
  }
  

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount={true}
    >
      {({ errors, touched, isSubmitting, isValid }) => (
        <Form className="space-y-8">
          <div className="space-y-4">
            <div>
              <Label htmlFor="name">
                Name <span className="text-red">*</span>
              </Label>
              <Field
                as={Input}
                type="text"
                id="name"
                name="name"
                className={errors.name && touched.name ? 'outline-red' : ''}
              />
              {errors.name && touched.name && (
                <p className="text-sm text-red mt-1">{errors.name}</p>
              )}
            </div>

            <div>
              <Label htmlFor="description">
                Description
              </Label>
              <Field
                as={TextArea}
                id="description"
                name="description"
              />
            </div>
          </div>

          <div className="">
            <h2 className="text-lg font-semibold pt-2 border-t border-border">Permissions</h2>
            
            <div className='flex gap-5 justify-start flex-wrap'>
              {Object.entries(groupedPermissions).map(([resource, permissions]) => (
                <div key={resource} className='flex-1'>
                  <div className="pt-6">
                    <h3 className="text-base font-medium capitalize mb-4">{resource}</h3>
                    <div className="flex flex-col min-w-[220px] items-start gap-4">
                      {permissions.map((permission) => (
                        <div key={permission.id} className="flex w-full items-center space-x-2">
                          <Field
                            type="checkbox"
                            name="permissions"
                            value={permission.name}
                            as={Checkbox}
                            id={permission.id}
                          />
                          <Label
                            htmlFor={permission.id}
                            className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                          >
                            {permission.name}
                          </Label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Permissions Error */}
            {errors.permissions && touched.permissions && (
              <p className="text-sm text-red mt-4">{errors.permissions}</p>
            )}
          </div>

          <div className="flex justify-end space-x-4 py-5">
            <Link to="/account?tab=roles">
            <Button type="button" variant="outline" aschild >
              Cancel
            </Button>
            </Link>
            <Button 
              type="submit" 
              disabled={isSubmitting || !isValid || (initialRoleValues ? isUpdatePending : isPending)}
            >
              {initialRoleValues
                ? (isSubmitting || isUpdatePending) ? 'Updating...' : 'Update Role'
                : (isSubmitting || isPending) ? 'Creating...' : 'Create Role'
              }
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default RoleForm

