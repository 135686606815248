import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import OnlinePlatform from "../assets/images/Online-Platform.png";
import Logo from "../assets/images/wineclub_logo.png";
import TextCarousel from "../components/carousels";
import useAuthStore from "../store/authStore";
import useAppStore from "../store/appStore";

const AuthLayout = () => {
  const { isAuthenticated } = useAuthStore();
  const isMobile = useAppStore((state) => state.isMobile);


  if (isAuthenticated) {
    return <Navigate to='/' />;
  }

  return (
    <div className='flex min-h-screen h-full items-stretch justify-between max-w-[2000px] mx-auto'>
      {/* Left section - Hidden on small screens */}
      <div className='hidden lg:flex flex-1 bg-blue-bg flex-col items-center justify-start'>
        <div className='flex h-32 items-center pl-14 pb-5 w-full'>
          <div className='w-[180px] h-[180px]'>
            <img
              className='w-full h-full object-contain'
              src={Logo}
              alt='Logo'
            />
          </div>
        </div>

        <div className='flex flex-col flex-1 justify-center'>
          <div className='flex justify-center select-none'>
            <img
              className='w-full max-w-[480px] h-auto'
              src={OnlinePlatform}
              alt='online platform'
            />
          </div>

          { !isMobile && <TextCarousel /> }

         
        </div>
      </div>

      {/* Outlet Section - Full width on small screens */}
      <div className='flex-1 my-auto p-0 lg:p-[40px] w-full'>
      <div className='w-[120px] h-[120px] mx-auto lg:hidden'>
            <img
              className='w-full h-full object-contain'
              src={Logo}
              alt='Logo'
            />
          </div>
        <Outlet />
          { isMobile && <TextCarousel /> }
      </div>
    </div>
  );
};

export default AuthLayout;
