
export const queryKeys = {
  USER : ['getUser'],
  TENANT : ['getTenant'],
  STATES : ['states'],
  TERMS : ['terms'],
  ROLES : ['roles'],
  PERMISSIONS : ['permissions'],
  USERS : ['users'],
  CLUBS : ['clubs'],
  PRODUCTS : ['products'],
  PRODUCT_TYPES : ['productTypes'],
  PRODUCT : ['product'],
  MEMBERS : ['members'],
  MEMBER : ['member'],
  
}