import { Link, useNavigate } from "react-router-dom";
import ChevronRightIcon from "../../assets/icons/ChevronRightIcon";
import Pagination from "../../components/pagination/Pagination";
import { usePagination } from "../../components/pagination/usePagination";
import {  Button, Container, Tag } from "../../components/ui";
import Table from "../../components/ui/table/Table";
import TableBody from "../../components/ui/table/TableBody";
import TableCell from "../../components/ui/table/TableCell";
import TableHeader from "../../components/ui/table/TableHeader";
import TableRow from "../../components/ui/table/TableRow";
import add_ad from '../../assets/images/add_ad.png'
import { TiPlus } from "react-icons/ti";
import { formatDate } from "../../utils/formatters";

const MembershipTable = ({members}) => {
  const columns = ['Member', 'Status', 'Card No.', 'Member No.', 'Member Since', 'Expires', 'Club Membership'];


const navigate = useNavigate();

const actions = [
  {
    icon: <ChevronRightIcon width="12" height="16" />,
    onClick: (row) => navigate(`/memberships/${row.id}/edit`),
    tooltip: 'View details',
  },
];


  const {
    currentItems,
    currentPage,
    totalPages,
    totalItems,
    perPage,
    handlePageChange,
    handleItemsPerPageChange
  } = usePagination(members);
  

  if (!currentItems || currentItems.length === 0) {
    return (
      <Container className="mt-6 text-center h-[60vh] justify-center items-center" size='xl'>
        <div className='flex flex-col items-center  max-w-[901px] w-full justify-center space-y-4 p-10 border rounded-lg'>  
          <img src={add_ad} alt='add_ad' className='w-[48px] h-[48px]' />
          <h1 className='text-h1 font-semibold text-dark text-lg'>
            No Memberships found
          </h1>
          <p className='text-base2 font-normal text-secondary-grey'>
            Add a new member to get started
          </p>
          <Link to="add-member">
            <Button 
              size='sm'
              className={`w-full px-5`}
              aschild 
              variant="default">
              <TiPlus /> Add Member
            </Button>
          </Link>
        </div>
      </Container>
    )
  }



  return (
    <Container>
      <Table>
        <TableHeader columns={columns} hasActions />
        <TableBody>
          {currentItems.map((row, index) => (
            <TableRow key={index} 
              onClick={() => navigate(`/memberships/${row.id}/edit`)}
            >
              {/* Member */}
              <TableCell>
                <div className="flex flex-col">
                  <span className="text-base2 font-medium">{row.name}</span>
                  <span className="text-small font-medium text-secondary-grey">{row.email}</span>
                </div>
              </TableCell>
              <TableCell>
                <Tag
                  variant={{
                    Active: 'default',
                    Expired: 'primary',
                    'On Hold': 'secondary',
                  }[row.status] || 'primary'}
                  sizes="md"
                  text={row.status || 'On Hold'}
                />
              </TableCell>
              <TableCell>{row.card || "No card available"}</TableCell>
              <TableCell>{row.membership_number}</TableCell>
              <TableCell>{formatDate(row.membership_start_date , false)}</TableCell>
              <TableCell>{formatDate(row.membership_start_date , false)}</TableCell>
              <TableCell>{row.club || "No club membership available"}</TableCell>

              {/* Actions */}
              <TableCell className="flex justify-end  h-full">
                   <div className="flex justify-end items-center  w-full">
                   {actions.map((action, actionIndex) => (
                     <Button
                       key={actionIndex}
                       variant="ghost"
                       className=" text-center flex justify-center"
                       onClick={() => action.onClick(row)}
                       title={action.tooltip}
                       aschild
                     >
                      {action.icon}
                     </Button>
                   ))}
                 </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        totalItems={totalItems}
        itemsPerPage={perPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </Container>
  )

};

export default MembershipTable;
