import * as React from "react";

const AccountCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    fill="currentColor"
    viewBox="0 0 20 21"
    {...props}

  >
    <path
      d="M3.875 13.224a8.7 8.7 0 0 1 2.375-1.282A8.1 8.1 0 0 1 9 11.473q1.437 0 2.75.47 1.312.468 2.375 1.28a6.4 6.4 0 0 0 1.135-1.937q.406-1.083.406-2.313 0-2.77-1.947-4.718t-4.72-1.948-4.718 1.948-1.948 4.718q0 1.23.406 2.313t1.136 1.938M9 9.807q-1.23 0-2.073-.844-.844-.843-.844-2.073t.844-2.073T9 3.973t2.073.844.843 2.073q0 1.23-.843 2.073-.844.844-2.073.844m0 7.5a8.1 8.1 0 0 1-3.25-.656 8.4 8.4 0 0 1-2.646-1.782 8.4 8.4 0 0 1-1.781-2.645 8.1 8.1 0 0 1-.656-3.25q0-1.73.656-3.25a8.4 8.4 0 0 1 1.781-2.646A8.4 8.4 0 0 1 5.75 1.296 8.1 8.1 0 0 1 9 .64q1.73 0 3.25.656a8.4 8.4 0 0 1 2.646 1.782 8.4 8.4 0 0 1 1.78 2.645 8.1 8.1 0 0 1 .657 3.25 8.1 8.1 0 0 1-.656 3.25 8.4 8.4 0 0 1-1.781 2.646 8.4 8.4 0 0 1-2.646 1.782 8.1 8.1 0 0 1-3.25.656m0-1.667a6.6 6.6 0 0 0 2.083-.323 6.3 6.3 0 0 0 1.792-.927 6.3 6.3 0 0 0-1.792-.927A6.6 6.6 0 0 0 9 13.14a6.6 6.6 0 0 0-2.083.323 6.3 6.3 0 0 0-1.792.927 6.3 6.3 0 0 0 1.792.927A6.6 6.6 0 0 0 9 15.64m0-7.5q.542 0 .896-.354t.354-.896-.354-.896A1.21 1.21 0 0 0 9 5.64q-.542 0-.896.354a1.21 1.21 0 0 0-.354.896q0 .542.354.896T9 8.14"
      fill="currentColor"
      ></path>
  </svg>
);

export default AccountCircleIcon;
