import React, {memo, useState, useCallback} from "react";
import useClickOutside from "../../../hooks/useOnClickOutside";
import WineIcon2 from "../../../assets/icons/WineIcon2";
import {Button, Tag} from "../../ui";
import EditIcon2 from "../../../assets/icons/EditIcon2";
import {EyeIcon, EyeIcon2, IoIosMore, TrashIcon} from "../../../assets/icons";
import { Link } from "react-router-dom";
import Image from "../../ui/Image";

const ProductCard = memo(({product , onDelete , onArchive , onUnarchive}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const closeDropdown = useCallback(() => {
    setShowDropdown(false);
  }, []);

  const dropdownRef = useClickOutside(closeDropdown);

  const toggleDropdown = useCallback((e) => {
    e.stopPropagation();
    setShowDropdown((prev) => !prev);
  }, []);

  const getDropdownPosition = () => {
    const dropdownElement = dropdownRef.current;
    if (!dropdownElement) return {};

    const rect = dropdownElement.getBoundingClientRect();
    const isOverflowingRight = rect.right + 130 > window.innerWidth;

    return isOverflowingRight ? { right: 'auto', left: '-105px' } : { right: '-105px', left: 'auto' };
  };

  const handleRestore = useCallback(
    (e) => {
      e.preventDefault();
      onUnarchive(product);
    },
    [onUnarchive, product],
  );

  const handleArchive = useCallback(
    (e) => {
      e.preventDefault();
      onArchive(product);
    },
    [onArchive, product],
  );

  const handleDelete = useCallback(
    (e) => {
      e.preventDefault();
      onDelete(product);
    },
    [onDelete, product],
  );

  return (
    <div className=" relative w-full transition-shadow duration-200 max-w-[320px]">
      {/* Product Content */}
      <div className={`flex flex-col items-start p-1.5`}>
        {/* Image */}
        <Link to={`${product.id}/edit`} 
          className={`
          w-full relative  h-[105px] sm:h-[150px] lg:h-auto lg:aspect-video max-h-[170px]  rounded-md overflow-hidden
          ${product.status === 0 ? "opacity-50" : ""}
          `}
        >
          <Image
            src={product.logo}  //TODO product.logo
            alt={product.name}
          />
          <Tag
            text={product.status !== 0 ? "Available" : "Unavailable"}
            variant={product.status !== 0 ? "default" : "primary"}
            sizes="md"
            className="absolute top-2.5 right-2"
          />
        </Link>

        {/* Product Info*/}
        <div className="flex flex-col items-start text-center p-3.5 w-full">
          <div className="w-full flex flex-col items-start ">
            <Link 
              to={`${product.id}/edit`}
            className="flex items-center justify-between w-full">
              <span className=" font-medium text-small lg:text-lg truncate text-dark">
                {product.name}
              </span>
              <span className="font-semibold  text-base2 lg:text-h6 text-dark ">
                ${product.price}
              </span>
            </Link>
            <span className="text-sm text-[#8D8D8D] font-normal">
              {product.size_unit !== "L" ?  parseInt(product.bottle_size) : parseFloat(product.bottle_size)}{product.size_unit}   
            </span>
          </div>

          <div className="w-full flex items-center flex-wrap gap-1 lg:gap-0 justify-between relative">
            <span className=" pt-3.5 text-[#8D8D8D] flex items-center flex-wrap space-x-2 lg:space-x-2 text-base">
              <WineIcon2 />{" "}
              <span className="text-small  ">{product.product_type}</span>
            </span>
            {/* Dropdown */}
            <div className="absolute top-2 right-0 " ref={dropdownRef}>
              <Button
                className={"bg-[#F5F5F5] max-w-28 px-3 max-h-7"}
                aschild
                variant="ghost"
                size="sm"
                onClick={toggleDropdown}
              >
                <IoIosMore />
              </Button>

              {showDropdown && (
                <div
                  className="
                absolute 
                mt-1 w-[130px] bg-white rounded-md drop-shadow-md shadow-[#0000000A]  z-50 border  border-[#DCDCDC]"
                  style={getDropdownPosition()}
                >
                  <div className=" flex flex-col space-y-1 cursor-pointer ">
                    <Link to={`${product.id}/edit`}>
                    <span
                      className="w-full text-left flex
                      items-center px-2 py-2 text-base2 text-secondary-grey hover:bg-gray-100"
                    >
                      <EditIcon2 className=" mr-1" /> Edit
                    </span>
                    </Link>
                    {product.status === 1 ? (
                      <span
                        onClick={handleArchive}
                        className="w-full text-left flex
                      items-center px-2 py-2 text-base2 text-secondary-grey hover:bg-gray-100"
                      >
                        <EyeIcon2 className="mr-1" />
                        Archive
                      </span>
                    ) : (
                      <span
                        onClick={handleRestore}
                        className="w-full text-left flex
                      items-center px-2 py-2 text-base2 text-secondary-grey hover:bg-gray-100"
                      >
                        <EyeIcon className="mr-1" width="18" height="18" />
                        Unarchive
                      </span>
                    )}
                    <span
                      onClick={handleDelete}
                      className="w-full text-left flex
                      items-center px-2 py-2 text-base2 text-secondary-grey hover:bg-gray-100"
                    >
                      <TrashIcon className="mr-1 " /> Delete
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Dropdown Menu */}
      </div>
    </div>
  );
});

ProductCard.displayName = "ProductCard";

export default ProductCard;
