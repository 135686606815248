import axiosInstance from '../axiosInstance';

export const loginUser = async ({email, password}) => {

  try {
    const response = await axiosInstance.post('/login', {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};


export const logoutUser = async () => {
  try {
    const response = await axiosInstance.post('/logout');
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};



export const forgotPassword = async ({email}) => {
  try {
    const response = await axiosInstance.post('/forgot-password', {
      email,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

export const resetPassword = async ({password, token, email}) => {
  try {
    if (!token) {
      throw new Error('Token is required');
    }
    if (!email) {
      throw new Error('Email is required');
    }

    const response = await axiosInstance.post('/reset-password', {
      password,
      password_confirmation: password,
      token,
      email,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}



export const fakeLogout = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        message: 'Logout successful',
      });
    }, 1000);
  });
}

