import React from "react";
import { withFormik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Input, Label } from "../../../components/ui";
import RichTextEditor from "../../../components/ui/MessageEditor";

const SetupEmailForm = ({
  values,
  errors,
  touched,
  isSubmitting,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  return (
    <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <h6 className="text-lg font-normal">Setup Email</h6>

      <div className="flex flex-col gap-2">
        <Label htmlFor="email">Email</Label>
        <Field
          as={Input}
          type="email"
          id="email"
          name="email"
          placeholder="Enter your email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
        />
        <ErrorMessage name="email" component="div" className="text-red text-sm" />
      </div>

      <div className="flex flex-col gap-2">
        <Label htmlFor="subject">Subject</Label>
        <Field
          as={Input}
          type="text"
          id="subject"
          name="subject"
          placeholder="Enter email subject"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.subject}
        />
        <ErrorMessage name="subject" component="div" className="text-red text-sm" />
      </div>

        <div className="flex flex-col gap-2">
        <Label htmlFor="messageEditor">Message</Label>
        <RichTextEditor
            value={values.messageEditor}
            onChange={(content) => setFieldValue('message', content)}
            />
        <ErrorMessage name="message" component="div" className="text-red text-sm" />
        </div>

      <div className="w-full max-w-52">
      <Button type="submit" disabled={isSubmitting} className="mt-2 w-full">
        {isSubmitting ? "Saving..." : "Save Email"}
      </Button>
      </div>
    </Form>
  );
};

const EnhancedSetupEmailForm = withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    email: initialValues?.email || "",
    subject: initialValues?.subject || "",
  }),
  validationSchema: Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Email is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    console.log("Submitted values:", values);
    // Simulate API call
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
      resetForm();
    }, 1000);
  },
})(SetupEmailForm);

export default EnhancedSetupEmailForm;

