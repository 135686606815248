import React from "react";
import { Tag } from "../../ui";

const MetricCard = ({ icon, title, value, percentage, numbers }) => (
  <div className='p-4 bg-white rounded-[5px] min-w-full max-w-[280px] border flex flex-col items-start min-h-[202px] justify-between '>
    <div className='text-4xl mb-2'>
      <img src={icon} alt='' />
    </div>
    <h4 className='text-[#525252] text-base2 font-[500] mb-2'>{title}</h4>
    {value !== undefined ? (
      <p className='text-2xl text-[18px] lg:text-xl font-bold'>${value}</p>
    ) : (
      <p className='text-2xl text-[18px] lg:text-xl font-bold'>{numbers}</p>
    )}
    <Tag variant="default" text={`${percentage}%`} />
  </div>
);

export default MetricCard;
