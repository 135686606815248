import React from "react";

const TableRow = ({ children , ...props }) => {
  return (
    <tr 
      {...props}
    className="border-t text-dark text-base2 font-medium hover:cursor-pointer hover:bg-slate-50 hover:text-primary">
      {children}
    </tr>
  );
};

export default TableRow;
