

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {clubsData} from '../utils/data/clubs';
import { queryKeys } from '../api/queryKeys';
import { useNavigate } from 'react-router-dom';
import useNotification from './useNotification';
import { createClub, deleteClub, getAllClubs, getClub, updateClub } from '../api/clubs/clubsQueries';

const useClubs = () => {


  const { data , isLoading , isError } = useQuery({
    queryKey: ["duumyClubs"],
    refetchOnWindowFocus: false,
    staleTime: 60000,
    cacheTime: 5 * 60 * 1000, // Keep in cache for 5 minutes
    initialData: clubsData,
  });

  return { data , isLoading , isError };
}

export default useClubs;




export const useGetAllClubs = () => {
  const { data , isLoading , isError } = useQuery({
    queryKey: queryKeys.CLUBS,
    queryFn: getAllClubs,
    refetchOnWindowFocus: false,
    staleTime: 600000,
    cacheTime: 600000, // Keep in cache for 1 minute
});

  return { data , isLoading , isError };

  
}

export const useGetClub = (clubId) => {
  const { data , isLoading , isError, isSuccess } = useQuery({
    queryKey: [queryKeys.CLUBS, clubId],
    queryFn: () => getClub(clubId),
    refetchOnWindowFocus: false,
    staleTime: 30000,
    cacheTime: 60000, 
  });

  return { data , isLoading , isError ,isSuccess };
}


export const useCreateClub = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {STATUSES, showToast} = useNotification();

  const createClubMutation = useMutation({
    mutationFn: createClub,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.CLUBS,
      });

      queryClient.removeQueries({
        queryKey: queryKeys.CLUBS,
      });


      showToast("Club created successfully",STATUSES.ACTION , "Success");
      navigate("/club-management/clubs");
    },
    onError: (error) => {
      showToast(
        error.message || "Failed to create club, Please try again ",
        STATUSES.ERROR,
      );
    },
  });

  return  createClubMutation 
}


export const useUpdateClub = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {STATUSES, showToast} = useNotification();

  return useMutation({
    mutationFn: async ({clubId,clubData}) => {

      if (!clubId) {
        throw new Error("Club ID is required");
      }
      const club_data = {
        name: clubData.clubName,
        description : clubData.description,
        quantity : clubData.totalBottles,
        membership_limit : clubData.maxMembers,
        club_type : clubData.clubType,
        prices_visibility : clubData.allowPricingVisibility ? 1 : 0,
        bill_checkout_total_amount : clubData.billTotalAmount ? 1 : 0,
        allow_extra_products : clubData.allowMoreBottles ? 1 : 0,
        notify_admin_on_selection : clubData.notifyAdmin ? 1 : 0,
        width : clubData.boxWidth,
        length : clubData.boxLength,
        height : clubData.boxHeight,
        weight : clubData.boxWeight,
        club_visibility : clubData.clubVisible ? 1 : 0,
        is_private : clubData.markAsPrivate ? 1 : 0,
      }

      if (typeof clubData.clubPicture !== 'string') { 
        club_data.logo = clubData.clubPicture;
      }


      await updateClub(clubId, club_data);
    },
    onMutate: async () => {
      await queryClient.cancelQueries(queryKeys.CLUBS);

      const previousProducts = queryClient.getQueryData(queryKeys.CLUBS);

      return { previousProducts };
    },
    onSuccess: (response, {clubId}) => {

      queryClient.invalidateQueries({
        queryKey: queryKeys.CLUBS,
      });
      queryClient.removeQueries({
        queryKey: queryKeys.CLUBS,
      });
      queryClient.removeQueries({
        queryKey: [queryKeys.CLUBS, `${clubId}`],
      });

      showToast("Club updated successfully", STATUSES.ACTION, "Success");
      navigate("/club-management/clubs");
    },
    onError: (error) => {
      showToast(
        error.message || "Failed to update club, Please try again ",
        STATUSES.ERROR,
      );
    },
  });
}

export const useDeleteClub = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {STATUSES, showToast} = useNotification();

  return useMutation({
    mutationFn: async (clubId) => {
      await deleteClub(clubId);
    },
    onMutate: async () => {
      await queryClient.cancelQueries(queryKeys.CLUBS);

      const previousProducts = queryClient.getQueryData(queryKeys.CLUBS);

      return { previousProducts };
    },
    onSuccess: (response, {id}) => {
      queryClient.invalidateQueries(queryKeys.CLUBS);

      showToast("Club deleted successfully", STATUSES.ACTION, "Success");
      navigate("/club-management/clubs");
    },
    onError: (error) => {
      showToast(
        error.message || "Failed to delete club, Please try again ",
        STATUSES.ERROR,
      );
    },
  });
}