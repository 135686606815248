import React from 'react'
import { Link, useParams } from 'react-router-dom';
import { useGetRole } from '../../../../hooks/settings/useRoles';
import { Button, Container, LoadingSpinner } from '../../../../components/ui';
import { ArrowBackIcon } from '../../../../assets/icons';
import Header from '../../../../components/ui/Header';
import RoleForm from '../RoleForm';

const EditRolePage = () => {

  const { id } = useParams();

   const {data,isError,isLoading} = useGetRole(id);

   if (isLoading) {
     return  (
      <Container>
      <LoadingSpinner />
     </Container>
     )
   }

    if (isError) {
      return (
        <Container>
          <div>Something went wrong</div>
        </Container>
      )
    }

  return (
    <Container className="max-sm:px-2 px-4 mt-4 " size="lg">
    <div>
      <Link to="/account?tab=roles">
        <Button aschild variant="ghost" size="sm">
          <ArrowBackIcon className="text-black hover:text-primary" />
        </Button>
      </Link>
      <Header >Edit Role</Header>
    </div>

    <RoleForm initialRoleValues={data.data?.role} />

  </Container>
  )
}

export default EditRolePage
