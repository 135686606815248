import React from "react";
import { withFormik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {  Input, Label, Modal } from "../../../ui";

const validationSchema = Yup.object().shape({
  promotionTitle: Yup.string().required('Title is required'),
  //should be  not less than 0
  promotionPrice: Yup.number().required('Promotion Price .is required').min(0, 'Promotion Price must be at least 0'),

});

const PromotionModal = ({
  isOpen,
  onClose,
  title,
  actionLabel,
  secondaryAction,
  secondaryActionLabel,
  handleSubmit,
  isSubmitting,
  isValid,
  isPending,
}) => {

  const body = (
    <Form className='flex flex-col gap-2'>
      <div className='flex justify-between flex-col w-full items-start gap-4'>
        <div className='w-full flex flex-col items-start gap-2'>
          <Label htmlFor='promotionTitle'>Promotion Title *</Label>
          <Field
            name='promotionTitle'
            as={Input}
            type='text'
            placeholder='Enter  Title'
          />
          <ErrorMessage
            name='promotionTitle'
            component='div'
            className='error-message text-red max-sm:text-small'
          />
        </div>
 
        <div className='w-full flex flex-col items-start gap-2'>
          <Label htmlFor='promotionPrice'>Promotion Price</Label>
          <div className="relative w-full">
          <span className="absolute
          top-1/2
          transform -translate-y-1/2
          left-2
          text-base2

          ">$</span>
          <Field
            name='promotionPrice'
            as={Input}
            type='number'
            placeholder=' Enter Price'
          />
          </div>
         
          <ErrorMessage
            name='promotionPrice'
            component='div'
            className='error-message text-red max-sm:text-small'
          />
        </div>

        
      </div>
    </Form>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      body={body}
      disabled={isSubmitting}
      actionLabel={isPending ? 'Saving...' : actionLabel}
      secondaryAction={secondaryAction}
      secondaryActionLabel={secondaryActionLabel}
      onSubmit={handleSubmit}
      disableSubmit={!isValid || isSubmitting}
    />
  );
};

const PromotionClubModal = withFormik({
  validateOnMount: true,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  mapPropsToValues: (props) => ({
    promotionTitle: props.initialValues?.promotionTitle || "",
    promotionPrice: props.initialValues?.promotionPrice || "",
  }),
  validationSchema,
  handleSubmit:  (values, { props, setSubmitting, resetForm }) => {
      props.onSave(values);
      props.onClose();
      resetForm();
      setSubmitting(false);

 
  },
})(PromotionModal);

const PromotionModalClubWrapper = (props) => {



  return <PromotionClubModal {...props} />;
};

export default PromotionModalClubWrapper;

