import { getRoleById, updateRole, useGetAllRoles } from "../../api/settings/settingsQueries";
import { createRole } from "../../api/settings/settingsMutations";
import { queryKeys } from "../../api/queryKeys";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useNotification from "../useNotification";
import { useEffect } from "react";
import useAppStore from "../../store/appStore";
import { useNavigate } from "react-router-dom";

export const useRoles = () => { 
  const { data, isError, isLoading, status } = useGetAllRoles();
  const { setRoles , roles } = useAppStore();

  useEffect(() => {
    if (status === 'success' && data?.data?.roles) {
      setRoles(data.data.roles);
    }
  }, [status, setRoles, data?.data?.roles]);

  return { isError, isLoading, status , roles };
}

export const useCreateRole = () => {
  const { STATUSES, showToast } = useNotification();
  const queryClient = useQueryClient();
  const { addRole } = useAppStore();
  const navigate = useNavigate();

  const createRoleMutation = useMutation({
    mutationFn: createRole,
    onSuccess: (newRole) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.ROLES,
        stale: true,
        exact: true,
        type:'all'
      });
      
      const { permissions, ...role } = newRole.data.role;

      queryClient.setQueryData(queryKeys.ROLES, (oldData) => {
        return {
          ...oldData,
          data: {
            ...oldData.data,
            roles: [...oldData.data.roles, role]
          }
        };
      });
      
      addRole(role);
      showToast('Role created successfully', STATUSES.ACTION);
      navigate('/account?tab=roles');
    },
    onError: () => {
      showToast('Failed to create role, Please try again', STATUSES.ERROR);
    },
  });

  return createRoleMutation;
};

export const useGetRole = (roleId) => {
  const { data, isError, isLoading, status } = useQuery({
    queryKey: [queryKeys.ROLES, roleId],
    queryFn: () => getRoleById(roleId),
    staleTime: 0
  });

  return { data, isError, isLoading, status };
}

export const useUpdateRole = () => {
  const { STATUSES, showToast } = useNotification();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const updateRoleMutation = useMutation({
    mutationFn: (data) => updateRole(data.id, data),
    onSuccess: (updatedRole, { id }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.ROLES,
      });

      // queryClient.invalidateQueries({
      //   queryKey: [queryKeys.ROLES, `${id}`],
      // });

      // Optimistic update for roles
      queryClient.setQueryData(queryKeys.ROLES, (oldData) => {
        if (!oldData) {
          return {
            data: {
              roles: [updatedRole.data.role],
            },
          };
        }
        return {
          ...oldData,
          data: {
            ...oldData.data,
            roles: oldData.data.roles.map((role) => {
              if (role.id === updatedRole.data.role.id) {
                return updatedRole.data.role;
              }
              return role;
            }),
          },
        };
      });


      // Optimistic update for single role
      queryClient.setQueryData([queryKeys.ROLES, `${id}`], (oldData) => {
        if (!oldData) return updatedRole;
        
        return {
          ...updatedRole
        };
      });

      showToast('Role updated successfully', STATUSES.ACTION);
      navigate('/account?tab=roles');
    },
    onError: () => {
      showToast('Failed to update role, Please try again', STATUSES.ERROR);
    },
  });

  return updateRoleMutation;
};