import React from 'react'
import { Container } from '../../components/ui'
import UserProfileForm from './UserProfileForm'
import useAuthStore from '../../store/authStore';

const UserProfilePage = () => {

  const {user} =  useAuthStore()




  if (!user) {
    return  (
    <Container className='mt-10'>
      <h2>
      Something went wrong, please try again
      </h2>
    </Container>
    )
      
  }


  const initialValues = {
    profileImage: '',
    firstName: user.name || "",
    lastName: user.last_name || "",
    email: user.email || "",
    newPassword: "",
    confirmPassword: "",
  };

  return (
    <Container className='mt-10'>

      <UserProfileForm initialValues={initialValues} />
      
    </Container>
  )
}

export default UserProfilePage
