import React from 'react';
import { Button } from '../ui';

const Pagination = ({ 
  currentPage, 
  totalPages, 
  totalItems,
  itemsPerPage,
  onPageChange,
  onItemsPerPageChange  //TODO , Selecting Items per page 8, 16, 24, 32 ...
}) => {
  // Generate page numbers array with ellipsis
  const getPageNumbers = () => {
    const delta = 2;
    const range = [];

    for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
      range.push(i);
    }

    if (currentPage - delta > 2) {
      range.unshift("...");
    }
    if (currentPage + delta < totalPages - 1) {
      range.push("...");
    }

    range.unshift(1);
    if (totalPages > 1) {
      range.push(totalPages);
    }

    return range;
  };

  const pages = getPageNumbers();

  return (
    <div className="flex items-center justify-between px-2 py-3 bg-white flex-wrap gap-2">
      <div className="flex items-center text-sm text-secondary-grey">
        {totalItems > 0 ? (
          <>
            {totalItems === 1 ? (
              <>Showing 1 result</>
            ) : (
              <>
                Showing {Math.min((currentPage - 1) * itemsPerPage + 1, totalItems)} to{' '}
                {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems} results
              </>
            )}
          </>
        ) : (
          'No results found'
        )}
      </div>
      
      <div className="flex items-center gap-2">
        <Button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="p-2 text-gray-600 hover:text-blue-600 disabled:text-secondary-grey"
          size='sm'
          variant='ghost'
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </Button>

        {pages.map((page, index) => (
          <React.Fragment key={index}>
            {page === "..." ? (
              <span className="px-3 py-1 text-sm text-secondary-grey">...</span>
            ) : (
              <Button
                key={page}
                aschild
                variant='ghost'
                onClick={() => onPageChange(page)}
                className={`px-3 py-1 text-sm rounded-md transition-none ${
                  currentPage === page
                    ? 'bg-blue text-white'
                    : 'text-secondary-grey hover:bg-gray-100'
                }`}
              >
                {String(page).padStart(2, '0')}
              </Button>
            )}
          </React.Fragment>
        ))}

        <Button
          onClick={() => onPageChange(currentPage + 1)}
          variant='ghost'
          size='sm'
          disabled={currentPage === totalPages}
          className="p-2 text-gra hover:text-blue-600 disabled:text-secondary-grey"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default Pagination;

