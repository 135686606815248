import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Input, Label } from "../../components/ui";
import { useUpdateProfile } from "../../hooks/settings/useUsers";

const UserProfileForm = ({ initialValues }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

   const {mutateAsync , isPending} =useUpdateProfile()

  useEffect(() => {
    const hasDataChanged = 
      formValues.firstName !== initialValues.firstName ||
      formValues.lastName !== initialValues.lastName ||
      formValues.email !== initialValues.email ||
      formValues.newPassword !== initialValues.newPassword;
    setHasChanges(hasDataChanged);
  }, [formValues, initialValues]);

  useEffect(() => {
    if (isEditing) {
      setFormValues(prevValues => ({
        ...prevValues,
        newPassword: '',
        confirmPassword: ''
      }));
    }
  }, [isEditing]);

  const validationSchema = Yup.object().shape({
    profileImage: Yup.mixed(),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    newPassword: Yup.string(),
    confirmPassword: Yup.string().test('password-match', 'New password and confirm password must match', function(value) {
      return !value || value === this.parent.newPassword;
    })
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setHasChanges(false);
    setFormValues(initialValues);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    // sent in payload only data that are changed
    const payload = {
     
    };

    if (values.firstName !== initialValues.firstName) {
      payload.name = values.firstName;
    }

    if (values.lastName !== initialValues.lastName) {
      payload.last_name = values.lastName;
    }

    if (values.email !== initialValues.email) {
      payload.email = values.email;
    }

    if (values.newPassword && values.newPassword === values.confirmPassword) {
      payload.password = values.newPassword;
      payload.password_confirmation = values.confirmPassword;
    }

    if (payload && Object.keys(payload).length < 1) {
      setSubmitting(false);
      setIsEditing(false);
      setHasChanges(false);
      return;
    }

    await mutateAsync(payload);
    setSubmitting(false);
    setIsEditing(false);
    setHasChanges(false);
    setFormValues(values);
  };

  return (
    <Formik
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue, isSubmitting, isValid }) => (
        <Form className='max-w-screen-md w-full mx-auto p-6 space-y-6 '>
          <div className='flex gap-6 '>
            {/* <div className='w-1/2'>
              <ImageUpload
                value={values.profileImage}
                onChange={(file) => {
                  setFieldValue("profileImage", file);
                  setFormValues({ ...values, profileImage: file });
                }}
                accept='image/png,image/jpeg,image/gif'
                maxSize={10485760}
                className='w-full'
                disabled={!isEditing}
              />
              <ErrorMessage
                name='profileImage'
                component='div'
                className='text-rose-600 text-sm mt-1'
              />
            </div> */}

            <div className='w-full border-b pb-4 flex flex-col gap-3'>
              <div>
                <Label htmlFor='firstName'>First Name</Label>
                <Field
                  name='firstName'
                  type='text'
                  className='mt-1 block w-full rounded-md border px-3 py-2'
                  placeholder='Enter First Name'
                  as={Input}
                  disabled={!isEditing}
                  onChange={(e) => {
                    setFieldValue("firstName", e.target.value);
                    setFormValues({ ...values, firstName: e.target.value });
                  }}
                />
                <ErrorMessage
                  name='firstName'
                  component='div'
                  className='text-rose-600 text-sm mt-1'
                />
              </div>

              <div>
                <Label htmlFor='lastName'>Last Name</Label>
                <Field
                  name='lastName'
                  type='text'
                  className='mt-1 block w-full rounded-md border px-3 py-2'
                  placeholder='Enter Last Name'
                  as={Input}
                  disabled={!isEditing}
                  onChange={(e) => {
                    setFieldValue("lastName", e.target.value);
                    setFormValues({ ...values, lastName: e.target.value });
                  }}
                />
                <ErrorMessage
                  name='lastName'
                  component='div'
                  className='text-rose-600 text-sm mt-1'
                />
              </div>
            </div>
          </div>

          <div>
            <Label htmlFor='email'>Email</Label>
            <Field
              name='email'
              type='email'
              className='mt-1 block w-full rounded-md border px-3 py-2'
              placeholder='Enter Email'
              as={Input}
              disabled={!isEditing}
              onChange={(e) => {
                setFieldValue("email", e.target.value);
                setFormValues({ ...values, email: e.target.value });
              }}
            />
            <ErrorMessage
              name='email'
              component='div'
              className='text-rose-600 text-sm mt-1'
            />
          </div>

          <div>
            <Label htmlFor='newPassword'>New Password</Label>
            <Field
              name='newPassword'
              type='password'
              className='mt-1 block w-full rounded-md border px-3 py-2'
              placeholder='New Password'
              as={Input}
              disabled={!isEditing}
              onChange={(e) => {
                setFieldValue("newPassword", e.target.value);
                setFormValues({ ...values, newPassword: e.target.value });
              }}
            />
            <ErrorMessage
              name='newPassword'
              component='div'
              className='text-rose-600 text-sm mt-1'
            />
          </div>

          <div>
            <Label htmlFor='confirmPassword'>Confirm Password</Label>
            <Field
              name='confirmPassword'
              type='password'
              className='mt-1 block w-full rounded-md border px-3 py-2'
              placeholder='Confirm New Password'
              as={Input}
              disabled={!isEditing}
              onChange={(e) => {
                setFieldValue("confirmPassword", e.target.value);
                setFormValues({ ...values, confirmPassword: e.target.value });
              }}
            />
            <ErrorMessage
              name='confirmPassword'
              component='div'
              className='text-rose-600 text-sm mt-1'
            />
          </div>

          <div className='flex justify-end space-x-4'>
            {!isEditing && (
              <Button type='button'
              size="lg"
              onClick={handleEdit}>
                Edit
              </Button>
            )}
            {isEditing && (
              <>
                <Button type='button'
                variant="outline"
                size="lg"
                onClick={handleCancel}>
                  Cancel
                </Button>
                <Button 
                  type='submit' 
                  size="lg"
                  disabled={
                    isSubmitting || 
                    !isValid || 
                    (!hasChanges && !values.newPassword) || 
                    (values.newPassword !== values.confirmPassword)
                  }
                >
                  {isPending ? "Saving..." : "Save"}
                </Button>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserProfileForm;

