import React, {useState, useEffect, useRef, useCallback} from "react";
import {Formik, Form} from "formik";
import Step1ClubInfo, {step1Validation} from "./Step1ClubInfo";
import Step2ConfigPricing, {step2Validation} from "./Step2ClubConfig";
import Step3ClubShipping, {step3Validation} from "./Step3ClubShipping";
import {Button} from "../../ui";
import {ArrowBackIcon} from "../../../assets/icons";
import {Link, useNavigate} from "react-router-dom";
import ClubSteps from "../../../utils/steps/clubSteps";
import ProgressTracker from "../ProgressTracker";
import SaveFeeModalWrapper from "./modals/ClubFeeModal";
import useModals from "../../../hooks/useModals";
import PromotionClubModalWrapper from "./modals/PromotionClubModal";
import WelcomePackClubWrapper from "./modals/WelcomePackModal";
import { useCreateClub } from "../../../hooks/useClubs";
import { ScrollToError } from "../../ScrollToError";

const steps = [
  {component: Step1ClubInfo, validationSchema: step1Validation},
  {component: Step2ConfigPricing, validationSchema: step2Validation},
  {component: Step3ClubShipping, validationSchema: step3Validation},

];

const initialValues = {
  clubName: "",
  description: "",
  maxMembers: 1,
  limitMembers:false,
  clubType: "",
  billingPreference: "",
  allowPricingVisibility: false,
  totalBottles: 1,
  pricePerShipment: "",
  shipmentOption: "",
  taxRate: "",
  tastingRoomDiscount: 0,
  selectedShippingOptions: [],
  membershipPerks: "",
  boxWeight: "",
  boxHeight: "",
  boxLength: "",
  boxWidth: "",
  clubPicture: "",
  clubFeeSettings: {
    feeTitle: "",
    feeDescription: "",
    clubFee: 0,
    applyTax: false,
  },
  promotionClubSettings: {
    promotionTitle: "",
    promotionPrice: 0.00,
  },
  welcomePack: {
    maxWines: 5,
    selectedWines: [],
  },
  shippingType:  "",
  isShippable:  "",
  taxApplication: "",

};

function AddClubForm() {
  const navigate = useNavigate();

  const {closeModal,isModalOpen} = useModals()
  const [step, setStep] = useState(0);
   const createClub =  useCreateClub()
  const formikRef = useRef(null);

  const CurrentStepComponent = steps[step].component;



  const clubSubmitFormData = (values) => {
    const formData = new FormData();

    formData.append('name', values.clubName);
    formData.append('logo', values.clubPicture);
    formData.append('description', values.description);
    formData.append('quantity', values.totalBottles);
    formData.append('membership_limit', values.maxMembers);
    formData.append('club_type', values.clubType);
    formData.append('prices_visibility', values.allowPricingVisibility ? 1 : 0);
    formData.append('bill_checkout_total_amount', values.billTotalAmount ? 1 : 0);
    formData.append('allow_extra_products', values.allowMoreBottles ? 1 : 0);
    formData.append('notify_admin_on_selection', values.notifyAdmin ? 1 : 0);
    formData.append('width', values.boxWidth);
    formData.append('length', values.boxLength);
    formData.append('height', values.boxHeight);
    formData.append('weight', values.boxWeight);
    formData.append('club_visibility', values.clubVisible ? 1 : 0);
    formData.append('is_private', values.markAsPrivate ? 1 : 0);

    return formData;
  }

 const handleNext = useCallback(async (values, actions) => {
    const nextStep = step + 1;
    const isLastStep = nextStep === steps.length;


    const operations = {
      submit: async () => {
        try {
          await createClub.mutateAsync(clubSubmitFormData(values));
       
        } catch (error) {
          console.error("Error adding member:", error);
        }
      },
      advance: () => setStep(nextStep),
    };

    await operations[isLastStep ? 'submit' : 'advance']();
    actions.setSubmitting(false);
  }, [step, createClub]);

  const handleBack = () => {
    if (step > 0) setStep(step - 1);
  };

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.validateForm();
      formikRef.current.setTouched({});
    }
  }, [step]);


  const onSaveClubFee = useCallback((values) => {
    if (values) {
      formikRef.current.setFieldValue("clubFeeSettings", values);
    }
  }
  ,[formikRef])

  const onSavePromotion =useCallback((values) => {
    if (values) {
      formikRef.current.setFieldValue("promotionClubSettings", values);
    }
  }
  ,[formikRef])

  const onSaveWelcomePack = useCallback((values) => {
    if (values) {
      formikRef.current.setFieldValue("welcomePack", values);
    }
  }
  ,[formikRef])




  return (
    <div className="max-w-[784px] w-full mx-auto flex flex-col justify-center gap-6">
      <div>
        <Link to="/club-management/clubs">
          <Button aschild variant="ghost" size="sm">
            <ArrowBackIcon className="text-black hover:text-primary" />
          </Button>
        </Link>
        <h1 className="font-bold text-xl pt-2">Add New Club</h1>
      </div>

      <ProgressTracker currentStep={step} steps={ClubSteps} />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={steps[step].validationSchema}
        onSubmit={handleNext}
        
      >
        {({isValid, values}) => (
          <Form className="flex flex-col gap-8">
                        <ScrollToError />
            
            <CurrentStepComponent values={values} />
            <div className="flex justify-end gap-3 text-base2 font-medium">
              {step > 0 ? (
                <Button
                  variant="outline"
                  type="button"
                  onClick={handleBack}
                  className="outline-1"
                >
                  Back
                </Button>
              ) : (
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => navigate("/club-management/clubs")}
                  className="btn"
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="default"
                type="submit"
                disabled={createClub.isPending}
                className="btn btn-primary text-base2 font-medium"
              >
                {step === steps.length - 1 ? `
                  ${createClub.isPending ? "Adding..." : "Add Club"}
                ` : "Next"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>


      <SaveFeeModalWrapper
        isOpen={isModalOpen("clubFeeModal")}
        onClose={() => closeModal("clubFeeModal")}
        title="Club Fee Settings"
        actionLabel="Save Fee"
        secondaryAction={() => closeModal("clubFeeModal")}
        secondaryActionLabel="Cancel"
        onSave={onSaveClubFee}
        initialValues={formikRef.current?.values.clubFeeSettings}
      />

      <PromotionClubModalWrapper
        isOpen={isModalOpen("promotionClubModal")}
        onClose={() => closeModal("promotionClubModal")}
        title="Promotion Settings"
        actionLabel="Save Promotion"
        secondaryAction={() => closeModal("promotionClubModal")}
        secondaryActionLabel="Cancel"
        onSave={onSavePromotion}
        initialValues={formikRef.current?.values.promotionClubSettings}
      />

      <WelcomePackClubWrapper
        isOpen={isModalOpen("welcomePackModal")}
        onClose={() => closeModal("welcomePackModal")}
        title="Welcome Pack  Options"
        actionLabel="Save Pack Options"
        secondaryAction={() => closeModal("welcomePackModal")}
        secondaryActionLabel="Cancel"
        onSave={onSaveWelcomePack}
        initialValues={formikRef.current?.values.welcomePack}
      />
      
    </div>
  );
}

export default AddClubForm;
