import { useEffect, useState, useMemo, useCallback } from 'react';

export const usePagination = (items, itemsPerPage = 4) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(itemsPerPage);


  const totalItems = items.length || 0;
  const totalPages = useMemo(() => Math.max(1, Math.ceil(totalItems / perPage)), [totalItems, perPage]);

  useEffect(() => {
    setCurrentPage(prevPage => Math.min(prevPage, totalPages));
  }, [totalPages]);

  const currentItems = useMemo(() => {
    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;
    return items.slice(indexOfFirstItem, indexOfLastItem);
  }, [items, currentPage, perPage]);

  const handlePageChange = useCallback((pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  }, [totalPages]);

  const handleItemsPerPageChange = useCallback((newPerPage) => {
    setPerPage(newPerPage);
    setCurrentPage(1);
  }, []);

  return {
    currentItems,
    currentPage,
    totalPages,
    totalItems,
    perPage,
    handlePageChange,
    handleItemsPerPageChange,
  };
};

