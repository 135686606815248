import React from "react";
import {Link} from "react-router-dom";

const NotFoundPage = () => {
  return (
    <section className="bg-white ">
      <div className="py-8 px-4 m-auto h-full   min-h-screen flex justify-center items-center max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4  tracking-tight font-extrabold lg:text-xxl text-primary dark:text-primary80">
            404
          </h1>
          <p className="mb-4 text-xl tracking-tight font-bold text-new-secondary-gray md:text-4xl">
            Something's missing.
          </p>
          <p className="mb-4 text-lg font-light text-secondary-grey ">
            Sorry, we can't find this page. Work in progress.{" "}
          </p>
          <Link
            to={"/"}
            className="inline-flex text-slate-500  hover:text-primary focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center  my-4"
          >
            Back to Homepage
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
