import React from "react";
import {Button, Container} from "../../../components/ui";
import {Link} from "react-router-dom";
import {ArrowBackIcon} from "../../../assets/icons";
import ProductForm from "../../../components/forms/ProductForm";

const AddProductPage = () => {
  return (
    <Container className="max-sm:px-2 px-4 mt-4 " size="lg">
      <div>
        <Link to="/products">
          <Button aschild variant="ghost" size="sm">
            <ArrowBackIcon className="text-black hover:text-primary" />
          </Button>
        </Link>
        <h1 className="font-bold text-xl ">Add New Product</h1>
      </div>
      {/* AddProductForm */}
      <ProductForm />
    </Container>
  );
};

export default AddProductPage;
