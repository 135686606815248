import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const RichTextEditor = ({ value, onChange }) => {
  const editorRef = useRef(null);

  const handleEditorChange = (content, editor) => {
    onChange(content);
  };

  return (
    <Editor
      apiKey="pavc0fmrsur7f3i11rrn5ssabkaa714sbvgr73g2c4eqy6di"
      onInit={(evt, editor) => editorRef.current = editor}
      value={value}
      onEditorChange={handleEditorChange}
      init={{
        height: 380,
        menubar: false,
        statusbar: false,
        plugins: 'link image lists',
        toolbar: 'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright | link image | bullist numlist',
        content_style: `
          body {
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            line-height: 1.5;
            color: #2D2D2D;
            padding: 16px;
          }
        `,
        formats: {
          p: { block: 'p' },
          h1: { block: 'h1' },
          h2: { block: 'h2' },
          h3: { block: 'h3' }
        },
        font_size_formats: '14px 16px 18px 20px 24px 28px 32px',
        skin: 'oxide',
        content_css: 'default',
        branding: false,
        elementpath: false,
        resize: false,
        toolbar_mode: 'wrap',
        toolbar_sticky: false,
        toolbar_location: 'top',
        automatic_uploads: true,
        file_picker_types: 'image',
        images_file_types: 'jpg,jpeg,png,gif,svg',
        file_picker_callback: (cb, value, meta) => {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.onchange = function () {
            const file = this.files[0];
            const reader = new FileReader();
            reader.onload = function () {
              const id = 'blobid' + (new Date()).getTime();
              const blobCache = editorRef.current.editorUpload.blobCache;
              const base64 = reader.result.split(',')[1];
              const blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };
          input.click();
        },
      }}
    />
  );
};

export default RichTextEditor;

