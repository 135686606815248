import React from "react";

const EditIcon2 = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0"
      style={{maskType: "alpha"}}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="19"
    >
      <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M1.5 18.5V15.5H16.5V18.5H1.5ZM3 14V10.8125L11.4 2.43125C11.5375 2.29375 11.6969 2.1875 11.8781 2.1125C12.0594 2.0375 12.25 2 12.45 2C12.65 2 12.8438 2.0375 13.0312 2.1125C13.2188 2.1875 13.3875 2.3 13.5375 2.45L14.5688 3.5C14.7188 3.6375 14.8281 3.8 14.8969 3.9875C14.9656 4.175 15 4.36875 15 4.56875C15 4.75625 14.9656 4.94062 14.8969 5.12187C14.8281 5.30312 14.7188 5.46875 14.5688 5.61875L6.1875 14H3ZM12.45 5.6L13.5 4.55L12.45 3.5L11.4 4.55L12.45 5.6Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default EditIcon2;
