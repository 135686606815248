import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../ui";

const ErrorDisplay = () => {
  return (
    <div className='flex flex-col items-center justify-center w- h-[70vh] text-center bg-red-100 text-red-700 '>

      <div className='flex flex-col items-center justify-center w-full h-full self-center text-center bg-red-100 text-red-700 '>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 64 64'
          className='w-24 h-24 mb-6 text-red-500'
          fill='currentColor'
        >
          <circle
            cx='32'
            cy='32'
            r='30'
            stroke='currentColor'
            strokeWidth='2'
            fill='none'
          />
          <circle cx='22' cy='24' r='2' fill='currentColor' />
          <circle cx='42' cy='24' r='2' fill='currentColor' />
          <path
            d='M22 42c4-4 12-4 16 0'
            stroke='currentColor'
            strokeWidth='2'
            fill='none'
            strokeLinecap='round'
          />
        </svg>
        {/* Error message */}
        <h2 className='text-xl font-bold text-primary'>OOPS!</h2>
        <p className='text-xl font-normal pb-5'>something went wrong</p>
        <Link to={"/"}>
          <Button size='sm' className={`w-full px-5 `} aschild variant='default'>
            Go back to home
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ErrorDisplay;
