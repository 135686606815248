import React  from "react";
import {  Label, Modal, Select } from "../../../components/ui";
import { withFormik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const PaymentModal = ({
  isOpen,
  onClose,
  title,
  footer,
  disabled,
  actionLabel,
  secondaryAction,
  secondaryActionLabel,
  values,
  handleSubmit,
  isSubmitting,
  isValid,
  validateForm,
}) => {
  const body = (
    <Form className='flex flex-col gap-4'>
      <div className='flex justify-between flex-col w-full items-start gap-7'>
        {/* Payment Processor */}
        <div className='w-full flex flex-col items-start gap-2'>
          <Label htmlFor='paymentProcessor'>Payment Processor</Label>
          <Field
            name='paymentProcessor'
            as={Select}
            placeholder='Select Payment Processor'
          >
            <option value='' style={{ display: "none" }}>
              Choose anyone
            </option>
            <option value='332'>Fedex</option>
            <option value='323'>UPS</option>
          </Field>
          <ErrorMessage
            name='paymentProcessor'
            component='div'
            className='error-message text-red max-sm:text-small'
          />
        </div>

        {/* Square Location */}
        <div className='w-full flex flex-col items-start gap-2'>
          <Label htmlFor='squareLocation'>Square Location</Label>
          <Field
            name='squareLocation'
            as={Select}
            placeholder='Select Square Location'
          >
            <option value='' style={{ display: "none" }}>
              Choose anyone
            </option>
            <option value='332'>USA 123</option>
            <option value='323'>MXP 123</option>
          </Field>
          <ErrorMessage
            name='squareLocation'
            component='div'
            className='error-message text-red max-sm:text-small'
          />
        </div>
      </div>
    </Form>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      body={body}
      disabled={isSubmitting}
      actionLabel={actionLabel}
      secondaryAction={secondaryAction}
      secondaryActionLabel={secondaryActionLabel}
      onSubmit={handleSubmit}
      disableSubmit={!isValid || isSubmitting}
    />
  );
};

export default withFormik({
  validateOnMount: true,
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    paymentProcessor: props.initialValues?.paymentProcessor || "",
    squareLocation: props.initialValues?.squareLocation || "",
  }),
  validationSchema: Yup.object().shape({
    paymentProcessor: Yup.string().required("Payment Processor is required"),
    squareLocation: Yup.string().required("Square Location is required"),
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    console.log("Submitted values:", values);
    props.onClose(); // Close the modal
    setSubmitting(false); // Reset submission state
    resetForm();
  },
})(PaymentModal);
