import React from 'react'
import ErrorDisplay from '../../components/error/NotFound'

const SettingsPage = () => {
  return (
    <ErrorDisplay />
  )
}

export default SettingsPage
