import React from "react";
import cn from "../../utils/cn";

// DateInput Component

const dateInputStyles = {
  wrapper: "flex items-center h-[56px] w-full rounded-md  border border-input bg-background py-2 text-sm ring-offset-background disabled:cursor-not-allowed disabled:opacity-50",
  input: "flex-1 bg-transparent outline-none placeholder:text-muted-foreground placeholder:text-base2 placeholder:text-secondary-grey placeholder:font-normal",
  icon: "text-muted-foreground ml-2",
  sizes: {
    lg: "h-full max-h-[56px] min-h-[48px] py-4 text-base w-[49.3%]",
  },
  spacings: {
    default: "",
    normal: "px-5",
    iterator: "px-0",
  },
};

const DateInput = React.forwardRef(
  ({ className, size = "lg", spacing = "normal", ...props }, ref) => {
    return (
      <div className={cn(dateInputStyles.wrapper, dateInputStyles.sizes[size], dateInputStyles.spacings[spacing], className)}>
        <input
          type='date'
          className={cn(dateInputStyles.input)}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);

DateInput.displayName = "DateInput";

export default DateInput;