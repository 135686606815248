import React, { useState } from 'react';
import { Button, Checkbox, Tag } from '../../components/ui';

const FilterDropdown = ({ show, onClose }) => {
  const [statusFilters, setStatusFilters] = useState([]);
  const [clubFilters, setClubFilters] = useState([]);

  if (!show) return null;

  const handleStatusFilter = (status) => {
    console.log('status', status);
    console.log('statusFilters', statusFilters);
    setStatusFilters(prev => 
      prev.includes(status) ? prev.filter(s => s !== status) : [...prev, status]
    );
  };

  const handleClubFilter = (club) => {

    setClubFilters(prev => 
      prev.includes(club) ? prev.filter(c => c !== club) : [...prev, club]
    );
  };

  const handleApplyFilters = () => {
    onClose();
  };

  const handleClearFilters = () => {
    setStatusFilters([]);
    setClubFilters([]);
  };

  return (
    <div className="absolute bg-white shadow-md top-[50px] w-full max-w-[480px] rounded-2xl border flex items-center justify-center z-50">
      <div className="w-full flex flex-col">
        <h3 className="px-6 py-4 text-h6 font-medium border-b">Filter</h3>

        {/* Status Filters */}
        <div className="px-6">
          <div className='border-b py-4'>
            <h4 className="text-base font-semibold mb-4">Status</h4>
            <div className="flex gap-2 flex-wrap">
              {['Active', 'Expired', 'Cancelled', 'No Card', 'On Hold'].map(
                (status) => (
                  <Tag
                    key={status}
                    variant={statusFilters.includes(status) ? 'blue' : 'secondary'}
                    text={status}
                    sizes='md'
                    onClick={() => handleStatusFilter(status)}
                  />
                )
              )}
            </div>
          </div>
        </div>

        {/* Club Membership Filters */}
        <div className="px-6">
          <div className='border-b py-4'>
            <h4 className="text-base font-semibold mb-4">Club Membership</h4>
            {[
              'SAVI Society Wine Club',
              'Culinary Corks',
              'LA FANTASIA Club',
              'Celebration Vineyard',
              'Elite Vintage Winery',
            ].map((club) => (
              <div key={club} className="flex items-center mb-2 text-secondary-grey font-medium">
                <Checkbox 
                  name={club} 
                  checked={clubFilters.includes(club)}
                  onChange={() => handleClubFilter(club)}
                />
                <label className="ml-2 text-sm">{club}</label>
              </div>
            ))}
          </div>
        </div>

        {/* Footer Buttons */}
        <div className="flex justify-between px-6 pt-4 pb-6 mt-4 border-t">
          <Button variant="ghost" className="text-secondary-grey" onClick={handleClearFilters}>
            Clear All
          </Button>
          <div className="flex gap-4">
            <Button variant="outline" onClick={onClose} className="">
              Cancel
            </Button>
            <Button variant="default" onClick={handleApplyFilters}>
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterDropdown;
