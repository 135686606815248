import React from "react";
import { Container } from "../../components/ui";
import ReleasesTable from "./ReleasesTable";
import Header from "../../components/ui/Header";
import { pageTitles } from "../../utils/data/dummyData";

const ReleasesPage = () => {
  return (
    <Container type='flex' className='px-2 py-4'>
      {/* Header */}
      <div className=' flex justify-between w-full items-center'>
        <Header>{pageTitles.releasesPage}</Header>
      </div>
      {/* Member Content */}
      <ReleasesTable />
    </Container>
  );
};

export default ReleasesPage;
