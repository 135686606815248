import React from 'react';
import useAppStore from '../../../store/appStore';

const TableCell = ({ children, className = "" }) => {

  const isMobile = useAppStore((state) => state.isMobile);

  if (isMobile) {
    return <td className={`py-4  min-h-10 min-w-[140px] pr-4 break-words  ${className}`}>{children}</td>;
  }


  return <td className={`py-4 px-2  min-h-10  break-words  ${className}`}>{children}</td>;
};

export default TableCell;
