import React, { useMemo } from "react";
import { Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input, Label, Select, DateInput, Checkbox, LoadingSpinner } from "../../ui";
import { useGetAllClubs } from "../../../hooks/useClubs";
import { useUsers } from "../../../hooks/settings/useUsers";

const Step1PersonalInfo = () => {
  return (
    <div className='grid gap-[16px]'>
      <h2 className='text-xl font-bold '>Personal Information</h2>

      <div className='flex flex-col md:flex-row justify-between items-start gap-4'>
        <div className='flex-1 flex flex-col items-start justify-between gap-2 w-full'>
          <Label htmlFor='firstName'>First Name *</Label>
          <Field
            name='firstName'
            as={Input}
            placeholder='First Name'
            className='h-full px-2'
          />
          <ErrorMessage
            name='firstName'
            component='div'
            className='error-message text-red max-sm:text-small'
          />
        </div>
        <div className='flex-1 flex flex-col items-start justify-between gap-2 w-full'>
          <Label htmlFor='lastName'>Last Name *</Label>
          <Field
            name='lastName'
            as={Input}
            placeholder='Last Name'
            className='h-full px-2'
          />
          <ErrorMessage
            name='lastName'
            component='div'
            className='error-message text-red max-sm:text-small'
          />
        </div>
      </div>

      <div className='flex flex-col w-full sm:max-w-[100%] md:max-w-[49.3%] items-start gap-2 cursor-pointer'>
        <Label htmlFor='birthday'>Birthday </Label>
        <Field name='birthday' as={DateInput} size='lg' />

        <ErrorMessage
          name='birthday'
          component='div'
          className='error-message text-red max-sm:text-small'
        />
      </div>

      <div className='flex justify-start items-center gap-2 mb-1 '>
        <Field
          name='unknownBirthday'
          type='checkbox'
          id='unknownBirthday'
          as={Checkbox}
          placeholder='Choose anyone'
        ></Field>
        <Label htmlFor='unknownBirthday'>
          Birthday Unknown (Drinking age has been confirmed){" "}
        </Label>
        <ErrorMessage
          name='unknownBirthday'
          component='div'
          className='error-message text-red max-sm:text-small'
        />
      </div>
      <div className='flex flex-col md:flex-row justify-between items-start gap-4  '>
        <div className='flex-1 flex flex-col items-start justify-between gap-2 w-full'>
          <Label htmlFor='email'>Email Address *</Label>
          <Field
            name='email'
            as={Input}
            placeholder='Email Address'
            className='h-full px-2'
          />
          <ErrorMessage
            name='email'
            component='div'
            className='error-message text-red max-sm:text-small'
          />
        </div>
        <div className='flex-1 flex flex-col items-start justify-between gap-2 w-full'>
          <Label htmlFor='phone'>Phone Number *</Label>
          <Field
            name='phone'
            as={Input}
            type='tel'
            onKeyPress={(e) => {
              //  prevent letters and special characters exept numbers and +
              if (!/[0-9+]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            placeholder='Phone Number'
            className='h-full px-2'
          />
          <ErrorMessage
            name='phone'
            component='div'
            className='error-message text-red max-sm:text-small'
          />
        </div>
      </div>
      <div className='flex-1 flex flex-col items-start justify-between  h-full gap-2'>
        <Label htmlFor='secondaryPhone'>Secondary Contact (optional)</Label>
        <Field
          name='secondaryPhone'
          as={Input}
          placeholder='Secondary Contact'
          className='h-full px-2'
          onKeyPress={(e) => {
            //  prevent letters and special characters exept numbers and +
            if (!/[0-9+]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          type='tel'
        />
        <ErrorMessage
          name='secondaryPhone'
          component='div'
          className='error-message text-red max-sm:text-small'
        />
      </div>
      <h2 className='text-xl font-bold'>Club Information</h2>

      <ClubMembershipSelect/>
      <div className=''>
        <Label htmlFor='nrOfReleases'>Number Of Releases*</Label>
        <Field name='nrOfReleases' as={Select} placeholder='Choose anyone'>
          <option value='default'>
            Open-ended Membership (Until Canceled)
          </option>
          <option value='5'>5 Releases</option>
          <option value='15'>15 Releases</option>
          <option value='50'>50 Releases</option>
        </Field>
        <ErrorMessage
          name='nrOfReleases'
          component='div'
          className='error-message text-red max-sm:text-small'
        />
      </div>
      <div className='flex flex-col items-start gap-2 '>
        <Label htmlFor='membershipStartDate'>Membership Start Date *</Label>
        <Field name='membershipStartDate' as={DateInput} size='lg' />
        <ErrorMessage
          name='membershipStartDate'
          component='div'
          className='error-message text-red max-sm:text-small'
        />
      </div>
      <div className='flex flex-col md:flex-row justify-between items-start gap-4  '>
        <div className='flex-1 flex flex-col items-start justify-between gap-2 w-full'>
          <Label htmlFor='memberNumber'>Member Number *</Label>
          <Field
            name='memberNumber'
            as={Input}
            type='text'
            placeholder='Member Number'
            className='h-full px-2'
          />
          <ErrorMessage
            name='memberNumber'
            component='div'
            className='error-message text-red max-sm:text-small'
          />
        </div>
        <SignedUpBySelectUser />
       
      </div>
    </div>
  );
};

// Validation schema for Step 1
export const step1Validation = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  birthday: Yup.date(),
  email: Yup.string().email().required("Email is required"),
  phone: Yup.string().required("Phone Number is required").max(15 , 'Phone number is too long'),
  secondaryPhone: Yup.string().max(15 , 'Phone number is too long'),
  clubMembership: Yup.string().required("Club Membership is required"),
  nrOfReleases: Yup.string().required("Number of Releases is required"),
  membershipStartDate: Yup.date().required("Membership Start Date is required"),
  memberNumber: Yup.string().required("Member Number is required"),
  signedUpBy: Yup.string().required("Signed By is required"),
  unknownBirthday: Yup.boolean(),
});

export default Step1PersonalInfo;



export const ClubMembershipSelect= () => {

  const {data,isError,isLoading} = useGetAllClubs();

  const clubOptions = useMemo(() => {
    if(!data) return [];
    return data.data.clubs.map(club => ({
      value: club.id,
      label: club.name
    }))
  },[data]);

  if(isLoading){
    return (
      <div className=''>
      <Label htmlFor='clubMembership'>Club Membership *</Label>
      <Field name='clubMembership' as={Select} placeholder='Loading...'
      >
        <option value='' className='hidden'>
          Select a club membership
        </option>

      </Field>

    </div>
    )
   
  }

  if(isError){
    return (
      <div className=''>
      <Label htmlFor='clubMembership'>Club Membership *</Label>
      <Field name='clubMembership' as={Select} placeholder='No club membership found' 
      style={{color:'red'}}
      >
        <option value='' className='hidden'>
          Select a club membership
        </option>

      </Field>
    
    </div>
    )
  }

  return (
    <div className=''>
        <Label htmlFor='clubMembership'>Club Membership *</Label>
        <Field name='clubMembership' as={Select} placeholder='Choose anyone'>
          <option value='' className='hidden'>
            Select a club membership
          </option>
          {clubOptions.map(club => (
            <option key={club.value} value={club.value}>
              {club.label}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name='clubMembership'
          component='div'
          className='error-message text-red max-sm:text-small'
        />
      </div>
  );
}

export const SignedUpBySelectUser = () => {

  const {data,isError,isLoading} =  useUsers();

  const userOptions = useMemo(() => {
    if(!data) return [];
    return data.data.users.map(user => ({
      value: user.id,
      label: `${user.name} ${user.last_name}`
    }))
  },[data]);

  if(isLoading){
    return (
      <div className=''>
      <Label htmlFor='signedUpBy'>Signed By *</Label>
      <Field name='signedUpBy' as={Select} placeholder='Loading...'
      >
        <option value='' className='hidden'>
          Select a user
        </option>

      </Field>

    </div>
    )
  }

  if(isError){
    return (
      <div className=''>
      <Label htmlFor='signedUpBy'>Signed By *</Label>
      <Field name='signedUpBy' as={Select} placeholder='No user found' 
      style={{color:'red'}}
      >
        <option value='' className='hidden'>
          Select a user
        </option>

      </Field>
    
    </div>
    )
  }


  return (
    <div className='flex-1 flex flex-col items-start justify-between gap-2 w-full'>
    <Label htmlFor='signedUpBy'>Signed By *</Label>
    <Field name='signedUpBy' as={Select} placeholder='Choose anyone'>
      <option value='' className='hidden'>
        Select a user
      </option>
      {userOptions.map(user => (
        <option key={user.value} value={user.value}>
          {user.label}
        </option>
      ))}
    </Field>
    <ErrorMessage
      name='signedUpBy'
      component='div'
      className='error-message text-red max-sm:text-small'
    />
  </div>
  )


}