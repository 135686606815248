import {useMutation, useQueryClient} from "@tanstack/react-query";
import useAuthStore from "../store/authStore";
import {
  forgotPassword,
  loginUser,
  logoutUser,
  resetPassword,
} from "../api/auth/authQueries";
import useNotification from "./useNotification";
import {useNavigate} from "react-router-dom";

export const useLoginMutation = () => {
  const login = useAuthStore((state) => state.login);
  const {showToast, STATUSES} = useNotification();

  return useMutation({
    mutationFn: loginUser,
    onSuccess: (user) => {
      const {data} = user;

      login(data);
      showToast("Login successful", STATUSES.ACTION,`Welcome ${data.user.name}`);
    },
    onError: (error) => {
      showToast("Login Failed , Please check your credentials", STATUSES.ERROR);
    },
  });
};

export const useLogoutMutation = () => {
  const logout = useAuthStore((state) => state.logout);
  const {showToast, STATUSES} = useNotification();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: logoutUser,
    onSuccess: () => {
      logout();
      queryClient.clear();
      showToast("Logout successful", STATUSES.ACTION);
    },
    onError: (error) => {
      showToast("Something went wrong, Please try again", STATUSES.ERROR);
    },
  });
};

export const useForgotPasswordMutation = () => {
  const {showToast, STATUSES} = useNotification();

  return useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      showToast("Password reset link sent to your email", STATUSES.SUCCESS);
    },
    onError: (error) => {
      showToast("Password reset failed, Please try again", STATUSES.ERROR);
    },
  });
};

export const useResetPasswordMutation = () => {
  const {showToast, STATUSES} = useNotification();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      navigate("/login");
      showToast("Password reset successful", STATUSES.SUCCESS);
    },
    onError: (error) => {
      showToast("Password reset failed, Please try again", STATUSES.ERROR);
    },
  });
};
