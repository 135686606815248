import api from "../axiosInstance";

export const getAllClubs = async () => {
  try {
    const response = await api.get("/clubs");

    // handle not successful response
    if (!response.data) {
      throw new Error("No data received from the server.");
    }
    
    return response.data; // Return the actual data
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error(`Error: ${error.response.status} - ${error.response.data?.message || error.message}`);
      throw new Error(error.response.data?.message || `Server error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Error: No response received from the server.");
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      console.error(`Error: ${error.message}`);
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
};


export const getClub = async (clubId) => {
  try {
    const response = await api.get(`/clubs/${clubId}`);

    // handle not successful response
    if (!response.data) {
      throw new Error("No data received from the server.");
    }
    
    return response.data; // Return the actual data
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error(`Error: ${error.response.status} - ${error.response.data?.message || error.message}`);
      throw new Error(error.response.data?.message || `Server error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Error: No response received from the server.");
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      console.error(`Error: ${error.message}`);
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
}


export const createClub = async (clubData) => {
  try {
    const response = await api.post("/clubs", clubData , {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    // handle not successful response
    if (!response.data) {
      throw new Error("No data received from the server.");
    }
    
    return response.data; // Return the actual data
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error(`Error: ${error.response.status} - ${error.response.data?.message || error.message}`);
      throw new Error(error.response.data?.message || `Server error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Error: No response received from the server.");
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      console.error(`Error: ${error.message}`);
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
}


export const updateClub = async (clubId, clubData) => {
  try {
    const response = await api.post(`/clubs/${clubId}?_method=PUT`, clubData , {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    // handle not successful response
    if (!response.data) {
      throw new Error("No data received from the server.");
    }
    
    return response.data; // Return the actual data
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error(`Error: ${error.response.status} - ${error.response.data?.message || error.message}`);
      throw new Error(error.response.data?.message || `Server error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Error: No response received from the server.");
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      console.error(`Error: ${error.message}`);
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
}


export const deleteClub = async (clubId) => {
  try {
    const response = await api.delete(`/clubs/${clubId}`);

    // handle not successful response
    if (!response.data) {
      throw new Error("No data received from the server.");
    }
    
    return response.data; // Return the actual data
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error(`Error: ${error.response.status} - ${error.response.data?.message || error.message}`);
      throw new Error(error.response.data?.message || `Server error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Error: No response received from the server.");
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      console.error(`Error: ${error.message}`);
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
}
