import React, { useMemo, useState } from "react";
import Container from "../../components/ui/Container";
import { Button, Input, LoadingSpinner } from "../../components/ui";
import { SearchIcon } from "../../assets/icons";
import FilterIcon from "../../assets/icons/Filter";
import FilterDropdown from "./FilterDropdown";
import useClickOutside from "../../hooks/useOnClickOutside";
import MembershipTable from "./MembershipTable";
import Header from "../../components/ui/Header";
import { pageTitles } from "../../utils/data/dummyData";
import {  useGetMembers } from "../../hooks/useMembers";

const MembershipsPage = () => {
  const [showFilterModal, setShowFilterModal] = useState(false);

  //TODO: Implement search and filter
  // const [filters, setFilters] = useState({});
  // const [search, setSearch] = useState("");
  const modalRef = useClickOutside(() => setShowFilterModal(false));

  const {data,isError,isLoading } = useGetMembers();

  const members = useMemo(() => {
    if (!data || !data.data ||  !data.data.members) {
      return [];
    }

    return data.data.members;
  }, [data]);


  if (isLoading) {
    return    (
      <Container type='flex' className='px-2 py-4'>
          <LoadingSpinner />
      </Container>
    )
  }    

  if (isError) {
    return <div>Error</div>;
  }







  return (
    <Container type='flex' className='px-2 py-4'>
      {/* Header */}
      <div
        className=' flex justify-between w-full items-center flex-wrap gap-3'
        ref={modalRef}
      >
        <Header>{pageTitles.membershipsPage}</Header>
        <div className='flex justify-end max-w-[550px] flex-1 gap-3 relative'>
          <div className='inline-flex    focus-within:border-blue  w-full max-w-[444px] min-w-[200px] items-center justify-between outline-1 outline outline-[#DCDCDC]   rounded-md pr-4'>
            <Input
              type='text'
              placeholder='Search'
              name='search'
              id='search'
              size='sm'
              className=' w-full  flex-1 border-none no-shadow placeholder:text-base placeholder:font-normal'
            />
            <SearchIcon className='h-6 w-6 text-gray-400 ' />
          </div>
          <div className='inline-flex justify-center items-center gap-2  '>
            <Button
              onClick={() => setShowFilterModal(!showFilterModal)}
              variant='outline'
              className='
              h-[40px]
              outline-[#DCDCDC]
              font-medium text-base2

              text-secondary-grey select-none hover:bg-primary hover:text-white hover:outline-none  flex-1 w-[98px]'
              aschild
            >
              <FilterIcon /> Filter
            </Button>
          </div>
          <FilterDropdown
            show={showFilterModal}
            onClose={() => setShowFilterModal(false)}
          />
        </div>
      </div>
      {/* Member Content */}
      <MembershipTable members={members} />
    </Container>
  );
};

export default MembershipsPage;
