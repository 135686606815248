import React from "react";

const Table = ({ children }) => {

  return (
    <div className="space-y-4">
      <div className="relative w-[91vw]  lg:w-full overflow-x-auto border border-gray-200 rounded-md px-4 max-w-screen-xxl mt-2">
        <table className="w-full bg-white rounded-lg shadow-sm table-auto">
          {/* Apply table-auto for responsive column sizing */}
          {children}
        </table>
      </div>
    </div>
  );
};

export default Table;
