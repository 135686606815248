import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../api/queryKeys";
import { getAllStates } from "../../api/members/membersQueries";

 const useGetStates = () => { 

  const { data, isLoading, isError, status } = useQuery({
    queryKey: queryKeys.STATES,
    queryFn: getAllStates,
    refetchOnWindowFocus: false,

  }); 
  return { isError, isLoading, status , data };

}

export default useGetStates;