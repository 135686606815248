import { create } from 'zustand';

const useAppStore = create((set) => ({
  isMobile: false,
  selectedTenant: null,
  tenants: [],
  roles : [],
  isLoading: false,
  error: null,

  setSelectedTenant: (tenant) => set({ selectedTenant: tenant }),
  setTenants: (tenants) => set({ tenants }),
  setRoles: (roles) => set({ roles }),
  setUsers: (users) => set({ users }),
  addUser : (user) => set((state) => ({ users: [...state.users, user] })),
  addRole: (role) => set((state) => ({ roles: [...state.roles, role] })),
  updateRole: (role) => set((state) => ({ roles: state.roles.map((r) => r.id === role.id ? role : r) })),
  setLoading: (isLoading) => set({ isLoading }),
  setError: (error) => set({ error }),
  reset: () => set({ selectedTenant: null, tenants: [], isLoading: false, error: null }),
  setMobile: (isMobile) => set({ isMobile }),
}));

export default useAppStore;

