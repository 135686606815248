
const dummyUsersAccounts = [
  {
    id: 1,
    name: 'John Doe',
    email: 'johnDoe@example.com',
    club:'SAVI Society Wine Club',
    created: 'June 10, 2020 2:41 PM	',
    status: 'Active',
    sentEmail: true,
  },
  {
    id: 2,
    name: 'Darrell Stewarde',
    email: 'darrelteward@example.com',
    club:'SAVI Society Wine Club',
    created: 'October 10, 2020 2:41 PM	',
    status : 'Disabled',
    sentEmail: true
  },
  {
    id: 3,
    name: 'Ronald Richards',
    email: 'ronald@gmail.com',
    club:'LA FANTASIA Club',
    created: 'October 10, 2020 2:41 PM	',
    status : 'Active',
    sentEmail: false
  },
  {
    id: 4,
    name: 'Micheal Johnson',
    email: 'micJohn@example.com',
    club:'Elite Vintage Winery',
    created: 'October 10, 2020 2:41 PM	',
    status : 'Active',
    sentEmail: false
    }, 
    {
      id: 5,
      name: 'John Doe',
      email: 'johnDoe@example.com',
      club:'SAVI Society Wine Club',
      created: 'October 10, 2020 2:41 PM	',
      status : 'Active',
      sentEmail: true,
    },
    {
      id: 6,
      name: 'Darrell Stewarde',
      email: 'darrelteward@example.com',
      club:'SAVI Society Wine Club',
      created: 'October 10, 2020 2:41 PM	',
      status : 'Disabled',
      sentEmail: true
    },
    {
      id: 7,
      name: 'Ronald Richards',
      email: 'ronald@gmail.com',
      club:'LA FANTASIA Club',
      created: 'October 20, 2020 2:41 PM	',
      status : 'Active',
      sentEmail: false
    },
    {
      id: 8,
      name: 'Micheal Johnson',
      email: 'micJohn@example.com',
      club:'Elite Vintage Winery',
      created: 'October 10, 2020 2:41 PM	',
      status : 'Pending Invitation',

      sentEmail: false
      }, 
      {
        id: 9,
        name: 'John Doe',
        email: 'micJohn@example.com',
        club:'SAVI Society Wine Club',
        created: 'October 10, 2020 2:41 PM	',
        status : 'Pending Invitation',
        sentEmail: true,
      },

]

export default dummyUsersAccounts;