import { useFormikContext } from "formik";
import { useEffect } from "react";

export function ScrollToError() {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;
  const errors = formik?.errors;

  useEffect(() => {
    if (submitting && Object.keys(errors).length > 0) {
      const firstErrorField = Object.keys(errors)[0];
      const errorElement = document.querySelector(`[name="${firstErrorField}"]`);

      if (errorElement) {
        // Scroll to the error element smoothly
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });

        // Add a slight delay before focusing the element
        setTimeout(() => {
          errorElement.focus();
        }, 400); // Delay in milliseconds (adjust as needed)
      }
    }
  }, [submitting, errors]);

  return null;
}