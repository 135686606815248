import api from "../axiosInstance";

export const getAllProducts = async () => {
  try {
    const response = await api.get("/products");

    // handle not successful response
    if (!response.data) {
      throw new Error("No data received from the server.");
    }
    
    return response.data; // Return the actual data
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error(`Error: ${error.response.status} - ${error.response.data?.message || error.message}`);
      throw new Error(error.response.data?.message || `Server error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Error: No response received from the server.");
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      console.error(`Error: ${error.message}`);
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
};

export const getProduct = async (productId) => {
  try {
    const response = await api.get(`/products/${productId}`);

    // handle not successful response
    if (!response.data) {
      throw new Error("No data received from the server.");
    }
    
    return response.data; // Return the actual data
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error(`Error: ${error.response.status} - ${error.response.data?.message || error.message}`);
      throw new Error(error.response.data?.message || `Server error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Error: No response received from the server.");
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      console.error(`Error: ${error.message}`);
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
}

export const createProduct = async (productData) => {
  try {
    const response = await api.post("/products", productData , {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });

    // handle not successful response
    if (!response.data) {
      throw new Error("No data received from the server.");
    }
    
    return response.data; // Return the actual data
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error(`Error: ${error.response.status} - ${error.response.data?.message || error.message}`);
      throw new Error(error.response.data?.message || `Server error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Error: No response received from the server.");
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      console.error(`Error: ${error.message}`);
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
}

export const updateProduct = async (productId, productData) => {
  try {
    const response = await api.post(`/products/${productId}?_method=PUT`, productData , {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });

    // handle not successful response
    if (!response.data) {
      throw new Error("Something Went Wrong");
    }

    return response.data; // Return the actual data
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error(`Error: ${error.response.status} - ${error.response.data?.message || error.message}`);
      throw new Error(error.response.data?.message || `Something Went Wrong.`);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Something Went Wrong.");
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      console.error(`Error: ${error.message}`); 
      throw new Error("Something Went Wrong..");
    }
  }
}



export const getProductTypes = async () => {
  try {
    const response = await api.get("/product-types");

    // handle not successful response
    if (!response.data) {
      throw new Error("No data received from the server.");
    }
    
    return response.data; // Return the actual data
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error(`Error: ${error.response.status} - ${error.response.data?.message || error.message}`);
      throw new Error(error.response.data?.message || `Server error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Error: No response received from the server.");
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      console.error(`Error: ${error.message}`);
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
}


export const deleteProduct = async (productId) => {
  try {
    const response = await api.delete(`/products/${productId}`);

    // handle not successful response
    if (!response.data) {
      throw new Error("No data received from the server.");
    }
    
    return response.data; // Return the actual data
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error(`Error: ${error.response.status} - ${error.response.data?.message || error.message}`);
      throw new Error(error.response.data?.message || `Server error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Error: No response received from the server.");
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      console.error(`Error: ${error.message}`);
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
}


export const  archiveProduct = async (productId) => {
  try {
    const response = await api.post(`/products/${productId}/archive`);

    // handle not successful response
    if (!response.data) {
      throw new Error("No data received from the server.");
    }
    
    return response.data; // Return the actual data
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error(`Error: ${error.response.status} - ${error.response.data?.message || error.message}`);
      throw new Error(error.response.data?.message || `Server error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Error: No response received from the server.");
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      console.error(`Error: ${error.message}`);
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
}




export const  restoreProduct = async (productId) => {
  try {
    const response = await api.post(`/products/${productId}/restore`);

    // handle not successful response
    if (!response.data) {
      throw new Error("No data received from the server.");
    }
    
    return response.data; // Return the actual data
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error(`Error: ${error.response.status} - ${error.response.data?.message || error.message}`);
      throw new Error(error.response.data?.message || `Server error: ${error.response.status}`);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Error: No response received from the server.");
      throw new Error("No response received from the server. Please check your network connection.");
    } else {
      // Something else happened while setting up the request
      console.error(`Error: ${error.message}`);
      throw new Error("An unexpected error occurred. Please try again.");
    }
  }
}