import React, { useCallback, useState, useMemo } from 'react';
import { Button, Container } from '../../../components/ui';
import { TiPlus } from "../../../assets/icons";
import { Link } from "react-router-dom";
import ClubsTable from "./ClubsTable";
import Header  from "../../../components/ui/Header";
import {pageTitles} from "../../../utils/data/dummyData";
import useModals from '../../../hooks/useModals';
import ClubActionModal from '../clubs/ClubsActionModal';
import { useDeleteClub } from '../../../hooks/useClubs';

const ClubPage = () => {
  const [selectedClub, setSelectedClub] = useState(null);
  const [actionType, setActionType] = useState(null);
  const { isModalOpen, closeModal, openModal } = useModals();
  const clubActionMutation = useDeleteClub();

  const handleModalClose = useCallback(() => {
    closeModal("clubActionModal");;
    setSelectedClub(null);
    setActionType(null);
  }, [closeModal]);

  const handleModalSubmit = useCallback(async () => {
    if (actionType && selectedClub) {
      try {
        // Perform the mutation here
        await clubActionMutation.mutateAsync(  selectedClub.id );
      } catch (error) {
        console.error(`Error ${actionType} user:`, error);
      } finally {
        handleModalClose();
      }
    }
  }, [actionType, selectedClub, clubActionMutation, handleModalClose]);

  const handleProductAction = useCallback((club, action) => {
    setSelectedClub(club);
    setActionType(action);
    openModal("clubActionModal");
  }, [openModal]);

  const modalTitle = useMemo(() => actionType ? `${actionType.charAt(0).toUpperCase() + actionType.slice(1)} Club` : "", [actionType]);
  const actionLabel = useMemo(() => actionType ? actionType.charAt(0).toUpperCase() + actionType.slice(1) : "", [actionType]);

  

  return (
    <Container type='flex' className='px-0 py-3 ' size='full'>
      <div className='flex px-5 justify-between flex-wrap items-center gap-3'>
        <Header aschild variant='mobile'>
          {pageTitles.clubManagement}
        </Header>
        <Link to='/club-management/clubs/add-club'>
          <Button aschild variant='default' >
            <TiPlus />  Add New Club
          </Button>
        </Link>
      </div>

      <ClubsTable 
      onDelete={(product) => handleProductAction(product, "delete")}
      onArchive={(product) => handleProductAction(product, "archive")}
      onUnarchive={(product) => handleProductAction(product, "unarchive")}

      />

      <ClubActionModal
        isOpen={isModalOpen("clubActionModal")}
        onClose={handleModalClose}
        title={modalTitle}
        actionLabel={actionLabel}
        selectedClub={selectedClub}
        secondaryAction={handleModalClose}
        secondaryActionLabel="Cancel"
        onSubmit={handleModalSubmit}
        actionType={actionType}
        isPending={clubActionMutation.isPending}
      />
    </Container>
  );
};

export default ClubPage;
