import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useRef } from 'react'
import {  useNavigate } from 'react-router-dom';
import { Button } from '../../../components/ui';
import * as Yup from "yup";

import Step1PersonalInfo from '../../../components/forms/addMemberForm/Step1PersonalInfo';
import Step2Releases from '../../../components/forms/addMemberForm/Step2Releases';
import Step3MemberBilling from '../../../components/forms/addMemberForm/Step3Billing';
import { useUpdateMember } from '../../../hooks/useMembers';
import { ScrollToError } from '../../../components/ScrollToError';



const initialValues = {
    firstName: "",
    lastName: "",
    birthday: "",
    unknownBirthday: false,
    email: "",
    phone: "",
    secondaryPhone: "",
    clubMembership: "",
    nrOfReleases: "default",
    membershipStartDate: "",
    memberNumber: "",
    signedUpBy: "",
    firstReleaseDate: "",
    deliveryMethod: 'shipping',
    shippingAddress: "",
    city: "",
    state: "",
    zipCode: "",
    addNote: "",
    billingOptionValue: "",
    cardHolderName: "",
    cardNumber: "",
    expirationDate: "",
    cvv: "",
    billingAddress: "",
  };
  


const EditMemberForm = ({id , memberInitialData}) => {

  
   const initialValuesData = useMemo(() => {
      return memberInitialData ? memberInitialData : initialValues;
    }, [memberInitialData]);


    const navigate = useNavigate();

    const formikRef = useRef(null);

   const updateMember = useUpdateMember()





      useEffect(() => {
        if (formikRef.current) {
          formikRef.current.validateForm(); // Re-validate the form based on the new step's schema
          formikRef.current.setTouched({}); // Reset touched state to prevent errors from showing immediately
        }
        // Get all the values from the form
        const values = formikRef.current.values;
        console.log("Step Data:", values);
      }, []);

      const handleSubmit = async (values, { setSubmitting }) => {

        const formattedValues = {
          name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          phone_number: values.phone,
          phone_number_2: values.secondaryPhone,
          is_drinking_age_confirmed: values.unknownBirthday,
          birthday: values.birthday,
          signed_up_by: values.signedUpBy,
          club_id: parseInt(values.clubMembership),
          membership_start_date: values.membershipStartDate,
          membership_expiration_date: values.membershipStartDate,
          membership_number: values.memberNumber,
          notes: values.addNote,
          address_line_1: values.shippingAddress || "",
          state_id: parseInt(values.state) || "",
          city: values.city || "",
          zip: values.zipCode || "",
          country: "USA",
        };

        try {
        await updateMember.mutateAsync({id, memberData: formattedValues})
        } catch (error) {
          console.error("Error updating member:", error);
        }
        
        setSubmitting(false)
      }

  return (
    <div>

<Formik
        innerRef={formikRef}
        initialValues={initialValuesData}
        validationSchema={validationSchemaEditForm}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnMount={true}
        validateOnBlur
   
      >
        {({ isValid, values }) => (
          <Form className="flex flex-col gap-8 max-w-[1024px] mx-auto mt-4 p-3">
            <ScrollToError />
            {/* Club Info Fields */}
           <Step1PersonalInfo />
           <Step2Releases  />
           <Step3MemberBilling />


             <div className="flex justify-end gap-3 text-base2 font-medium">
                        
                            <Button
                              variant="outline"
                              type="button"
                              onClick={() => {
                                navigate("/club-management/clubs");
                              }}
                              className="btn"
                            >
                              Cancel
                            </Button>
                          <Button
                            variant="default"
                            type="submit"
                            disabled={updateMember.isPending}
                            >
                            {updateMember.isPending ? "Updating..." : "Update"}
                          </Button>
                        </div>

          </Form>
        )}
      </Formik>
      
    </div>
  )
}

export default EditMemberForm



export const validationSchemaEditForm = Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      birthday: Yup.date(),
      email: Yup.string().email().required("Email is required"),
      phone: Yup.string().required("Phone Number is required").max(15 , 'Phone number is too long'),
      secondaryPhone: Yup.string().max(15 , 'Phone number is too long'),
      clubMembership: Yup.string().required("Club Membership is required"),
      nrOfReleases: Yup.string().required("Number of Releases is required"),
      membershipStartDate: Yup.date().required("Membership Start Date is required"),
      memberNumber: Yup.string().required("Member Number is required"),
      signedUpBy: Yup.string().required("Signed By is required"),
      unknownBirthday: Yup.boolean(),
       firstReleaseDate: Yup.date().required("First release date is required"),
        deliveryMethod: Yup.string().required("Delivery method is required"),
        shippingAddress: Yup.string().when("deliveryMethod", {
          is: "shipping",
          then: () => Yup.string().required("Shipping address is required"),
        }),
        city: Yup.string().when("deliveryMethod", {
          is: "shipping",
          then: () => Yup.string().required("City is required"),
        }),
        state: Yup.string().when("deliveryMethod", {
          is: "shipping",
          then: () => Yup.string().required("State is required"),
        }),
        zipCode: Yup.string().when("deliveryMethod", {
          is: "shipping",
          then: () => Yup.string().required("Zip code is required"),
        }),
        addNote: Yup.string(),
        billingOptionValue: Yup.string().required("Billing option is required"),
        cardHolderName: Yup.string().required("Card holder's name is required"),
        cardNumber: Yup.string()
          .required("Card number is required")
          .matches(/^[0-9]{16}$/, "Card number must be 16 digits"),
        expirationDate: Yup.string()
          .required("Expiration date is required")
          .matches(/^(0[1-9]|1[0-2])\/([0-9]{2})$/, "Must be in MM/YY format"),
        cvv: Yup.string()
          .required("CVV is required")
          .matches(/^[0-9]{3,4}$/, "CVV must be 3 or 4 digits"),
        billingAddress: Yup.string().required("Billing address is required"),
});