import React, {useState, useMemo, useCallback, lazy, Suspense} from "react";
import {Container, LoadingSpinner, Tabs} from "../../components/ui";
import useModals from "../../hooks/useModals";
import {useRoles} from "../../hooks/settings/useRoles";
import AddUserModalWrapper from "./modals/AddUserModal";
import {useUsers} from "../../hooks/settings/useUsers";

// Lazy load tab components
const BusinessDetailsForm = lazy(() => import("./BusinessDetailsForm"));
const TenantUsers = lazy(() => import("./TenantUsers"));
const ShippingStates = lazy(() => import("./ShippingStates"));
const TermsAndConditions = lazy(() => import("./TermsAndConditions"));
const RolesAndPermissionsPage = lazy(() =>
  import("./roles_and_premissions/index"),
);

const TenantSettingsPage = () => {
  // get active tab from the url params activeTab param or default to 'business'
  const urlParams = new URLSearchParams(window.location.search);
  const [activeTab, setActiveTab] = useState(
    urlParams.get("tab") || "business",
  );
  const {isModalOpen, closeModal} = useModals();
  const {roles, isLoading: isLoadingRoles, isError: IsErrorRoles} = useRoles();
  const {
    data: usersData,
    isError: IsErrorUsers,
    isLoading: isLoadingUsers,
  } = useUsers();

  const tabs = useMemo(
    () => [
      {label: "Business Details", value: "business"},
      {label: "Users", value: "users"},
      {label: "Shipping States", value: "shipping"},
      {label: "Terms and Conditions", value: "terms"},
      {label: "Roles and Permissions", value: "roles"},
    ],
    [],
  );

  // Memoize tab change handler

  const onCloseModal = (type) => {
    closeModal(type);
  };

  const handleTabChange = useCallback((value) => {
    setActiveTab(value);
  }, []);

  // Memoize tab components mapping
  const TAB_COMPONENTS = useMemo(
    () => ({
      business: BusinessDetailsForm,
      users: () => (
        <TenantUsers
          users={usersData}
          isError={IsErrorUsers}
          isLoading={isLoadingUsers}
        />
      ),
      shipping: ShippingStates,
      terms: TermsAndConditions,
      //pass roles page with roles and isLoading and isError
      roles: () => (
        <RolesAndPermissionsPage
          roles={roles}
          isLoading={isLoadingRoles}
          isError={IsErrorRoles}
        />
      ),
    }),
    [
      IsErrorRoles,
      IsErrorUsers,
      isLoadingRoles,
      isLoadingUsers,
      roles,
      usersData,
    ],
  );

  const ActiveComponent = TAB_COMPONENTS[activeTab] || BusinessDetailsForm;

  return (
    <Container type="flex" className="px-4 py-4" size="xxl">
      <div className="flex justify-between items-center">
        <Tabs tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange} />
      </div>
      <Suspense fallback={<LoadingSpinner />}>
        <ActiveComponent />
      </Suspense>

      <AddUserModalWrapper
        isOpen={isModalOpen("addUserModal")}
        onClose={() => onCloseModal("addUserModal")}
        title="Add User"
        actionLabel="Create"
        secondaryAction={() => onCloseModal("addUserModal")}
        secondaryActionLabel="Cancel"
      />
    </Container>
  );
};

export default TenantSettingsPage;
