import React, { useState } from 'react'
import { Button, Container, Select } from '../../../components/ui'
import EmailsTable from './EmailsTable';
import { Link } from 'react-router-dom';
import { pageTitles } from '../../../utils/data/dummyData';
import  Header  from '../../../components/ui/Header';

const EmailHistoryPage = () => {

    const [timePeriod, setTimePeriod] = useState('1-year'); // Track selected option
  
  
  
    const handleSelectChange = (event) => {
      setTimePeriod(event.target.value);
    }; 


  return (
    <Container type="flex" className="px-4 py-4" size='xxl'>
        <Header>{pageTitles.emailHistoryPage}</Header>
        <div className=" pt-4 flex justify-between flex-wrap gap-3 ">
            <Select
              name='timePeriod'
              id="timePeriod"
              placeholder='Time Period'
              className='max-w-[205px] mr-2'
              size='sm'
              onChange={handleSelectChange}
              value={timePeriod} // Bind value to state
            >
               <option value='all'>All</option>
              <option value='1-year'>Last 12 months</option>
              <option value='three-months'>Last 3 months</option>
              <option value='six-months'>Last 6 Months</option>
              <option value='twelve-months'>Last 12 months</option>
            </Select>

            <Link to='/send-email'>
            <Button aschild variant='default' className='min-w-[250px]'>Email Members</Button>
            </Link>


          </div>
    <EmailsTable />
 

  </Container>
  )
}

export default EmailHistoryPage
