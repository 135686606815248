import React from "react";
import { Input, Label, Modal, Select } from "../../../components/ui";
import { withFormik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../api/queryKeys";
import { useCreateUser } from "../../../hooks/settings/useUsers";

const RoleField = ({ roles }) => (
  <div className='w-full flex flex-col items-start gap-2'>
    <Label htmlFor='role'>Role</Label>
    {roles && roles.length > 0 ? (
      <>
        <Field
          name='role'
          as={Select}
          placeholder='Select Role'
        >
          <option className="hidden" value=''>Select Role</option>
          {roles.map((role) => (
            <option key={role.id} value={role.id}>
              {role.name}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name='role'
          component='div'
          className='error-message text-red max-sm:text-small'
        />
      </>
    ) : (
      <div className="text-yellow-600 bg-yellow-100 p-2 rounded">
        No roles found. Please add a role before creating a user.
      </div>
    )}
  </div>
);

const AddUserModal = ({
  isOpen,
  onClose,
  title,
  footer,
  disabled,
  actionLabel,
  secondaryAction,
  secondaryActionLabel,
  values,
  handleSubmit,
  isSubmitting,
  isValid,
  isPending,
}) => {
  const queryClient = useQueryClient();

  const roles = queryClient.getQueryState(queryKeys.ROLES)?.data?.data?.roles || [];

  const body = (
    <Form className='flex flex-col gap-4'>
      <div className='flex justify-between flex-col w-full items-start gap-7'>
        <div className="flex justify-between w-full gap-4">
          <div className='w-full flex flex-col items-start gap-2'>
            <Label htmlFor='firstName'>First Name</Label>
            <Field
              name='firstName'
              as={Input}
              placeholder='Enter First Name'
            />
            <ErrorMessage
              name='firstName'
              component='div'
              className='error-message text-red max-sm:text-small'
            />
          </div>
          <div className='w-full flex flex-col items-start gap-2'>
            <Label htmlFor='lastname'>Last Name</Label>
            <Field
              name='lastname'
              as={Input}
              placeholder='Enter Last Name'
            />
            <ErrorMessage
              name='lastname'
              component='div'
              className='error-message text-red max-sm:text-small'
            />
          </div>
        </div>
        <div className='w-full flex flex-col items-start gap-2'>
          <Label htmlFor='email'>Email</Label>
          <Field
            name='email'
            as={Input}
            type='email'
            placeholder='Enter Email'
          />
          <ErrorMessage
            name='email'
            component='div'
            className='error-message text-red max-sm:text-small'
          />
        </div>
        <RoleField roles={roles} />

        <div className="flex items-center gap-2">
          <Field
            type="checkbox"
            name="sendEmailInvitation"
            id="sendEmailInvitation"
            className="text-primary border-primary"
          />
          <Label htmlFor="sendEmail">Send an email invitation?</Label>
        </div>

      </div>
    </Form>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      body={body}
      disabled={isSubmitting}
      actionLabel={isPending ? 'Creating...' : actionLabel}
      secondaryAction={secondaryAction}
      secondaryActionLabel={secondaryActionLabel}
      onSubmit={handleSubmit}
      disableSubmit={!isValid || isSubmitting}
    />
  );
};

const EnhancedAddUserModal = withFormik({
  validateOnMount: true,
  enableReinitialize: true,
  mapPropsToValues: (props) => ({
    firstName: props.initialValues?.firstName || "",
    lastname: props.initialValues?.lastname || "",
    email: props.initialValues?.email || "",
    role: "",
    sendEmailInvitation: false,
  }),
  validationSchema: Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    email: Yup.string().required("Email is required"),
    role: Yup.string().required("Role is required"),
  }),
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    try {
      // Access the mutation function through props
      await props.onCreateUser({
        name: values.firstName,
        last_name: values.lastname,
        email: values.email,
        role_id: values.role,
        send_email: values.sendEmailInvitation,
      });
      props.onClose(); // Close the modal

      resetForm(); // Reset the form
    } catch (error) {
      console.error('Error creating user:', error);
    } finally {
      setSubmitting(false); // Reset submission state

    }
  },
})(AddUserModal);

// Wrapper component to provide mutation function
const AddUserModalWrapper = (props) => {
  const createUserMutation = useCreateUser();
  
  return (
    <EnhancedAddUserModal
      {...props}
      onCreateUser={(data) => createUserMutation.mutateAsync(data)}
      isPending={createUserMutation.isPending}
    />
  );
};

export default AddUserModalWrapper;

