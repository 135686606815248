import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getAllPermissions, getAllRoles } from '../api/settings/settingsQueries';
import { queryKeys } from '../api/queryKeys';
import { getAllUsersData } from '../api/user/userQueries';
import { getProductTypes } from '../api/product/productQueries';
import { getAllStates } from '../api/members/membersQueries';

export const usePrefetchData = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
      // Common prefetch for all users
      queryClient.prefetchQuery({
        queryKey: queryKeys.PERMISSIONS,
        queryFn: getAllPermissions,
        staleTime: 1000 * 60 * 60 * 24, // 24 hours
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      });

      queryClient.prefetchQuery({
        queryKey: queryKeys.ROLES,
        queryFn: getAllRoles,
        staleTime: 600000, // Cache for 10 minutes
        cacheTime: 600000, // Keep in cache for 10 minutes
      });

      queryClient.prefetchQuery({
        queryKey: queryKeys.USERS,
        queryFn: getAllUsersData,
        //10minutes
        staleTime: 600000, // Cache for 10 minutes
        cacheTime: 600000, // Keep in cache for 10 minutes
      }); 

      queryClient.prefetchQuery({
        queryKey: queryKeys.PRODUCT_TYPES,
        queryFn: getProductTypes,
        staleTime: 1000 * 60 * 60 * 24, // 24 hours
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      });

      queryClient.prefetchQuery({
        queryKey: queryKeys.STATES,
        queryFn: getAllStates,
        staleTime: 1000 * 60 * 60 * 24, // 24 hours
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      });

      



      // Conditional prefetch based on user type
      // if (user.type === 'admin') {
      //   queryClient.prefetchQuery({
      //     queryKey: queryKeys.ADMIN_DATA,
      //     queryFn: () => fetch('/api/admin-data').then(res => res.json()),
      //   });
      // } else if (user.type === 'manager') {
      //   queryClient.prefetchQuery({
      //     queryKey: queryKeys.MANAGER_DATA,
      //     queryFn: () => fetch('/api/manager-data').then(res => res.json()),
      //   });
      // }
      // Add more conditions for other user types as needed
  }, [queryClient]);
};

