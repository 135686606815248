import React, { useMemo } from 'react'
import { Button, Container, LoadingSpinner } from '../../../components/ui'
import { Link, useParams } from 'react-router-dom'
import { ArrowBackIcon } from '../../../assets/icons'
import EditMemberForm from './EditMemberForm'
import { useGetMember } from '../../../hooks/useMembers'

const EditMemberPage = () => {

  const { id } = useParams();

   const { data ,isError ,isLoading} = useGetMember(id);

   const memberInitialData = useMemo(() => {
      if (!data || !data?.data?.member) return null;
  
      const memberData = data?.data?.member;
  
      const memberRefactoredData = {
        id: memberData.id,
        firstName: memberData.name,
        lastName: memberData.last_name,
        birthday: memberData.birthday,
        email: memberData.email,
        phone: memberData.phone_number,
        secondaryPhone: memberData.phone_number_2,
        unknownBirthday: memberData.is_drinking_age_confirmed === 0 ? false : true,
        nrOfReleases: "default",
        membershipStartDate: memberData.membership_start_date,
        memberNumber: memberData.membership_number,
        signedUpBy: memberData.signed_up_by || "",
        firstReleaseDate: "",
        deliveryMethod: memberData.addresses ? 'shipping' : 'pickup',
        shippingAddress: memberData.addresses?.address_line_1 || '',
        city: memberData.addresses?.city || '',
        state: memberData.addresses?.state_id || '',
        zipCode: memberData.addresses?.zip || '',
        addNote: memberData.notes || '',
        clubMembership: memberData.club_id || "",
        billingOptionValue: "",
        cardHolderName: "",
        cardNumber: "",
        expirationDate: "",
        cvv: "",
        billingAddress: "",
      
      }

      return memberRefactoredData;
    }, [data]);


    if (isLoading) {
      return (
        <Container className="max-sm:px-1 mt-4 " size="lg">
          <LoadingSpinner />
        </Container>
      )
    }

    if (isError || !data) {
      return <div>
        Could not find member
      </div>
    }
  return (
    <Container  size='xxl' className='' >
      <div className='max-w-[1024px] w-full mx-auto flex flex-col justify-center gap-6'>
        <div className='px-3'>
               <Link to='/memberships'>
                 <Button aschild variant='ghost' size='sm'>
                   <ArrowBackIcon className='text-black hover:text-primary' />
                 </Button>
               </Link>
               <h1 className='font-semibold text-base text-new-secondary-gray pt-2'>Edit Member </h1>
             </div>
      </div>
      <EditMemberForm id={id} memberInitialData={memberInitialData} />
    </Container>
  )
}

export default EditMemberPage
