import React from "react"
import { withFormik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { Input, Label, Modal, Checkbox, Select } from "../../../ui"
import  { useGetProductsDummy } from "../../../../hooks/useProducts"

const validationSchema = Yup.object().shape({
  maxWines: Yup.number()
    .required('Maximum number of wines is required')
    .min(1, 'Must allow at least 1 wine')
    .max(10, 'Cannot exceed 10 wines'),
  selectedWines: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().required(),
      quantity: Yup.string().required('Quantity is required')
    })
  )
});

const WelcomeModal = ({
  isOpen,
  onClose,
  title,
  actionLabel,
  secondaryAction,
  secondaryActionLabel,
  handleSubmit,
  isSubmitting,
  isValid,
  isPending,
  data,
  isLoading,
  isError,
  setFieldValue,
  values
}) => {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    )
  }

  if (isError) {
    return (
      <div className="p-4 text-center text-red-500">
        Error loading products. Please try again later.
      </div>
    )
  }

  const handleCheckboxChange = (index, checked) => {
    const selectedWines = [...values.selectedWines];
    if (checked) {
      selectedWines.push({ id: data[index].id, quantity: "unlimited" });
    } else {
      const wineIndex = selectedWines.findIndex(wine => wine.id === data[index].id);
      if (wineIndex > -1) {
        selectedWines.splice(wineIndex, 1);
      }
    }
    setFieldValue("selectedWines", selectedWines);
  };

  const handleQuantityChange = (index, quantity) => {
    const selectedWines = [...values.selectedWines];
    const wineIndex = selectedWines.findIndex(wine => wine.id === data[index].id);
    if (wineIndex > -1) {
      selectedWines[wineIndex].quantity = quantity;
    }
    setFieldValue("selectedWines", selectedWines);
  };

  const body = (
    <Form className='flex flex-col gap-3 w-full'>
      <div className="space-y-2">
        <p className="text-secondary-grey text-small md:text-base">
          Select the wines you'd like to make available for new members to select from.
        </p>
        
        <div className='space-y-2 border-b pb-4'>
          <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
            <Label 
              htmlFor='maxWines'
              className="text-sm font-medium"
            >
              Maximum Number of Wines
            </Label>
            <div className="w-full md:w-24">
              <Field name="maxWines">
                {({ field }) => (
                  <Input
                    {...field}
                    type="number"
                    id="maxWines"
                    className="text-center"
                    min={1}
                    max={10}
                  />
                )}
              </Field>
            </div>
          </div>
          <ErrorMessage
            name='maxWines'
            component='div'
            className='text-red text-sm'
          />
        </div>
      </div>

      <div className="relative overflow-x-auto rounded-md ">
        <table className="w-full ">
          <thead className="bg-muted/50 text-muted-foreground text-base2">
            <tr>
              <th className="w-16 px-4 py-3 text-left font-normal  "></th>
              <th className="px-4 py-3 text-left font-normal text-new-secondary-gray">Type</th>
              <th className="px-4 py-3 text-left font-normal text-new-secondary-gray">Wine</th>
              <th className="px-4 py-3 text-left font-normal text-new-secondary-gray">Price</th>
              <th className="px-4 py-3 text-left font-normal text-new-secondary-gray">Max Quantity</th>
            </tr>
          </thead>
          <tbody className="">
            {data?.map((wine, index) => (
              <tr 
                key={wine.id}
                className="  transition-colors border-b"
              >
                <td className="px-4 py-3">
                  <Checkbox
                    checked={values.selectedWines.some(selectedWine => selectedWine.id === wine.id)}
                    onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                  />
                </td>
                <td className="px-4 py-3 font-medium">{wine.category}</td>
                <td className="px-4 py-3 font-medium">{wine.name}</td>
                <td className="px-4 py-3 text-left font-medium">${wine.price.toFixed(2)}</td>
                <td className="px-4 py-3 font-medium">
                  <Select
                    value={values.selectedWines.find(selectedWine => selectedWine.id === wine.id)?.quantity || ""}
                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                    disabled={!values.selectedWines.some(selectedWine => selectedWine.id === wine.id)}
                    className="w-full min-w-[100px]"
                  >
                    <option value="unlimited">Unlimited</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </Select>
                  <ErrorMessage
                    name={`selectedWines.${index}.quantity`}
                    component="div"
                    className="text-red text-base2 mt-1"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Form>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      body={body}
      disabled={isSubmitting}
      actionLabel={isPending ? 'Saving...' : actionLabel}
      secondaryAction={secondaryAction}
      secondaryActionLabel={secondaryActionLabel}
      onSubmit={handleSubmit}
      disableSubmit={!isValid || isSubmitting}
      className="w-full max-w-4xl mx-auto"
      customModal
    />
  );
};

const WelcomeClubModal = withFormik({
  validateOnMount: true,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  mapPropsToValues: (props) => ({
    maxWines: props.initialValues?.maxWines || 5,
    selectedWines: props.initialValues?.selectedWines || []
  }),
  validationSchema,
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    try {
      console.log('values:', values);
      await props.onSave(values);
      props.onClose();
      resetForm();
    } catch (error) {
      console.error('Error saving welcome pack:', error);
    } finally {
      setSubmitting(false);
    }
  },
})(WelcomeModal);

const WelcomePackClubWrapper = (props) => {
  const { data, isError, isLoading } = useGetProductsDummy();

  return (
    <WelcomeClubModal 
      {...props}
      data={data}
      isError={isError}
      isLoading={isLoading}
    />
  );
};

export default WelcomePackClubWrapper;

