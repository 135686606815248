import React, { useRef, useState } from "react";
import { TiPlus } from "../../assets/icons";
import brandPic from "../../assets/images/brandPic.png";
import { SearchIcon, NotificationIcon } from "../../assets/icons";
import Button from "../ui/Button";
import useClickOutside from "../../hooks/useOnClickOutside";
import { Input } from "../ui";
import { useLogoutMutation } from "../../hooks/useLoginMutation";
import useAuthStore from "../../store/authStore";
import { Link } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { FiUser } from 'react-icons/fi';

function Navbar() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const [openUserDropdown, setOpenUserDropdown] = useState(false);
  const modalRef = useClickOutside(() => setOpenUserDropdown(false));

  const searchInputRef = useRef(null);

  const { user } = useAuthStore();


  const { mutate, isPending } = useLogoutMutation();

  const getUserFirst2Letters = (user) => {
    const name = user?.name || ["A", "B"];
    const firstLetter = name[0].toUpperCase();
    const secondLetter = name[1].toUpperCase();
    return `${firstLetter}${secondLetter}`;
  };

 


  return (
    <header className='bg-white shadow flex items-center justify-between p-4  border-b-[1px]'>
      
   
      <h1 className='text-xl font-semibold text-gray-800'>
        <img src={brandPic} alt='' />
      </h1>


      <div className='flex items-center gap-5'>
        {/* Add new member button */}
        <Link to={"/memberships/add-member"}>
        <Button aschild variant='default'>
          <TiPlus />
          Add New Member
        </Button>

        </Link>

        <div className='inline-flex items-center '>
          <div
            className={`transition-width duration-300 ${
              isSearchOpen ? "w-64 opacity-100  pr-2" : "w-0 opacity-0 pr-0"
            } overflow-hidden p-[1px]`}
          >
            <Input
              type='text'
              ref={searchInputRef}
              className='border border-gray-300 rounded focus:outline-none  w-full overflow-hidden'
              placeholder='Search...'
              size='sm'
            />
          </div>

          {/* Search Icon */}
          <Button
            variant='ghost'
            size='sm'
            className='text-gray-600 hover:text-gray-800'
            onClick={() => setIsSearchOpen(!isSearchOpen)}
          >
            <SearchIcon className='h-6 w-6 text-gray-400' />
          </Button>
        </div>

        <button className='flex items-center text-gray-400 hover:text-gray-800'>
          <NotificationIcon className='h-6 w-6' />
        </button>
        {/*a little circle for the persons account image */}
        <div
          className='relative h-[46px] w-[46px] rounded-full cursor-pointer'
          ref={modalRef}
        >
          <div
            onClick={() => setOpenUserDropdown(!openUserDropdown)}
            className='absolute select-none flex items-center justify-center top-0 right-0  bg-new-secondary-gray h-full w-full rounded-full'
          >
            <span className='text-white text-h6 spa font-semibold'>
              {getUserFirst2Letters(user)}
            </span>
          </div>

          {openUserDropdown && (
            <div className='absolute z-10  right-4 top-[130%]  w-48 bg-white border border-gray-200 rounded shadow-lg'>
              <Link to={"/user-profile"}>
                <Button
                variant="ghost"
                  className='flex justify-start px-4 py-2 text-gray-800 hover:bg-gray-100 w-full'
                  onClick={() => {
                    setOpenUserDropdown(false);
                  }}
                >
                 <span className="w-full flex items-center justify-start">
                  <FiUser className='mr-2 ' />
                  User Profile
                 </span>
                </Button>
              </Link>
              <Button
                variant="ghost"
                disabled={isPending}
                className='flex justify-start px-4 py-2 text-gray-800 hover:bg-gray-100 w-full'
                onClick={() => {
                  mutate();
                }}
              >
              <span className="w-full flex items-center justify-start">
              <FiLogOut className='mr-2' />
              Logout
              </span>

                
              </Button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Navbar;
