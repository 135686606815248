import { create } from 'zustand'

/**
 * @typedef {Object} ModalState
 * @property {Object} modals - The modals state.
 * @property {(modalId: string) => void} openModal - Function to open a modal.
 * @property {(modalId: string) => void} closeModal - Function to close a modal.
 * @property {(modalId: string) => void} toggleModal - Function to toggle a modal.
 * 

 */



const modalStore = create((set) => ({
  modals: {},
  openModal: (modalId) => set((state) => ({ modals: { ...state.modals, [modalId]: true } })),
  closeModal: (modalId) => set((state) => ({ modals: { ...state.modals, [modalId]: false } })),
  toggleModal: (modalId) => set((state) => ({ modals: { ...state.modals, [modalId]: !state.modals[modalId] } })),
}))

export default modalStore

