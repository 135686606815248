import React from "react";

const WineIcon2 = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.33333 12.6667V9.25927L2 3.33333V2H14V3.33333L8.66667 9.25927V12.6667H12V14H4V12.6667H7.33333ZM4.99382 4.66667H11.0062L12.2062 3.33333H3.79381L4.99382 4.66667ZM6.19381 6L8 8.00687L9.8062 6H6.19381Z"
      fill="currentColor"
    />
  </svg>
);

export default WineIcon2;
