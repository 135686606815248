import React, { useState, useMemo, useCallback } from 'react';
import { Button, Container, LoadingSpinner, Tabs, Tag } from '../../../components/ui';
import { EditIcon, SettingsIcon, TiPlus } from '../../../assets/icons';
import DeleteIcon from '../../../assets/icons/DeleteIcon';
import Table from '../../../components/ui/table/Table';
import TableHeader from '../../../components/ui/table/TableHeader';
import TableBody from '../../../components/ui/table/TableBody';
import TableRow from '../../../components/ui/table/TableRow';
import TableCell from '../../../components/ui/table/TableCell';
import { Link, useNavigate } from 'react-router-dom';
import { useGetAllClubs } from '../../../hooks/useClubs';
import { usePagination } from '../../../components/pagination/usePagination';
import Pagination from '../../../components/pagination/Pagination';
import Image from '../../../components/ui/Image';
import add_ad from '../../../assets/images/add_ad.png';

const ClubsTable = ({ onDelete, onArchive, onUnarchive }) => {
  const { data, isError, isLoading } = useGetAllClubs();
  const navigate = useNavigate();
  const [isLive, setIsLive] = useState(true); // true for live, false for archive

  const tabs = useMemo(() => [
    { label: 'Active Clubs', value: true },  // true indicates "live"
    { label: 'Archive Clubs', value: false }, // false indicates "archive"
  ], []);

  const columns = useMemo(() => ["Club", "Members", "Status", "Bottles", "Price"], []);

  const filteredClubs = useMemo(() => {
    if (data && data.data && data.data.clubs && data.data.clubs.length > 0) {
      const clubData = data.data.clubs;

      return clubData.filter((product) => {
        if (!isLive) {
          return product.club_visibility === 0;
        }
        return product.club_visibility === 1;
      });
    }
    return [];
  }, [data, isLive]);

  const {
    currentItems,
    currentPage,
    totalPages,
    totalItems,
    perPage,
    handlePageChange,
    handleItemsPerPageChange
  } = usePagination(filteredClubs, 8);

  const onEditClubs = useCallback((club) => {
    navigate(`${club.id}/edit`);
  }, [navigate]);

  const getActions = useCallback((row) => [
    {
      icon: <EditIcon />,
      onClick: () => onEditClubs(row),
      tooltip: 'Edit',
    },
    {
      icon: row.isArchived ? <SettingsIcon /> : <SettingsIcon />,
      onClick: row.isArchived ? () => onUnarchive(row) : () => onArchive(row),
      tooltip: row.isArchived ? 'Unarchive Club' : 'Archive Club',
      disabled: true,
    },
    {
      icon: <DeleteIcon />,
      onClick: () => onDelete(row),
      tooltip: 'Delete Club',
    },
  ], [onEditClubs, onArchive, onUnarchive, onDelete]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <div>Something went wrong</div>;
  }

  if (!currentItems || currentItems.length === 0) {
    return (
      <Container className="mt-6 text-center h-[60vh] justify-center items-center" size='xl'>
      <div className='flex flex-col items-center max-w-[901px] w-full justify-center space-y-4 p-10 border rounded-lg'>
        <Image src={add_ad} alt='add_ad' className='max-w-[48px] h-[48px]' />
        <h1 className='text-h1 font-semibold text-dark text-lg'>{`No ${isLive ? 'Active' : 'Archived'} Clubs Available`}</h1>
        <p className='text-base2 font-normal text-secondary-grey'>{`You don't have any ${isLive ? 'active' : 'archived'} clubs.`}</p>
        <Link to="/club-management/clubs/add-club">
          <Button size='sm' className={`w-full px-5`} aschild variant="default">
            <TiPlus /> Add Club
          </Button>
        </Link>
      </div>
    </Container>
    )
    
  }
   
  return (
    <Container type="flex" className="px-0 py-3" size="full">
      {/* Tabs */}
      <Tabs tabs={tabs} activeTab={isLive} onTabChange={setIsLive} />
      {/* Clubs Empty State */}
   
        <Container className="mt-4 px-4" size='xxl'>
          <MemoizedTable
            columns={columns}
            currentItems={currentItems}
            getActions={getActions}
            navigate={navigate}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            itemsPerPage={perPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        </Container>
    </Container>
  );
};

const TableComponent = ({ columns, currentItems, getActions, navigate }) => {
  return (
    <Table>
      <TableHeader columns={columns} hasActions />
      <TableBody>
        {currentItems.map((row) => (
          <TableRow key={row.id}>
            <TableCell className='lg:w-[25%]'>
              <div
                onClick={() => navigate(`${row.id}/edit`)}
                className='inline-flex gap-2 items-center justify-start w-full'>
                <div className='h-[48px] min-w-[48px] w-fit overflow-hidden'>
                  <Image className='max-w-[48px]' src={row.logo} alt="Club" />
                </div>
                <h6 className='text-base2 font-medium text-[#333333]'>{row.name}</h6>
              </div>
            </TableCell>
            <TableCell className='lg:w-[10%] text-base2 font-medium text-[#333333]'>{row.members || 0}</TableCell>
            <TableCell className='lg:w-[10%]'>
              {row.is_private === 0 ? (
                <Tag variant='blue' sizes='md' text={'Public'} />
              ) : (
                <Tag variant='warning' sizes='md' text={'Private'} />
              )}
            </TableCell>
            <TableCell className='lg:w-[15%] text-base2 font-medium text-[#333333]'>{row.quantity} Bottle Per Release</TableCell>
            <TableCell>
              <div className='flex flex-col items-start justify-center'>
                <span className='text-base2 font-medium text-[#333333]'>${row.price || ' --'}</span>
                <span className='text-caption font-medium text-[#666666]'>Per release</span>
              </div>
            </TableCell>
            <TableCell className='text-[#A0A5AB]'>
              <div className="flex justify-end items-center w-full h-full">
                {getActions(row).map((action, actionIndex) => (
                  <Button
                    key={actionIndex}
                    variant="ghost"
                    className="text-center flex justify-center hover:text-primary"
                    onClick={action.onClick}
                    size="icon"
                    title={action.tooltip}
                    disabled={action.disabled}
                  >
                    {action.icon}
                  </Button>
                ))}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const MemoizedTable = React.memo(TableComponent);

export default ClubsTable;
