import { useQuery } from '@tanstack/react-query';
import useAuthStore from '../store/authStore';
import { useEffect } from 'react';
import { getUserData } from '../api/user/userQueries';
import { queryKeys } from '../api/queryKeys';



export const useAuth = () => {
  const { token, user, setUser, logout ,checkAuthStatus ,  } = useAuthStore();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: queryKeys.USER,
    queryFn: getUserData,
    enabled: !!token && !user,
    retry: false,
    cacheTime:  300000,
    
  });
  

  useEffect(() => {
    checkAuthStatus()
 
    if (data) {
      const { data: {user} } = data;
      setUser(user);
    }
      // eslint-disable-next-line
  }, [ data, isError, setUser, token]);

  useEffect(() => {
    if (isError) {
      logout();
    }

  }, [ isError, logout]);


  return {
    user: user || data?.data?.user,
    isLoading,
    error,
    isAuthenticated: !!(user || data),
  };
};

